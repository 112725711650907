@import '../partials/_mixins';
@import '../partials/_variables';

.home-banner {
    position: relative;
}

.banner-area {
    .banner__bg {
        height: 460px;
        width: 100%;
        background-position: center;
        background-size: cover;
    }

    .content-animate {
        position: relative;
        display: inline-block;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    .banner-overlay{
        position: absolute;
        z-index: 900;
        bottom: 200px;
        left: 0;
        right: 0;

        @media (max-width: 767px) {
            padding-top: 20px;
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
        }
    }

    .banner-title {
        display: inline-block;
        padding: 20px;
        font-size: 36px;
        color: #fff;
        background: @brand-primary;
        position: relative;
        z-index: 1;

        @media (max-width: 767px) {
            background: transparent;
            color: @brand-primary;
            font-size: 30px;
            padding: 0;
            margin-bottom: 10px;
            line-height: 36px;
        }
    }

    .banner-text {
        position: relative;
        margin: -27px 0 0 34px;
        background-color: #fff;
        width: 396px;
        display: block;
        padding: 40px 20px 30px;
        font-size: 18px;

        @media (max-width: 767px) {
            margin: 0;
            padding: 0;
            width: auto;
        }

        &:after {
            @media (min-width: 768px) {
                content: '';
                position: absolute;
                bottom: -12px;
                left: 0;
                height: 23px;
                width: 100%;
                background-image: url('/SiteFiles/Images/stripes.png');
                background-repeat: repeat-x;
            }
        }
    }
    
    .btn--standard {
        position: absolute;
        bottom: -25px;
        right: -30px;
        background: none;
        background-color: @brand-orange;
        color: @brand-white;
        z-index: 1;

        @media (max-width: 1199px) {
            bottom: -40px;
        }

        @media (max-width: 767px) {
            position: relative;
            bottom: auto;
            right: auto;
            margin-left: 10px;
        }
    }
}

@media (max-width: 991px) {
    .home-banner {
        top: 0!important;
    }
}