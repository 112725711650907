@import '../partials/_mixins';
@import '../partials/_variables';

.basket {
  .enquiry-form {
    padding-bottom: 50px;
  }

  .basket-step {
    padding: 10px 0 0px;
    background: #f1f1f1 url(~@imgOld/form-repeat.png) repeat 0px 0px;
    margin-bottom: 60px;

    @media (min-width: 768px) {
      text-align: right;
    }

    .choose-option {
      top: -151px;
      float: left;
      height: 50px;
      left: 0px;

      &.grey {
        background-color: #575757;
      }
    }

    .form-box {
      background: none;
      padding: 0;

      input,
      label,
      .chosen-container,
      textarea,
      select {
        display: block;
        width: 100%;
        text-align: left;
      }

      select {
        height: 54px;
        margin-bottom: 30px;
      }
    }

    .container.with-button {
      position: relative;

      .btn {
        position: absolute;
        bottom: -57px;
        right: 0;

        .open {
          display: inline-block;
        }

        .proceed {
          display: none;
        }
      }
    }

    .basket-step-main {
      display: none;

      .ret-pass {
        display: none;
      }

      .details-options {
        .font-size(18);

        h2 {
          color: @brand-primary;
        }

        input[type='submit'] {
          width: 100%;
          position: relative;
          background-color: @brand-orange;
          background-image: none;
          .font-size(14);
          bottom: 5px;

          &.green {
            background-color: @brand-primary;
          }

          &.grey {
            color: #575757;
            background-color: transparent;
            border: 1px solid #ccc;
            padding: 11px 35px 12px 36px;
          }
        }

        table {
          background-color: transparent;

          label {
            color: @brand-primary;
          }

          input[type='text'],
          input[type='email'] {
            margin: 5px 0 8px 0;
          }
        }

        td {
          border: none;
          padding: 0px;
        }

        .LogonDialog {
          display: block !important;
          width: 100%;
          position: relative;

          td {
            display: block !important;
            width: 100%;
            position: relative;
          }

          a {
            width: 100%;
            position: relative;
            background-color: @brand-orange;
            display: block;
            background-image: none;
            font-size: 1em;
            color: #fff;
            padding: 12px 0px;
            text-align: center;
            .font-size(14);

            &:hover {
              border-radius: 10px;
            }
          }

          .ErrorLabel {
            padding: 0px;
            background-color: transparent;
            border: none;
          }
        }

        .LogonDialog .TopLeftCorner,
        .LogonDialog .TopMiddleBorder,
        .LogonDialog .TopRightCorner {
        }
      }
    }

    &.active-step {
      .container.with-button {
        .btn {
          .open {
            display: none;
          }

          .proceed {
            display: inline-block;
          }
        }
      }
    }

    &.step2 {
      .enquiry-btn {
        i {
          left: 20px;
          top: 15px;
        }

        input[type='submit'] {
          float: right;
          position: relative;
          bottom: 0;
        }
      }

      .btn {
        pointer-events: none;
      }

      &.active-step {
        .btn {
          pointer-events: auto;
        }
      }
    }

    .grey-bar {
      width: 100%;
      display: block !important;

      .green-bar {
        position: absolute;
        top: 0;
        right: 0;
        content: ' ';
        display: block;
        height: 8px;
        width: 30%;
        background: @brand-primary;

        &:before {
          position: absolute;
          top: 0;
          left: -30px;
          content: ' ';
          display: block;
          height: 8px;
          width: 35px;
          background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 0px;
        }
      }
    }

    .step {
      overflow: hidden;
      width: 0;
      -moz-transition: width 1.5s ease-in-out;
      -o-transition: width 1.5s ease-in-out;
      -webkit-transition: width 1.5s ease-in-out;
      transition: width 1.5s ease-in-out;
      -moz-transition-delay: 0.2s;
      -o-transition-delay: 0.2s;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
      margin-bottom: 40px;
      text-align: left;

      .inner-step {
        display: inline-block;
        background: @brand-primary;
        padding: 10px 20px 10px 0;
        white-space: nowrap;

        .step-number {
          display: inline-block;
          color: #fff;
          .font-size(18);
        }
      }
    }

    .widget.featured-products .featured-product {
      margin-bottom: 10px;
      text-align: left;
    }

    .remove-item {
      margin-bottom: 15px;
      display: block;
      font-weight: bold;
      vertical-align: middle;
      text-decoration: none;
      text-align: left;

      .fa {
        color: @brand-grey;
        margin-left: 10px;
        font-size: 120%;
        vertical-align: middle;
      }

      &:hover {
        color: @brand-primary;

        .fa {
          color: @brand-primary;
        }
      }
    }
  }
}
