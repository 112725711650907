@import '../partials/_mixins';
@import '../partials/_variables';

.error-wrap {
    position: relative;

    .error-text {
        text-align: center;
        .font-size(24);
        line-height: 28px;
        font-weight: bold;
        margin: 300px 0 200px;

        a {
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            text-decoration: none;
            position: relative;

            &:before {
                display: block;
                content: ' ';
                width: 100%;
                height: 2px;
                background: @brand-secondary;
                position: absolute;
                z-index: 15;
                bottom: 16px;
                opacity: 0;
                left: 0px;
                right: 0;
                margin: 0 auto;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                &:before {
                    opacity: 1;
                    bottom: 0px;
                }
            }
        }
    }

    .error-banner {
        position: absolute;
        top: -260px;
        left: 380px;

        .you-seem-lost {
            position: relative;
            left: -1px;
            -moz-transform-origin: center top;
            -webkit-animation: swing 4s infinite ease-in-out;
            -o-animation: swing 4s infinite ease-in-out;
            -moz-animation: swing 4s infinite ease-in-out;
            animation: swing 4s infinite ease-in-out;
            -webkit-transform-origin: top;
            -ms-transform-origin: top;
            -o-transform-origin: top;
            transform-origin: top;



            &__container {
              position: absolute;
              top: 50%;
              left: 12%;
              transform: skewY(-4deg);
              width: 290px;
              text-align: center;

              h3 {
                font-size: 25px;
                color: #fff;
              }

              @media(max-width: 768px) {
                width: 230px;
                h3 {
                  font-size: 20px;
                }
              }

              @media(max-width: 362px) {
                width: 180px;
              }
            }
        }

        .pin-image {
            position: absolute;
            top: -34px;
            left: 155px;
            z-index: 10;
        }
    }

    .error-img-left {
        position: absolute;
        top: -95px;
        left: 150px;
    }

    .error-img-right {
        position: absolute;
        top: -139px;
        right: -41px;
    }
}


@media (max-width: 1199px) {
    .error-wrap .error-img-left {
        left: 63px;
    }

    .error-wrap .error-img-right {
        top: -92px;
        right: -27px;
        width: 318px;
    }

    .error-wrap .error-banner {
        top: -260px;
        left: 300px;
    }
}

@media (max-width: 991px) {
    .error-wrap .error-banner {
        top: -260px;
        left: 186px;
    }
    .error-wrap .error-text {
        .font-size(20);
    }

    .error-wrap .error-img-left {
        left: -21px;
    }

    .error-wrap .error-img-right {
        top: -42px;
        right: -35px;
        width: 236px;
    }
}

@media (max-width: 767px) {
    .error-wrap .error-banner {
        left: 50%;
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 294px;
        top: -209px;
    }

    .error-wrap .error-text {
        margin: 260px 0 200px;
    }

    .error-wrap .error-banner .pin-image {
        left: 111px;
        z-index: 10;
    }

    .error-wrap .error-img-left {
        top: auto;
        bottom:-200px;
        left: 10px;
        z-index: -1;
    }

    .error-wrap .error-img-right {
        top: 90px;
        right: 10px;
        width: 230px;
        z-index: -1;
    }
}

@media (max-width: 480px) {
    .error-wrap .error-img-right {
        top: 144px;
        right: 10px;
        width: 154px;
        z-index: -1;
    }

    .error-wrap .error-img-left {
        left: 10px;
        z-index: -1;
        width: 133px;
    }
}


@media (max-width: 400px) {
    .error-wrap .error-text {
        margin: 260px 0 160px;
    }

    .error-wrap .error-img-right {
        top: 104px;
    }

    .error-wrap .error-img-left {
        bottom: -162px;
    }
}

@media (max-width: 362px) {
    .error-wrap .error-img-right {
        right: -12px;
    }

    .error-wrap .error-img-left {
        left: -12px;
    }

    .error-wrap .error-banner .pin-image {
        left: 90px;
        top: -37px;
    }

    .error-wrap .error-banner {
        width: 242px;
        top: -193px;
    }
}




@-moz-keyframes swing {
    0% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    50% {
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    100% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}

@-ms-keyframes swing {
    0% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    50% {
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    100% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}

@-webkit-keyframes swing {
    0% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    100% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}

@keyframes swing {
    0% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }

    50% {
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }

    100% {
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
}
