/// For if the user has no javascript on the site
// ...weirdos

@-webkit-keyframes opac {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes opac {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.HomePage .no-js {
    -webkit-animation: opac 3s;
    animation: opac 3s;
}

.no-js {

    .js-btn {
        display: none !important;
    }

    .no-js-btn {
        display: block !important;
    }

    .js-div {
        display: none !important;
    }

    .no-js-div {
        display: block !important;

        .login-click {
            a {
                color: @brand-white;
            }
        }
    }

    .add-to-basket,
    .basket-icon-button {
        display: none !important;
    }

    .enquire-now {
        display: block;
        padding-left: 12px !important;
        float: right;
    }

    header {
        opacity: 1 !important;
        transition: none;
        display: block;
        top: 0px;

        .depot-area,
        .search-area,
        .basket-area,
        .country-area {
            display: none !important;
        }

        .signoutLink {
            display: none;
        }

        .top-links {
            .left-links {
                .menu-btn {
                    display: none !important;
                }
            }

            .right-links {
                min-width: 30%;
                // Hide thoughs that done wotk.
                .ipad-search {
                    display: none !important;
                }
                // Bring out the important
                .mail-icon {
                    opacity: 1 !important;
                }
            }
        }
    }

    .main-content {
        .owl-carousel {
            display: block;

            .item {
                display: none;

                &:first-child {
                    display: block;
                }
            }

            &.media-main {
                .item {
                    display: block;
                    padding: 0 0 20px 0;
                }
            }
        }
    }

    .homepage-signposts {
        .signpost-item {
            opacity: 1;
            width: 25%;
        }
    }


    .tech-library {
        .login-register {
            .login-box {
                .login-box-back {
                    position: initial !important;
                    max-height: none !important;
                }
            }
        }
    }

    .tech-library {
        .login-register {
            .login-box {
                &:hover.green-login {
                    background: #009dff !important;
                }

                &:hover.orange-login {
                    background: #06164a !important;
                }

                .login-inner {
                    .close-box {
                        display: none;
                    }
                }
            }
        }
    }

    .media-filter, .date-filter {
        display: none !important;
    }

    #ProductsList, .article-wrap {
        .orig {
            display: block;
        }
    }

    .tech-library {
        .tech-search {
            display: none;
        }
    }

    .media-hub {
        .media-view {
            display: none;
        }
    }

    .animate-banner {
        opacity: 1 !important;
        left: 0%;

        .home-banner {
            opacity: 1 !important;

            .item {
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }
    }

    .load-home-content {
        opacity: 1 !important;
    }

    .technical-widget {
        .orig {
            display: block;
        }
    }

    footer {
        display: block !important;
    }
}

@media (max-width: 991px) {
    .no-js {

        header {
            .desktop-nav {
                display: block;
                width: 100%;

                ul {
                    li {
                        width: 100%;
                        display: inline-block;

                        a {
                            padding: 10px 14px;
                            font-size: 1.2em;
                        }
                    }
                }
            }

            .login-area {
                opacity: 1 !important;

                a {
                    padding-top: 10px;

                    span {
                        opacity: 1 !important;
                    }
                }
            }
        }

        .footer-links ul {
            display: block;
        }
    }
}

@media (max-width: 767px) {
    .no-js {
        .back-to-desktop {
            display: block;
        }
    }

    header {
        .top-links {
            .right-links {
                .login-area {
                    .login-icon {
                        top: 13px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .no-js {
        header {
            .logo {
                position: absolute;
                margin: 12px 0 13px 18px;
                padding-right: 25px;
                transition: all .5s ease-in-out;
                top: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@media (max-width: 550px) {
    .no-js {
        header {
            .logo {
                text-align: center;
                margin: 10px auto;
                top: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .homepage-signposts {

            .signpost-item {
                opacity: 1;
                width: 50%;

                &:last-child {
                    display: block;
                }

                .widget.sign-post-widget {
                    min-height: 105px;
                }
            }
        }
    }
}

@media (max-width: 440px) {
    .no-js {
    }
}
