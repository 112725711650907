@import '../partials/_mixins';
@import '../partials/_variables';

.map {
    position: relative;
    margin-bottom: 90px;

    .inner-map {
        width: 615px;
        margin: 0 auto;
    }

    .grey-bar {
        margin-top: 30px;
    }

    .map-popup {
        width: 615px;
        display: none;
        position: absolute;
        z-index: 4000;
        top: 0px;
        left: 0px;
        border: 1px solid @brand-primary;
        background: #fff;
        padding: 25px 0;

        .popup-content {
            display: none;
            position: relative;

            .close-btn {
                display: block;
                width: 40px;
                height: 40px;
                .font-size(25);
                position: absolute;
                padding: 10px;
                top: -25px;
                right: 0px;
                cursor: pointer;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;

                &:before {
                    display: block;
                    color: @brand-grey;
                    content: '\f00d';
                    font-family: 'FontAwesome';
                    -moz-transition: all 0.4s ease-in-out;
                    -o-transition: all 0.4s ease-in-out;
                    -webkit-transition: all 0.4s ease-in-out;
                    transition: all 0.4s ease-in-out;
                }

                &:hover {
                    -moz-transform: rotateZ(90deg);
                    -ms-transform: rotateZ(90deg);
                    -o-transform: rotateZ(90deg);
                    -webkit-transform: rotateZ(90deg);
                    transform: rotateZ(90deg);

                    &:before {
                        color: @brand-primary;
                    }
                }
            }

            &.active {
                display: block;
            }
        }

        &.opened {
            -moz-animation: popupbounce 0.4s ease-in-out;
            -o-animation: popupbounce 0.4s ease-in-out;
            -webkit-animation: popupbounce 0.4s ease-in-out;
            animation: popupbounce 0.4s ease-in-out;
        }

        &:after {
            position: absolute;
            bottom: -15px;
            left: 200px;
            content: ' ';
            display: block;
            background: url(@sprite-path) no-repeat -83px -54px;
            width: 30px;
            height: 15px;
        }

        .popup-box {
            width: 33%;
            float: left;
        }

        p {
            margin-top: 0;
            .font-size(13);
            padding-left: 32px;
        }

        ul {
            padding-left: 32px;
            margin: 0;
        }

        address, .directions {
            .font-size(13);
            padding-left: 24%;
        }

        label {
            padding-right: 8px;
        }

        h4 {
            display: inline-block;
            .font-size(18);
            color: #fff;
            background: @brand-primary;
            padding: 10px 20px 10px 32px;
            margin-bottom: 10px;
        }
    }

    .map-point {
        cursor: pointer;

        .path {
            fill: #FE9903;
            -moz-animation: fillpath 2s ease-in-out infinite;
            -o-animation: fillpath 2s ease-in-out infinite;
            -webkit-animation: fillpath 2s ease-in-out infinite;
            animation: fillpath 2s ease-in-out infinite;
        }
    }
}

.IE8 {
    .map {
        .map-popup {
            position: relative!important;
            left: 0!important;
            top: 0!important;

            &:first-child {
                display: block;
            }

        }

        #Layer_1 img {
            display: none;
        }
    }
}



@media (max-width: 991px) {
    .map {
        .inner-map {
            width: 100%;
        }

        svg {
            width: 100%;
        }

        .map-popup {
            width: 442px;

            a {
                -ms-word-break: break-word;
                word-break: break-word;
            }

            label {
                display: block;
                max-width: 100%;
                margin-bottom: 0px;
            }

            p {
                padding-left: 19px;
            }

            ul {
                padding-left: 17px;
            }

            h4 {
                padding: 6px 11px 4px 17px;
            }
        }
    }

        .map .map-popup:after {
            left: 130px;
        }
}

@media (max-width: 767px) {
    .map {
        margin-bottom: 25px;

        .map-popup {
            position: relative;
            top: 0!important;
            left: 0!important;
            width: 100%;

            &.opened {
                -moz-animation: none;
                -o-animation: none;
                -webkit-animation: none;
                animation: none;
            }

            &:after {
                display: none;
            }
        }

        svg {
            display: none;
        }
    }

    .map-filter {
        h3 {
            position: relative;
            cursor: pointer;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;

            &.slide-open {
                background: lighten(@brand-primary, 8%)!important;

                &:before {
                    -moz-transform: rotateZ(180deg);
                    -ms-transform: rotateZ(180deg);
                    -o-transform: rotateZ(180deg);
                    -webkit-transform: rotateZ(180deg);
                    transform: rotateZ(180deg);
                }
            }

            &:hover {
                background: lighten(@brand-primary, 8%)!important;
            }

            &:before {
                position: absolute;
                top: 12px;
                right: 12px;
                content: '\f0ab';
                font-family: 'FontAwesome';
                .font-size(24);
                background: none;
                width: 30px;
                height: 30px;
                display: block;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
        }

        .link-wrap {
            display: none;
        }
    }
}

@media (max-width: 500px) {
    .map .map-popup .info-row .popup-box {
        width: 50%;
        padding-right: 15px;

        address, .directions {
            padding-left: 19px;
        }

        &:first-child {
            width: 100%;
        }
    }

    .map .map-popup .products-row .popup-box {
        width: 50%;
    }
}

@-moz-keyframes popupbounce {
    0% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }

    50% {
        -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
        -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
        -o-transform: scale(1.1) translateY(-5%) translateX(2%);
        -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
        transform: scale(1.1) translateY(-5%) translateX(2%);
    }

    100% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }
}

@-ms-keyframes popupbounce {
    0% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }

    50% {
        -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
        -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
        -o-transform: scale(1.1) translateY(-5%) translateX(2%);
        -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
        transform: scale(1.1) translateY(-5%) translateX(2%);
    }

    100% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }
}

@-webkit-keyframes popupbounce {
    0% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }

    50% {
        -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
        -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
        -o-transform: scale(1.1) translateY(-5%) translateX(2%);
        -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
        transform: scale(1.1) translateY(-5%) translateX(2%);
    }

    100% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }
}

@keyframes popupbounce {
    0% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }

    50% {
        -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
        -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
        -o-transform: scale(1.1) translateY(-5%) translateX(2%);
        -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
        transform: scale(1.1) translateY(-5%) translateX(2%);
    }

    100% {
        -moz-transform: scale(1) translateY(0%);
        -ms-transform: scale(1) translateY(0%);
        -o-transform: scale(1) translateY(0%);
        -webkit-transform: scale(1) translateY(0%);
        transform: scale(1) translateY(0%);
    }
}

@-moz-keyframes fillpath {
    0% {
        fill: #FE9903;
    }

    50% {
        fill: @brand-light-grey;
    }

    100% {
        fill: #FE9903;
    }
}

@-ms-keyframes fillpath {
    0% {
        fill: #FE9903;
    }

    50% {
        fill: @brand-light-grey;
    }

    100% {
        fill: #FE9903;
    }
}

@-webkit-keyframes fillpath {
    0% {
        fill: #FE9903;
    }

    50% {
        fill: @brand-light-grey;
    }

    100% {
        fill: #FE9903;
    }
}

@keyframes fillpath {
    0% {
        fill: #FE9903;
    }

    50% {
        fill: @brand-light-grey;
    }

    100% {
        fill: #FE9903;
    }
}
