@import '../partials/_mixins';
@import '../partials/_variables';

.widget {
  margin-bottom: 25px;

  &.side-nav-widget {
    border: 1px solid #bebebe;

    .link-wrap {
      padding: 10px 0;
    }

    h3 {
      margin: 0;
      .font-size(18);
      padding: 18px;
      background: @brand-primary;
      color: #fff;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: block;
        list-style-type: none;
        position: relative;
        padding-left: 0;

        &:before {
          position: absolute;
          top: 12px;
          left: 20px;
          display: none;
          // content: '\F061';
          width: 13px;
          height: 13px;
          // font-family: 'Font Awesome 5 Free';
          color: @brand-secondary;
        }

        &:hover {
          &:before {
            display: block;
            -moz-animation: wigglearrow 0.7s ease-in-out forwards;
            -o-animation: wigglearrow 0.7s ease-in-out forwards;
            -webkit-animation: wigglearrow 0.7s ease-in-out forwards;
            animation: wigglearrow 0.7s ease-in-out forwards;
          }
        }

        &.active {
          &:before {
            display: block;
            background: url(@sprite-path) no-repeat -4px -80px;
          }

          a {
            background: #ebebeb;
            color: @brand-primary;
          }
        }

        a {
          display: block;
          padding: 9px 30px 6px 49px;
          .font-size(16);
          color: @brand-grey;
          font-weight: bold;
          text-decoration: none;
          -moz-transition: background 0.3s ease-in-out;
          -o-transition: background 0.3s ease-in-out;
          -webkit-transition: background 0.3s ease-in-out;
          transition: background 0.3s ease-in-out;
          font-weight: 400;

          &:hover,
          &.active {
            background: #ebebeb;
            color: @brand-primary;
          }
        }
      }
    }

    &.enquiry-basket-guide {
      border: none;
      position: relative;

      &.always-show {
        display: block !important;
      }

      &.compact {
        display: none;
      }

      &.letterbox {
        display: block;

        .row {
          margin: 20px 0 0 0;
        }

        .col-xs-1,
        .col-xs-2 {
          padding: 0;
        }

        .col-xs-1 {
          width: 6.6%;
        }

        .col-xs-2 {
          width: 20%;
        }
      }

      img {
        display: block;
        margin: auto;
        margin-top: 30px;
      }

      .rotate90 {
        -ms-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-top: 0;

        @media (max-width: 991px) {
          margin-top: 25px;
        }
      }

      .rotate180 {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      table,
      td {
        border: none;
        white-space: normal;

        &.no-padding {
          padding: 0;
        }
      }

      .circle {
        background: @brand-secondary;
        height: 50px;
        width: 50px;
        line-height: 50px;
        -moz-border-radius: 100px;
        -webkit-border-radius: 100px;
        border-radius: 100px;
        display: block;
        position: relative;
        border: 2px solid @brand-light-grey;
        margin: auto;
        text-align: center;
        vertical-align: bottom;
        font-size: 1.8em;

        .fa {
          color: @brand-white;
        }
      }

      p {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
      }
    }
    //End
  }

  &.twitter-widget {
    iframe {
      width: 100% !important;
    }
  }

  &.sign-post-widget {
    -moz-transition: background 0.5s ease-in-out;
    -o-transition: background 0.5s ease-in-out;
    -webkit-transition: background 0.5s ease-in-out;
    transition: background 0.5s ease-in-out;

    &.theme-1-signpost {
      background-color: @brand-primary;
    }

    &.theme-2-signpost {
      background-color: @brand-secondary;
    }

    &.theme-3-signpost {
      background-color: @brand-text;
    }

    &.orange-signpost {
      background: @brand-orange;
    }

    &.green-signpost {
      background: @brand-primary;
    }

    &.grey-signpost {
      background: @brand-grey;
    }

    &.blue-signpost {
      background: @brand-secondary;
    }

    &.mid-blue-signpost {
      background: @brand-tertiary;
    }

    &.red-signpost {
      background: @brand-red;
    }

    &:hover {
      &.orange-signpost {
        background: lighten(@brand-orange, 10%);
      }

      &.green-signpost {
        background: lighten(@brand-primary, 10%);
      }

      &.grey-signpost {
        background: lighten(@brand-grey, 10%);
      }

      &.blue-signpost {
        background: lighten(@brand-secondary, 10%);
      }

      &.mid-blue-signpost {
        background: lighten(@brand-tertiary, 10%);
      }

      &.red-signpost {
        background: lighten(@brand-red, 10%);
      }
    }

    &.tech-file {
      a {
        .signpost .signpost-icon img {
          top: -3px !important;
          width: 50px;
        }
      }
    }

    a {
      outline: none;
      text-decoration: none;
      display: block;
      padding: 18px 8px;
      /*&:hover {
                .signpost .signpost-icon img {
                    width: 57px;
                    top: -4px;
                    left: 18px;
                }
            }*/
    }

    .signpost {
      display: table;
      width: 100%;

      .depot-title {
        .font-size(23);
        line-height: 29px;
        color: #fff;
        text-align: center;
        padding: 10px 0 3px 0;
      }

      .postcode-select {
        padding: 0 20px;

        .chosen-container {
          height: 36px;
          line-height: 36px;
          margin-bottom: 17px;

          .chosen-single {
            padding: 1px 12px;
            height: 36px;
            line-height: 36px;

            div {
              padding: 5px 15px;
            }

            span {
              padding-right: 0;
              height: 36px;
              line-height: 36px;
            }
          }
        }
      }

      .signpost-icon {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 70px;
        // padding: 0 40px 0 20px;
        text-align: center;

        img {
          top: 0px;
          left: 21px;
          width: 46px;
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }
      }

      .signpost-text {
        display: table-cell;
        vertical-align: middle;
        .font-size(26);
        line-height: 29px;
        color: #fff;
        max-width: 170px;

        @media (max-width: 991px) {
          font-size: 20px;
        }
      }
    }

    .dropdown-signpost {
      display: block;
    }
  }

  &.banner-widget {
    position: relative;
    padding-right: 38px;

    a {
      text-decoration: none;

      &:hover h3 {
        background: lighten(@brand-primary, 5%);
      }
    }

    h3 {
      background: @brand-primary;
      margin: 0;
      color: #fff;
      .font-size(16);
      padding: 10px 15px;
      -moz-transition: background 0.4s ease-in-out;
      -o-transition: background 0.4s ease-in-out;
      -webkit-transition: background 0.4s ease-in-out;
      transition: background 0.4s ease-in-out;
    }

    .new-item {
      position: absolute;
      top: 0;
      padding: 16px 10px;
      left: 0;
      text-transform: uppercase;
      .font-size(19);
      color: #fff;
      background: @brand-orange;
    }

    #widget-banner {
      .item {
        position: relative;
      }

      .owl-controls .owl-buttons {
        position: absolute;
        top: 50%;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: -38px;

        .owl-next {
          opacity: 1;
          margin: 14px 5px;
          text-indent: -9999px;
          display: block;
          background: url(@sprite-path) no-repeat -4px -105px;
          width: 26px;
          height: 26px;

          &:hover {
            background-position: -36px -105px;
          }
        }

        .owl-prev {
          opacity: 1;
          margin: 14px 5px;
          text-indent: -9999px;
          display: block;
          background: url(@sprite-path) no-repeat -4px -139px;
          width: 26px;
          height: 26px;

          &:hover {
            background-position: -36px -139px;
          }
        }
      }
    }
  }

  &.technical-widget {
    position: relative;

    .login-links {
      position: absolute;
      top: 0px;
      right: 0px;

      a {
        position: relative;
        display: inline-block;
        padding-right: 10px;

        &:last-child {
          &:before {
            display: none;
          }
        }

        &:before {
          display: block;
          content: '/';
          width: 13px;
          text-align: center;
          position: absolute;
          top: 0;
          right: -4px;
        }
      }
    }

    h3 {
      .font-size(19);
      color: @brand-grey;
      font-weight: bold;
      margin: 0 0 20px;
      padding-right: 114px;
    }

    a {
      text-decoration: none;
    }

    p {
      .font-size(13);
      line-height: 17px;
      color: #707070;
    }

    .tech-item {
      display: table;
      width: 100%;
      margin-bottom: 25px;

      .tech-icon {
        display: table-cell;
        vertical-align: top;
        width: 52px;
        max-width: 52px;
      }

      .tech-desc {
        display: table-cell;
        vertical-align: top;

        h3 {
          .font-size(17);
          color: @base-link-color;
          font-weight: normal;
          margin: 0 0 3px;
          padding-right: 0;

          &:hover {
            color: @base-link-active-color!important;
          }
        }

        .tags {
          .font-size(13);

          ul {
            padding: 0;

            li {
              list-style-type: none;
              display: inline-block;
              padding-left: 3px;

              &:before {
                display: none;
              }
            }

            a {
              position: relative;
              display: inline-block;
              padding-right: 9px;
              color: @brand-primary;

              &:hover {
                color: lighten(@brand-primary, 5%);
              }

              &:before {
                display: block;
                content: '|';
                width: 13px;
                text-align: center;
                position: absolute;
                top: 0;
                right: -4px;
                background: none;
              }
            }
          }
        }
      }
    }
  }

  &.advertise-widget {
    position: relative;

    .owl-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    .owl-theme .owl-controls .owl-page span {
      display: block;
      margin: 5px 3px;
      width: 10px;
      height: 10px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      border-radius: 20px;
      background: #4f4f4f;
    }

    .item {
      img {
        width: 100%;
      }
    }
  }

  &.article-widget {
    position: relative;

    .article-links {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .article__thumb {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
    }

    .article-desc__date {
      margin-bottom: 10px;
    }

    .jilted-title {
      .InnerPage & {
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    h3 {
      .font-size(19);
      color: @brand-grey;
      font-weight: bold;
      margin: 0 0 20px;
      padding-right: 66px;
    }

    a {
      text-decoration: none;
    }

    p {
      .font-size(13);
      line-height: 17px;
      color: #707070;
    }

    .article-item-wrap {
      margin-bottom: 30px;
      position: relative;
      line-height: 0;

      &:hover {
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);

        .icon-area {
          background: @brand-orange;
        }
      }

      > a {
        display: inline-block;
        margin-right: 20px;
        width: 100%;
      }

      .icon-area {
        width: 34px;
        height: 34px;
        display: block;
        position: absolute;
        background: @brand-secondary;
        bottom: 0;
        right: -20px;

        @media (max-width: 991px) {
          right: 20px;
        }

        @media (max-width: 767px) {
          right: 0;
        }
      }

      .grey-divide {
        display: block;
        position: relative;
        height: 8px;
        width: 100%;
        background: #cccccc;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          content: ' ';
          display: block;
          height: 9px;
          width: 35px;
          background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 1px;
        }
      }

      .article-item {
        position: relative;
        display: flex;
        width: 100%;
        transition: box-shadow 0.5s;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0);

        .article-icon {
          vertical-align: top;
          width: 100%;
          max-width: 97px;

          // a {
          //   display: block;

          //   &:hover {
          //     .transparent-overlay {
          //       background: #000;
          //       opacity: 0.4;
          //     }
          //   }
          // }
        }

        .article-desc {
          vertical-align: top;
          padding: 10px 45px 10px 15px;
          background-color: @brand-white;
          min-height: 90px;
          width: 100%;

          h3 {
            .font-size(13);
            color: @brand-primary;
            font-weight: normal;
            margin: 0 0 3px;
            padding-right: 0;
            line-height: 1;

            &:hover {
              color: fadeout(@brand-primary, 20%);
            }
          }

          p {
            display: none;
          }

          small {
            .font-size(12);
            display: block;
            color: @brand-secondary;
            line-height: 1;
          }

          .tags {
            .font-size(13);

            a {
              position: relative;
              display: inline-block;
              padding-right: 13px;

              &:before {
                display: block;
                content: '|';
                width: 13px;
                text-align: center;
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
  }

  &.blog-widget {
    position: relative;
    padding-top: 39px;

    .blog-profile {
      position: absolute;
      top: 0;
      left: 0;
    }

    .blog-links {
      position: absolute;
      top: 0;
      right: 0px;
    }

    h3 {
      .font-size(19);
      color: @brand-grey;
      font-weight: bold;
      margin: 0 0 20px;
      padding-left: 62px;
      padding-right: 66px;
      position: relative;
      top: -37px;
    }

    a {
      text-decoration: none;
    }

    p {
      .font-size(13);
      line-height: 17px;
      color: #707070;
    }

    .blog-item-wrap {
      margin-bottom: 0px;

      .grey-divide {
        display: block;
        position: relative;
        height: 8px;
        width: 100%;
        background: #cccccc;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          content: ' ';
          display: block;
          height: 9px;
          width: 35px;
          background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 1px;
        }
      }

      .blog-item {
        display: table;
        width: 100%;

        .blog-icon {
          position: relative;
          display: table-cell;
          vertical-align: top;
          width: 105px;
          max-width: 105px;

          a {
            position: relative;
            display: block;

            &:hover {
              .transparent-overlay {
                background: #000;
                opacity: 0.4;
              }
            }
          }

          .transparent-overlay {
            background: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
          }
        }

        .blog-desc {
          display: table-cell;
          vertical-align: top;
          padding-left: 15px;

          h3 {
            .font-size(17);
            color: @base-link-color;
            font-weight: normal;
            margin: 0 0 3px;
            padding-left: 0;
            padding-right: 0;
            position: relative;
            top: 0;

            &:hover {
              color: @base-link-active-color!important;
            }
          }

          .comments {
            .font-size(13);

            ul {
              padding: 0;
              margin: 0 0 4px;

              li {
                position: relative;
                list-style-type: none;
                display: inline-block;
                padding-left: 0;

                &:last-child {
                  &:before {
                    display: none;
                  }
                }

                &:before {
                  display: block;
                  content: '|';
                  width: 13px;
                  text-align: center;
                  position: absolute;
                  color: @brand-primary;
                  top: 0;
                  left: auto;
                  right: -4px;
                  background: none;
                }
              }

              a {
                display: block;
                padding-right: 9px;
                color: @brand-primary;

                &:hover {
                  color: lighten(@brand-primary, 5%);
                }
              }
            }
          }
        }
      }
    }
  }

  &.block-content-widget {
    position: relative;

    .block-links {
      position: absolute;
      top: 0;
      right: 0px;
    }

    h3 {
      .font-size(19);
      color: @brand-grey;
      font-weight: bold;
      margin: 0 0 20px;
      padding-right: 66px;
    }

    a {
      text-decoration: none;
    }

    p {
      .font-size(13);
      line-height: 17px;
      color: #707070;
      width: 92%;
    }
  }

  &.video-popup-widget {
    a {
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      text-decoration: none;

      &:before {
        content: '\f16a';
        font-family: 'FontAwesome';
        .font-size(66);
        color: @brand-white;
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }

      &:hover {
        &:before {
          opacity: 1;
          font-size: 74px;
        }

        h3 {
          background: lighten(@brand-primary, 8%);
        }
      }

      h3 {
        margin: 0;
        color: @brand-white;
        background: @brand-primary;
        padding: 18px 10px;
        .font-size(18);
        text-align: left;
        -moz-transition: background 0.4s ease-in-out;
        -o-transition: background 0.4s ease-in-out;
        -webkit-transition: background 0.4s ease-in-out;
        transition: background 0.4s ease-in-out;
      }
    }
  }

  &.standard-page-banner {
    .page-banner-inner {
      .item {
        position: relative;

        .text-overlay {
          position: absolute;
          top: 50px;
          left: 0;

          h3 {
            background: #fff;
            color: @brand-grey;
            .font-size(36);
            display: inline-block;
            padding: 8px 25px;
            text-transform: lowercase;

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }

        .banner-logo {
          position: absolute;
          bottom: 0;
          right: 0;

          img {
            padding: 15px;
            background: #fff;
            display: inline-block;
          }
        }
      }

      &.owl-theme .owl-controls {
        margin: 0;
      }

      .share-carousel-style-btn;
    }
  }

  &.featured-products {
    // &--homepage {
    //   margin-top: 30px;
    // }

    .featured-title {
      h5 {
        float: left;
      }

      a {
        float: right;
      }
    }

    .featured-product {
      position: relative;
      overflow: hidden;
      margin-bottom: 25px;
      max-height: 263px;
      height: 263px;

      &:hover {
        .featured-text {
          h6 {
            background: @brand-orange;
          }
        }
      }

      &.tpa-style {
        max-height: 155px;

        @media (max-width: 992px) {
          max-height: 200px;
        }

        @media (max-width: 767px) {
          max-height: 1000px;
          height: auto;
        }

        img {
          width: 100%;
        }
      }

      .new {
        position: absolute;
        top: 0;
        left: 0;
        color: @brand-white;
        background: @brand-secondary;
        width: 60px;
        height: 48px;
        text-align: center;
        .font-size(14);
        padding-top: 15px;
      }

      .featured-text {
        top: 100%;
        position: absolute;
        left: 0;
        background: rgba(6, 22, 74, 0.8);
        height: 100%;
        bottom: 0;
        -moz-transition: top 0.3s ease;
        -o-transition: top 0.3s ease;
        -webkit-transition: top 0.3s ease;
        transition: top 0.3s ease;
        width: 100%;

        a {
          display: block;
          text-decoration: none;
        }

        h6 {
          width: 100%;
          background: @brand-primary;
          padding: 12px 16px;
          height: 67px;
          color: @brand-white;
          margin: -67px 0 0 0;
          -moz-transition: margin 0.3s ease;
          -o-transition: margin 0.3s ease;
          -webkit-transition: margin 0.3s ease;
          transition: margin 0.3s ease;
          line-height: 24px;
        }

        p {
          padding: 14px;
          color: @brand-white;
        }
      }
    }

    .featured-product__btn {
      width: 100%;
      height: 155px;
      border: 1px solid @brand-grey;
      background: transparent;
      color: @brand-primary;
      border: background 0.3s ease;

      &:hover {
        background: @brand-orange;
        color: @brand-white;
      }
    }
  }

  &.contact-widget {
    h3 {
      .font-size(28);
      color: @brand-grey;
      font-weight: normal;
    }

    label {
      .font-size(32);
      padding-right: 5px;
      font-weight: normal;
      color: @brand-primary;
    }

    a {
      .font-size(32);
      font-weight: normal;
      color: @brand-primary;
      text-decoration: none;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;

      &:hover {
        color: lighten(@brand-primary, 10%);
      }
    }

    .tel-contact {
      margin-bottom: 14px;
    }

    .email-contact {
      margin: 18px 0 25px;

      label {
        .font-size(16);
        padding-right: 10px;
        font-weight: bold;
      }

      a {
        .font-size(16);
        font-weight: bold;
      }
    }
  }

  .icon-area {
    z-index: 50;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .video {
    .icon-area {
      .icon-img {
        display: block;
        width: 34px;
        height: 34px;
        background: url(~@imgOld/video-feature.png) no-repeat 0px 0px;
      }
    }
  }

  .news,
  .article-item-wrap {
    .icon-area {
      .icon-img {
        display: block;
        width: 34px;
        height: 34px;
        background: url(~@imgOld/news-feature.png) no-repeat 0px 0px;
      }
    }
  }

  .casestudy {
    .icon-area {
      .icon-img {
        display: block;
        width: 34px;
        height: 34px;
        background: url(~@imgOld/case-feature.png) no-repeat 0px 0px;
      }
    }
  }
}

.blog-tags {
  position: relative;
  .font-size(13);
  padding-left: 22px;
  clear: both;

  &:before {
    position: absolute;
    top: 0px;
    left: 0;
    display: block;
    content: '\f02b';
    font-family: @fa-f;
    width: 18px;
    height: 18px;
    color: @brand-secondary;
  }

  ul {
    padding: 0;

    li {
      position: relative;
      list-style-type: none;
      display: inline-block;
      padding-left: 0;

      &:last-child {
        &:before {
          display: none;
        }
      }

      &:before {
        display: block;
        /*content: '|';*/
        width: 13px;
        text-align: center;
        position: absolute;
        color: @brand-primary;
        top: 0;
        right: -4px;
        background: none;
      }
    }

    a {
      display: block;
      padding-right: 4px;
      color: @brand-primary;

      &:hover {
        color: lighten(@brand-primary, 5%);
      }
    }
  }
}

.case-box .blog-tags ul li {
  &:before {
    content: none;
  }

  a {
    padding-right: 3px;
  }
}

.media-hub .case-box .blog-top {
  padding-right: 65px;

  h5 {
    padding-left: 53px;
  }

  img {
    position: absolute;
    left: -8px;
    top: -10px;
    width: 50px;
  }
}

@media (min-width: 767px) {
  .everything-else .widget.featured-products .featured-product:hover {
    .featured-text {
      top: 0;

      h6 {
        margin: 0;
      }
    }
  }
}

.IE8 .widget.featured-products .featured-product .featured-text {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
}

.strip {
  background: @brand-light-grey;
  margin-bottom: 4px;
  padding: 14px 10px 14px 42px;

  .strip-content {
    display: table;
    width: 100%;

    .small-icon {
      display: table-cell;
      vertical-align: middle;
      width: 22px;
    }

    .strip-text {
      display: table-cell;
      vertical-align: middle;
      .font-size(18);
      color: @brand-black;
      padding-left: 10px;
    }
  }
}

.IE8 .widget.article-widget .article-item-wrap .article-item .article-icon a:hover .transparent-overlay,
.IE8 .widget.blog-widget .blog-item-wrap .blog-item .blog-icon a:hover .transparent-overlay {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
  opacity: 1 !important;
}

.IE8 .video-popup-widget.widget a:before {
  left: 50%;
  margin-left: -30px;
  right: auto;
}

@media (max-width: 1199px) {
  .widget {
    &.sign-post-widget {
      .signpost {
        .signpost-icon {
          width: 45px;

          img {
            left: 7px;
          }
        }
      }
      /*a:hover {
                .signpost .signpost-icon {
                    img {
                        left: 6px;
                        width: 55px;
                    }
                }
            }*/
    }

    &.standard-page-banner .page-banner-inner .item {
      .text-overlay {
        top: 0;
      }

      .banner-logo img {
        max-width: 170px;
      }
    }

    &.enquiry-basket-guide.side-nav-widget {
      &.letterbox {
        display: none;
      }
      &.compact {
        display: block;
      }
    }
  }
}

@media (max-width: 991px) {
  .widget {
    &.standard-page-banner .page-banner-inner .item {
      .text-overlay h3 {
        font-size: 1.4em;
        padding: 15px;
        margin: 0;
      }
    }

    &.map-filter {
      ul li a {
        padding: 7px 30px 5px 49px;
      }
    }

    &.contact-widget {
      label {
        .font-size(26);
      }

      a {
        .font-size(26);
      }

      .email-contact {
        margin: 13px 0 21px;
        .font-size(12);
      }
    }
  }

  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    display: none;
  }

  .widget.blog-widget h3 {
    margin: 0;
  }

  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 0;
  }

  .featured-products.widget {
    // padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .widget.side-nav-widget {
    display: none;
  }

  .widget.map-filter {
    display: block;
  }

  .widget.advertise-widget {
    text-align: center;
  }

  .widget.featured-products.widget {
    // padding: 0 15px;
  }

  .widget.map-filter ul li a {
    padding: 10px 30px 9px 49px;
  }

  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    width: 105px;
  }

  .widget.blog-widget h3 {
    margin: 0 0 20px;
  }

  .striped-bg-faint-line .strip {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .widget {
    &.enquiry-basket-guide.side-nav-widget {
      &.letterbox {
        display: block;
      }

      &.compact {
        display: none;
      }
    }
  }
}

@media (max-width: 480px) {
  .widget {
    &.standard-page-banner .page-banner-inner .item {
      .text-overlay {
        position: static;
      }
    }

    &.blog-widget .blog-item-wrap .blog-item {
      .blog-desc .comments {
        .font-size(12);

        ul li {
          &:before {
            display: block;
            content: '|';
            width: 9px;
          }

          a {
            padding-right: 6px;
          }
        }
      }

      .blog-icon {
        position: relative;
        display: table-cell;
        vertical-align: top;
        width: 91px;
      }
    }
  }

  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  .widget {
    &.enquiry-basket-guide.side-nav-widget {
      &.letterbox {
        display: none;
      }

      &.compact {
        display: block;
      }
    }
  }
}

/* Widget Keyframes */
@-moz-keyframes wigglearrow {
  0% {
    left: 20px;
  }

  25% {
    left: 17px;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 17px;
  }

  100% {
    left: 20px;
  }
}

@-ms-keyframes wigglearrow {
  0% {
    left: 20px;
  }

  25% {
    left: 17px;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 17px;
  }

  100% {
    left: 20px;
  }
}

@-webkit-keyframes wigglearrow {
  0% {
    left: 20px;
  }

  25% {
    left: 17px;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 17px;
  }

  100% {
    left: 20px;
  }
}

@keyframes wigglearrow {
  0% {
    left: 20px;
  }

  25% {
    left: 17px;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 17px;
  }

  100% {
    left: 20px;
  }
}

.large-cta {
  position: relative;
  background-color: @brand-secondary;

  &.mid-colour {
    background-color: @brand-tertiary;
  }

  &.dark-colour {
    background-color: @brand-primary;
  }

  &.red-colour {
    background-color: @brand-red;
  }

  &:hover {
    .large-cta__angle-middle {
      background-color: @brand-orange;
    }

    .large-cta__angle-top {
      @media (min-width: 992px) {
        left: 78%;
      }
    }

    .large-cta__content-inner {
      @media (min-width: 992px) {
        right: 60px;
      }
    }

    .jilted-title {
      color: @brand-secondary;
      background-color: @brand-white;
      padding-right: 30px;
    }

    &.mid-colour {
      .jilted-title {
        color: @brand-tertiary;
      }
    }

    &.dark-colour {
      .jilted-title {
        color: @brand-primary;
      }
    }
  }

  .widget.sign-post-widget {
    text-align: right;
    padding: 25px 10px;
  }
}

.large-cta__holder {
  position: relative;
  margin-top: 30px;

  &:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 0;
    height: 23px;
    width: 100%;
    background-image: url('/SiteFiles/Images/blue-stripes.png');
    background-repeat: repeat-x;
  }
}

.large-cta__inner {
  overflow: hidden;
  max-height: 289px;

  @media (max-width: 767px) {
    height: auto;
    max-height: 1000px;
  }
}

.large-cta__media-area {
  transition: transform 0.5s;
  position: relative;
  display: inline-block;
  transform: translateX(-150%);

  .is-active & {
    transform: translateX(0);

    @media (max-width: 991px) {
      transform: translateX(-33%);
    }

    @media (max-width: 767px) {
      transform: translateX(0);
      overflow: hidden;
    }
  }
}

.large-cta__angle-middle {
  transition: background-color 0.5s;
  display: block;
  background-color: @brand-white;
  position: absolute;
  top: -140px;
  right: 40px;
  transform: rotate(40deg);
  width: 100px;
  height: 550px;

  @media (max-width: 991px) {
    right: 90px;
    width: 30px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.large-cta__angle-top {
  transition: left 0.5s;
  display: block;
  width: 300px;
  height: 741px;
  background-color: @brand-secondary;
  position: absolute;
  top: -220px;
  left: 98%;
  transform: rotate(40deg);
  z-index: 2;

  @media (max-width: 991px) {
    left: 85%;
  }

  @media (max-width: 767px) {
    display: none;
  }

  .mid-colour & {
    background-color: @brand-tertiary;
  }

  .dark-colour & {
    background-color: @brand-primary;
  }
}

.large-cta__content {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  max-width: 1170px;
  height: 289px;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    padding: 20px 10px;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    transform: translateX(0);
  }

  .jilted-title {
    transition: all 0.5s;
    color: @brand-white;
    background-color: transparent;
    padding: 10px 15px;
    margin-left: -15px;
    margin-bottom: 5px;
  }
}

.large-cta__content-inner {
  transition: right 0.5s;
  width: 60%;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    transform: translateY(0);
  }

  @media (min-width: 768px) and (max-width: 900px) {
    width: 55%;
  }

  @media (min-width: 901px) and (max-width: 991px) {
    width: 60%;
  }

  @media (min-width: 992px) and (max-width: 1140px) {
    width: 40%;
  }

  @media (min-width: 1141px) and (max-width: 1310px) {
    width: 50%;
  }

  h3 {
    .font-size(28);
    color: @brand-white;
  }

  p {
    .font-size(18);
    color: @brand-white;
  }

  .btn--transparent {
    .font-size(16);
    background: transparent;
    border: 1px solid @brand-white;
    color: @brand-white;

    &:hover {
      background-color: @brand-orange;
      border-color: @brand-orange;
    }
  }
}
