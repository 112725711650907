@import '~chosen-js/chosen.min.css';
@import '~@vendor/owl-carousel/owl.carousel.css';
@import '~@vendor/owl-carousel/owl.theme.css';
@import '~@vendor/owl-carousel/owl.transitions.css';
@import '~@less/vendor/bootstrap.min.css';
.home-banner {
  position: relative;
}
.banner-area .banner__bg {
  height: 460px;
  width: 100%;
  background-position: center;
  background-size: cover;
}
.banner-area .content-animate {
  position: relative;
  display: inline-block;
}
@media (max-width: 767px) {
  .banner-area .content-animate {
    margin-bottom: 30px;
  }
}
.banner-area .banner-overlay {
  position: absolute;
  z-index: 900;
  bottom: 200px;
  left: 0;
  right: 0;
}
@media (max-width: 767px) {
  .banner-area .banner-overlay {
    padding-top: 20px;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
}
.banner-area .banner-title {
  display: inline-block;
  padding: 20px;
  font-size: 36px;
  color: #fff;
  background: #06164a;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner-area .banner-title {
    background: transparent;
    color: #06164a;
    font-size: 30px;
    padding: 0;
    margin-bottom: 10px;
    line-height: 36px;
  }
}
.banner-area .banner-text {
  position: relative;
  margin: -27px 0 0 34px;
  background-color: #fff;
  width: 396px;
  display: block;
  padding: 40px 20px 30px;
  font-size: 18px;
}
@media (max-width: 767px) {
  .banner-area .banner-text {
    margin: 0;
    padding: 0;
    width: auto;
  }
}
@media (min-width: 768px) {
  .banner-area .banner-text:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 23px;
    width: 100%;
    background-image: url('/SiteFiles/Images/stripes.png');
    background-repeat: repeat-x;
  }
}
.banner-area .btn--standard {
  position: absolute;
  bottom: -25px;
  right: -30px;
  background: none;
  background-color: #ff7600;
  color: #ffffff;
  z-index: 1;
}
@media (max-width: 1199px) {
  .banner-area .btn--standard {
    bottom: -40px;
  }
}
@media (max-width: 767px) {
  .banner-area .btn--standard {
    position: relative;
    bottom: auto;
    right: auto;
    margin-left: 10px;
  }
}
@media (max-width: 991px) {
  .home-banner {
    top: 0!important;
  }
}
.basket .enquiry-form {
  padding-bottom: 50px;
}
.basket .basket-step {
  padding: 10px 0 0px;
  background: #f1f1f1 url(app/modules/~@imgOld/form-repeat.png) repeat 0px 0px;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .basket .basket-step {
    text-align: right;
  }
}
.basket .basket-step .choose-option {
  top: -151px;
  float: left;
  height: 50px;
  left: 0px;
}
.basket .basket-step .choose-option.grey {
  background-color: #575757;
}
.basket .basket-step .form-box {
  background: none;
  padding: 0;
}
.basket .basket-step .form-box input,
.basket .basket-step .form-box label,
.basket .basket-step .form-box .chosen-container,
.basket .basket-step .form-box textarea,
.basket .basket-step .form-box select {
  display: block;
  width: 100%;
  text-align: left;
}
.basket .basket-step .form-box select {
  height: 54px;
  margin-bottom: 30px;
}
.basket .basket-step .container.with-button {
  position: relative;
}
.basket .basket-step .container.with-button .btn {
  position: absolute;
  bottom: -57px;
  right: 0;
}
.basket .basket-step .container.with-button .btn .open {
  display: inline-block;
}
.basket .basket-step .container.with-button .btn .proceed {
  display: none;
}
.basket .basket-step .basket-step-main {
  display: none;
}
.basket .basket-step .basket-step-main .ret-pass {
  display: none;
}
.basket .basket-step .basket-step-main .details-options {
  font-size: 1.28571429em;
}
.basket .basket-step .basket-step-main .details-options h2 {
  color: #06164a;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'] {
  width: 100%;
  position: relative;
  background-color: #ff7600;
  background-image: none;
  font-size: 1em;
  bottom: 5px;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'].green {
  background-color: #06164a;
}
.basket .basket-step .basket-step-main .details-options input[type='submit'].grey {
  color: #575757;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 11px 35px 12px 36px;
}
.basket .basket-step .basket-step-main .details-options table {
  background-color: transparent;
}
.basket .basket-step .basket-step-main .details-options table label {
  color: #06164a;
}
.basket .basket-step .basket-step-main .details-options table input[type='text'],
.basket .basket-step .basket-step-main .details-options table input[type='email'] {
  margin: 5px 0 8px 0;
}
.basket .basket-step .basket-step-main .details-options td {
  border: none;
  padding: 0px;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog {
  display: block !important;
  width: 100%;
  position: relative;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog td {
  display: block !important;
  width: 100%;
  position: relative;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog a {
  width: 100%;
  position: relative;
  background-color: #ff7600;
  display: block;
  background-image: none;
  color: #fff;
  padding: 12px 0px;
  text-align: center;
  font-size: 1em;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog a:hover {
  border-radius: 10px;
}
.basket .basket-step .basket-step-main .details-options .LogonDialog .ErrorLabel {
  padding: 0px;
  background-color: transparent;
  border: none;
}
.basket .basket-step.active-step .container.with-button .btn .open {
  display: none;
}
.basket .basket-step.active-step .container.with-button .btn .proceed {
  display: inline-block;
}
.basket .basket-step.step2 .enquiry-btn i {
  left: 20px;
  top: 15px;
}
.basket .basket-step.step2 .enquiry-btn input[type='submit'] {
  float: right;
  position: relative;
  bottom: 0;
}
.basket .basket-step.step2 .btn {
  pointer-events: none;
}
.basket .basket-step.step2.active-step .btn {
  pointer-events: auto;
}
.basket .basket-step .grey-bar {
  width: 100%;
  display: block !important;
}
.basket .basket-step .grey-bar .green-bar {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 8px;
  width: 30%;
  background: #06164a;
}
.basket .basket-step .grey-bar .green-bar:before {
  position: absolute;
  top: 0;
  left: -30px;
  content: ' ';
  display: block;
  height: 8px;
  width: 35px;
  background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 0px;
}
.basket .basket-step .step {
  overflow: hidden;
  width: 0;
  -moz-transition: width 1.5s ease-in-out;
  -o-transition: width 1.5s ease-in-out;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  margin-bottom: 40px;
  text-align: left;
}
.basket .basket-step .step .inner-step {
  display: inline-block;
  background: #06164a;
  padding: 10px 20px 10px 0;
  white-space: nowrap;
}
.basket .basket-step .step .inner-step .step-number {
  display: inline-block;
  color: #fff;
  font-size: 1.28571429em;
}
.basket .basket-step .widget.featured-products .featured-product {
  margin-bottom: 10px;
  text-align: left;
}
.basket .basket-step .remove-item {
  margin-bottom: 15px;
  display: block;
  font-weight: bold;
  vertical-align: middle;
  text-decoration: none;
  text-align: left;
}
.basket .basket-step .remove-item .fa {
  color: #575757;
  margin-left: 10px;
  font-size: 120%;
  vertical-align: middle;
}
.basket .basket-step .remove-item:hover {
  color: #06164a;
}
.basket .basket-step .remove-item:hover .fa {
  color: #06164a;
}
.chosen-container {
  height: 54px;
  width: 100%!important;
  background: #fff;
  margin-bottom: 30px;
  font-size: 1em;
}
.chosen-container .chosen-single {
  border: none;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 54px;
}
.chosen-container .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 48px;
  height: 100%;
  padding: 14px 10px;
}
.chosen-container .chosen-single div b {
  display: block;
  width: 26px;
  height: 26px;
  background: #06164a url('/sitefiles/images/sprite-tpa.png') no-repeat -38px -48px;
}
.chosen-container .chosen-single span {
  display: block;
  height: 54px;
  line-height: 54px;
  color: #7b7b7b;
  padding-left: 7px;
  padding-right: 40px;
}
.chosen-container .chosen-drop {
  border: none;
}
.chosen-container .chosen-results li {
  height: 42px;
  line-height: 17px;
  background: #fff;
  color: #7b7b7b;
  padding-left: 12px;
  padding-right: 40px;
}
.chosen-container .chosen-results li:before {
  display: none;
}
.chosen-container .chosen-results li.highlighted {
  background: #06164a;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -38px -48px;
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: none;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.owl-carousel--default .owl-buttons .owl-prev,
.owl-carousel--default .owl-buttons .owl-next {
  position: absolute;
  z-index: 8000;
  top: 50%;
  margin-top: -13px !important;
  opacity: 1 !important;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  background: url(app/partials/~@imgOld/tpa-site/svg/icon-arrow-circle.svg) no-repeat !important;
  transform: translateY(-50%);
}
.owl-carousel--default .owl-buttons .owl-prev {
  left: 30px;
}
.owl-carousel--default .owl-buttons .owl-next {
  right: 30px;
  transform: rotate(-180deg);
}
.owl-carousel--default .owl-buttons .owl-next,
.owl-carousel--default .owl-buttons .owl-prev {
  margin-top: 0 !important;
}
.owl-carousel--default .owl-buttons .owl-next {
  transform: rotate(-180deg) translateY(50%);
}
.owl-carousel .owl-buttons .owl-next,
.owl-carousel .owl-buttons .owl-prev {
  position: absolute;
  z-index: 8000;
  top: 50%;
  margin-top: -13px !important;
  opacity: 1!important;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.owl-carousel .owl-buttons .owl-next {
  right: 30px;
}
.owl-carousel .owl-buttons .owl-prev {
  left: 30px;
}
body {
  color: #4d4d4d;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.main-content {
  margin-top: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-size: 1.78571429em;
  margin: 0 0 20px 0;
}
h1 {
  font-size: 2.57142857em;
  color: #009dff;
  font-weight: bold;
}
h1 .fa {
  margin-right: 10px;
  font-size: 140%;
}
h1 small {
  font-size: 60%;
  color: #000;
}
h2 {
  font-size: 1.78571429em;
  color: #4d4d4d;
  font-weight: bold;
}
h3,
.BlogCommentsTitle {
  color: #06164a;
  font-size: 1.78571429em;
  font-weight: bold;
}
h4 {
  font-size: 1.42857143em;
}
h5 {
  font-size: 1.28571429em;
}
h6 {
  font-size: 1.14285714em;
}
a {
  outline: none !important;
  color: #009dff;
}
a:focus,
a:hover {
  outline: none !important;
  color: #24abff;
}
blockquote {
  border-left-color: #4d4d4d;
}
select {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
}
.load-more-btn {
  background: #dfdfdf;
  color: #06164a;
  padding: 5px 15px;
  margin-bottom: 40px;
}
.load-more-btn:hover {
  background: #06164a;
  color: #ffffff;
}
.large-text {
  font-size: 1.57142857em;
}
.grey-section {
  margin-top: 25px;
  background-color: #f5f5f5;
  padding-bottom: 50px;
}
.jilted-title {
  display: inline-block;
  margin-top: -25px;
  margin-left: -5px;
  margin-bottom: 50px;
  padding: 15px 50px;
  color: #ffffff;
  background-color: #06164a;
}
@media (max-width: 991px) {
  .jilted-title {
    padding: 15px 20px;
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .jilted-title {
    margin-left: 0;
  }
}
.share-icons {
  float: right;
  display: inline-block;
  padding-top: 10px;
}
.load-overlay {
  display: none;
}
.HomePage .load-overlay {
  display: block;
  z-index: 9999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.HomePage .load-overlay .load-icon {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}
.fade-in {
  opacity: 1 !important;
  top: 0 !important;
}
.alLeft {
  text-align: left;
}
.alCenter {
  text-align: center;
}
.alRight {
  text-align: right;
}
.inner-content {
  margin-bottom: 45px;
}
.inner-content .grey-bar {
  margin-top: 45px;
}
.breadcrumbs {
  margin: 0 0 10px;
}
.breadcrumbs a,
.breadcrumbs span {
  color: #575757;
  text-decoration: none;
  margin-right: 30px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}
.breadcrumbs a:after,
.breadcrumbs span:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  right: -20px;
  color: #06164a;
  font-size: 0.8em;
  background-image: url('/Sitefiles/Images/chevron.png');
  width: 6px;
  height: 10px;
}
.breadcrumbs a.CMSBreadCrumbsCurrentItem:after,
.breadcrumbs span.CMSBreadCrumbsCurrentItem:after {
  content: none;
}
.breadcrumbs a:hover,
.breadcrumbs span:hover {
  color: #06164a;
}
.breadcrumbs .hdn {
  display: none;
}
.breadcrumbs + img,
.top-image {
  margin-bottom: 15px;
}
.striped-bg-faint-line {
  padding: 15px 0 20px;
  background-color: #f5f5f5;
  text-align: center;
}
.striped-bg-faint-line + .grey-section {
  margin-top: 50px;
}
.striped-bg-faint-line label {
  font-size: 1.17857143em;
  font-weight: 400;
  margin: 0 0 10px;
  display: block;
  text-align: center;
}
.striped-bg-faint-line__inner {
  text-align: center;
}
.striped-bg-faint-line .info-strips {
  display: block;
  float: left;
  margin: 0;
}
.striped-bg-faint-line .strip {
  display: inline-block;
  padding: 14px 25px;
  margin: 0 8px;
}
img {
  max-width: 100%;
}
.marg-btm {
  margin-bottom: 15px;
}
.swipe-table {
  display: none;
  margin-bottom: 8px;
  font-weight: bold;
}
#page-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #f2f2f2;
}
.inner-wrapper {
  background: #fff;
  min-height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  .inner-wrapper {
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -moz-transition: transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
  }
}
.inner-wrapper.move-wrapper {
  -moz-transform: translateX(350px);
  -ms-transform: translateX(350px);
  -o-transform: translateX(350px);
  -webkit-transform: translateX(350px);
  transform: translateX(350px);
}
.is-fixed .inner-wrapper {
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  -webkit-transform: none;
  transform: none;
  -moz-transition: transform 0s ease-in-out;
  -o-transition: transform 0s ease-in-out;
  -webkit-transition: transform 0s ease-in-out;
  transition: transform 0s ease-in-out;
}
input[type='submit'],
.btn {
  position: relative;
  outline: none;
  width: auto;
  float: none;
  background: #06164a url(app/modules/~@imgOld/submit-arrow.png) no-repeat left center;
  color: #fff;
  font-size: 1.42857143em;
  padding: 12px 44px 12px 18px;
  text-decoration: none;
  display: inline-block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
}
input[type='submit'].btn--standard,
.btn.btn--standard {
  padding: 12px 30px;
  background: none;
  background-color: #ff7600;
  color: #ffffff;
}
input[type='submit'].btn-orange,
.btn.btn-orange {
  background: #ff7600 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-orange:hover,
.btn.btn-orange:hover {
  background: #ff9133 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-grey,
.btn.btn-grey {
  background: #575757 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].btn-grey:hover,
.btn.btn-grey:hover {
  background: #717171 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
input[type='submit'].back-arrow,
.btn.back-arrow {
  background-image: url(app/modules/~@imgOld/back-arrow.png);
}
input[type='submit'].back-arrow.back-align-left,
.btn.back-arrow.back-align-left {
  background-position-x: 3px;
}
input[type='submit'].with-icon,
.btn.with-icon {
  padding: 12px 18px 12px;
  background-image: none !important;
}
input[type='submit'].with-icon .fa,
.btn.with-icon .fa {
  padding-right: 15px;
}
.enquiry-btn {
  float: right;
  min-width: 163px;
  margin: 0px;
  position: relative;
  right: 0;
}
.enquiry-btn .fa {
  position: absolute;
  z-index: 15;
  left: 0px;
  top: 23px;
  color: #fff;
  font-size: 1.28571429em;
}
.enquiry-btn .fa .pad-right {
  left: -60px;
  top: 20px;
}
.enquiry-btn input[type='submit'] {
  content: '\f07a';
  background: #ff7600 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
  padding: 12px 18px 12px;
  background-image: none !important;
  padding-left: 50px;
}
.enquiry-btn input[type='submit']:hover {
  background: #ff9133 url(app/modules/~@imgOld/submit-arrow.png) no-repeat right center;
}
.placeholder {
  color: #c6c6c6;
}
.CategoryListList .CategoryListItem:before {
  display: inline-block;
  padding-right: 5px;
  color: #009dff;
  content: '\f061';
  font-family: 'FontAwesome';
}
.CategoryListList .CategoryListItem.CategoryListItem > .CategoryListInner {
  font-weight: bold;
  text-transform: capitalize;
}
.main-content ul {
  padding: 0;
}
.main-content ul li {
  position: relative;
  color: #4d4d4d;
  list-style-type: none;
  padding-left: 16px;
}
.main-content ul li:before {
  display: block;
  position: absolute;
  top: calc(0.5em - 3px);
  left: 0;
  width: 10px;
  height: 10px;
  content: ' ';
  border-radius: 50%;
  background-color: #06164a;
}
.main-content ul li a {
  color: #4d4d4d;
}
.main-content ul li a:hover {
  color: #06164a;
}
.main-content .product-view ul {
  padding: 0;
}
.main-content blockquote {
  border-left-color: #06164a;
  font-size: 1.21428571em;
}
.main-content blockquote footer {
  background: none;
}
.main-content .table th {
  color: #06164a;
  font-size: 1.28571429em;
  font-weight: normal;
  border-right: 1px solid #06164a;
  border-bottom: 2px solid #06164a;
  padding-bottom: 3px;
  padding-left: 15px;
}
.main-content .table th:last-child {
  /*border-right: none;*/
}
.main-content .table td {
  font-size: 1.14285714em;
}
.main-content .table tr td {
  border-right: 1px solid #06164a;
  border-bottom: 1px solid #06164a;
  border-top: none;
  padding: 15px 10px 3px;
}
.main-content .table tr td:last-child {
  /*border-right: none;*/
}
.main-content .table tr .td-no-border {
  border: none;
}
.blog-replys .CommentDetail {
  background-color: #fcfcfc;
  /*#f90 !important;*/
  font-family: 'ff-dax-pro', Arial, sans-serif;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
}
.blog-replys .CommentDetail:nth-child(2n + 1) {
  background-color: #fdfdfd !important;
}
.form-control-error {
  color: #e94d3a;
}
.CommentDetail .table-responsive .table {
  border-collapse: collapse;
}
/*User name and admin controls row in a comment*/
.CommentDetail .table tr:nth-child(2n + 1) {
  background-color: #009dff !important;
  color: #fff;
}
.CommentDetail .table tr:nth-child(3n + 1) {
  background-color: #ebebeb !important;
  color: #009dff;
}
.HomePage .content-wrap {
  opacity: 1;
}
.animate-with-dropdown {
  position: relative;
}
.site-offline header {
  position: relative !important;
  z-index: 3000;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 1 !important;
  display: block !important;
}
.site-offline header .logo {
  left: 0;
}
.site-offline .error-wrap {
  top: 82px;
}
.site-offline .grey-bar {
  width: 100% !important;
}
.main-content .grey-bar {
  width: 100%;
}
.main-content .grey-bar.with-edge:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 8px;
  width: 35px;
  background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0 0;
}
@media (max-width: 1199px) {
  .striped-bg-faint-line label {
    float: none;
    margin: 0 0 10px 0;
  }
  .striped-bg-faint-line {
    float: none;
    display: table;
    width: 100%;
  }
  .striped-bg-faint-line .strip {
    display: table-cell;
    margin: 0;
    background: none;
    padding: 0 2px;
  }
  .striped-bg-faint-line .strip .strip-content {
    padding: 14px 10px;
    background: #e3e3e3;
  }
}
@media (max-width: 991px) {
  .content-wrap {
    margin-top: 20px !important;
    opacity: 1 !important;
  }
  .HomePage .content-wrap {
    margin-top: 0 !important;
  }
  .container.no-tab-pad {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .share-icons {
    float: none;
    display: block;
    padding-top: 10px;
  }
  .swipe-table {
    display: block;
  }
  .site-offline header .logo {
    left: 10px;
    width: 300px;
  }
  .inner-content {
    margin-bottom: 25px;
  }
  .mobGap {
    margin-bottom: 25px;
  }
  .striped-bg-faint-line .info-strips {
    display: block;
  }
  .striped-bg-faint-line .info-strips .strip {
    display: block;
    float: left;
    margin: 0 2px 2px 0;
    padding: 0;
    width: 48%;
  }
  .striped-bg-faint-line .info-strips .strip .strip-content {
    padding: 14px 10px;
  }
}
@media (max-width: 480px) {
  select {
    width: 100% !important;
    height: 40px;
  }
  .site-offline .error-wrap {
    margin-bottom: 300px;
  }
  #advertise-carousel {
    display: none !important;
  }
}
@media (max-width: 450px) {
  .inner-wrapper.move-wrapper {
    -moz-transform: translateX(244px);
    -ms-transform: translateX(244px);
    -o-transform: translateX(244px);
    -webkit-transform: translateX(244px);
    transform: translateX(244px);
  }
  .striped-bg-faint-line .info-strips .strip {
    float: none;
    margin: 0 0 2px 0;
    padding: 0;
    width: 100%;
  }
  .striped-bg-faint-line .info-strips .strip .strip-content {
    padding: 14px 10px;
  }
  .btn-orange {
    clear: both;
    right: 30px;
  }
}
.hdn {
  display: none;
}
/* CMS Desk */
.EditMode .homepage-signposts .EditorWidget.WebPartInner {
  float: left;
  width: 20%;
}
.EditMode .homepage-signposts .EditorWidget.WebPartInner .WebPartContent .signpost-item {
  width: 100%;
}
.EditMode .striped-bg-faint-line .EditorWidget.WebPartInner {
  float: left;
}
.my-profile-logout {
  text-align: right;
  margin-bottom: 39px;
}
.orig {
  display: none;
}
.load-more-btn {
  display: none;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0px solid !important;
    /*overflow-x: hidden;*/
  }
  .col-sm-6.col-md-4.guest-checkout {
    padding-top: 25px;
  }
}
.option-btn {
  max-width: 188px;
}
.no-js-div {
  display: none !important;
}
.enquire-now {
  display: none !important;
}
.consent-item {
  margin-bottom: 34px;
}
.consent-item .btn {
  margin-bottom: 20px;
}
.consent-item .btn-manual-revoke {
  display: inline-block;
  position: relative;
  margin: 0;
  border: 0;
  padding: 12px 36px;
  color: #fff;
  background-color: #ff7600;
  font-size: 1.28571429em;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0;
  transition: background 0.3s ease;
  margin-bottom: 20px;
}
.consent-item .grey-bar:last-of-type {
  display: none;
}
.striped-bg-faint-line + .indent-strip {
  transform: translateY(0);
  margin-bottom: 0;
}
.indent-strip {
  transform: translateY(-50%);
  margin-bottom: -45px;
}
.grey-section .indent-strip {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .indent-strip {
    transform: translateY(0);
    margin-bottom: 0;
  }
}
.indent-strip .jilted-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2em;
}
@media (max-width: 767px) {
  .indent-strip .jilted-title {
    font-size: 1.42857143em;
    margin: 10px 0;
  }
}
.indent-strip .widget {
  margin-bottom: 0;
}
.indent-strip__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
.indent-strip__cell {
  flex: 1 0 33%;
  max-width: 33%;
}
@media (max-width: 991px) {
  .indent-strip__cell {
    flex-wrap: wrap;
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.indent-strip__cell:last-child .indent-strip__cell-inner {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .indent-strip__cell:last-child .indent-strip__cell-inner {
    display: block;
  }
}
.indent-strip--quad .indent-strip__cell {
  flex: 1 0 25%;
  max-width: 25%;
}
@media (max-width: 991px) {
  .indent-strip--quad .indent-strip__cell {
    flex: 1 0 100%;
    max-width: 100%;
  }
}
.indent-strip__cell-inner {
  padding: 0 20px;
}
@media (max-width: 991px) {
  .indent-strip__cell-inner {
    padding: 0 10px;
  }
}
.latest-news-text {
  font-size: 1.28571429em;
  color: #009dff;
  margin-bottom: 10px;
  display: none;
}
.owl-theme .owl-controls .owl-buttons .owl-next,
.owl-theme .owl-controls .owl-buttons .owl-prev {
  z-index: 10;
}
.owl-theme .owl-controls .owl-buttons .owl-next {
  transform: rotate(-180deg) translateY(50%);
}
.table-responsive .CaptchaTable {
  width: auto;
}
.table-responsive .CaptchaTable td {
  border: none !important;
}
.table-responsive .CaptchaTable td img {
  padding-top: 30px;
}
.map {
  position: relative;
  margin-bottom: 90px;
}
.map .inner-map {
  width: 615px;
  margin: 0 auto;
}
.map .grey-bar {
  margin-top: 30px;
}
.map .map-popup {
  width: 615px;
  display: none;
  position: absolute;
  z-index: 4000;
  top: 0px;
  left: 0px;
  border: 1px solid #06164a;
  background: #fff;
  padding: 25px 0;
}
.map .map-popup .popup-content {
  display: none;
  position: relative;
}
.map .map-popup .popup-content .close-btn {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 1.78571429em;
  position: absolute;
  padding: 10px;
  top: -25px;
  right: 0px;
  cursor: pointer;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.map .map-popup .popup-content .close-btn:before {
  display: block;
  color: #575757;
  content: '\f00d';
  font-family: 'FontAwesome';
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.map .map-popup .popup-content .close-btn:hover {
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.map .map-popup .popup-content .close-btn:hover:before {
  color: #06164a;
}
.map .map-popup .popup-content.active {
  display: block;
}
.map .map-popup.opened {
  -moz-animation: popupbounce 0.4s ease-in-out;
  -o-animation: popupbounce 0.4s ease-in-out;
  -webkit-animation: popupbounce 0.4s ease-in-out;
  animation: popupbounce 0.4s ease-in-out;
}
.map .map-popup:after {
  position: absolute;
  bottom: -15px;
  left: 200px;
  content: ' ';
  display: block;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat -83px -54px;
  width: 30px;
  height: 15px;
}
.map .map-popup .popup-box {
  width: 33%;
  float: left;
}
.map .map-popup p {
  margin-top: 0;
  font-size: 0.92857143em;
  padding-left: 32px;
}
.map .map-popup ul {
  padding-left: 32px;
  margin: 0;
}
.map .map-popup address,
.map .map-popup .directions {
  font-size: 0.92857143em;
  padding-left: 24%;
}
.map .map-popup label {
  padding-right: 8px;
}
.map .map-popup h4 {
  display: inline-block;
  font-size: 1.28571429em;
  color: #fff;
  background: #06164a;
  padding: 10px 20px 10px 32px;
  margin-bottom: 10px;
}
.map .map-point {
  cursor: pointer;
}
.map .map-point .path {
  fill: #FE9903;
  -moz-animation: fillpath 2s ease-in-out infinite;
  -o-animation: fillpath 2s ease-in-out infinite;
  -webkit-animation: fillpath 2s ease-in-out infinite;
  animation: fillpath 2s ease-in-out infinite;
}
.IE8 .map .map-popup {
  position: relative!important;
  left: 0!important;
  top: 0!important;
}
.IE8 .map .map-popup:first-child {
  display: block;
}
.IE8 .map #Layer_1 img {
  display: none;
}
@media (max-width: 991px) {
  .map .inner-map {
    width: 100%;
  }
  .map svg {
    width: 100%;
  }
  .map .map-popup {
    width: 442px;
  }
  .map .map-popup a {
    -ms-word-break: break-word;
    word-break: break-word;
  }
  .map .map-popup label {
    display: block;
    max-width: 100%;
    margin-bottom: 0px;
  }
  .map .map-popup p {
    padding-left: 19px;
  }
  .map .map-popup ul {
    padding-left: 17px;
  }
  .map .map-popup h4 {
    padding: 6px 11px 4px 17px;
  }
  .map .map-popup:after {
    left: 130px;
  }
}
@media (max-width: 767px) {
  .map {
    margin-bottom: 25px;
  }
  .map .map-popup {
    position: relative;
    top: 0!important;
    left: 0!important;
    width: 100%;
  }
  .map .map-popup.opened {
    -moz-animation: none;
    -o-animation: none;
    -webkit-animation: none;
    animation: none;
  }
  .map .map-popup:after {
    display: none;
  }
  .map svg {
    display: none;
  }
  .map-filter h3 {
    position: relative;
    cursor: pointer;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .map-filter h3.slide-open {
    background: #092170 !important;
  }
  .map-filter h3.slide-open:before {
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  .map-filter h3:hover {
    background: #092170 !important;
  }
  .map-filter h3:before {
    position: absolute;
    top: 12px;
    right: 12px;
    content: '\f0ab';
    font-family: 'FontAwesome';
    font-size: 1.71428571em;
    background: none;
    width: 30px;
    height: 30px;
    display: block;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .map-filter .link-wrap {
    display: none;
  }
}
@media (max-width: 500px) {
  .map .map-popup .info-row .popup-box {
    width: 50%;
    padding-right: 15px;
  }
  .map .map-popup .info-row .popup-box address,
  .map .map-popup .info-row .popup-box .directions {
    padding-left: 19px;
  }
  .map .map-popup .info-row .popup-box:first-child {
    width: 100%;
  }
  .map .map-popup .products-row .popup-box {
    width: 50%;
  }
}
@-moz-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-ms-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-webkit-keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@keyframes popupbounce {
  0% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
  50% {
    -moz-transform: scale(1.1) translateY(-5%) translateX(2%);
    -ms-transform: scale(1.1) translateY(-5%) translateX(2%);
    -o-transform: scale(1.1) translateY(-5%) translateX(2%);
    -webkit-transform: scale(1.1) translateY(-5%) translateX(2%);
    transform: scale(1.1) translateY(-5%) translateX(2%);
  }
  100% {
    -moz-transform: scale(1) translateY(0%);
    -ms-transform: scale(1) translateY(0%);
    -o-transform: scale(1) translateY(0%);
    -webkit-transform: scale(1) translateY(0%);
    transform: scale(1) translateY(0%);
  }
}
@-moz-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #dfdfdf;
  }
  100% {
    fill: #FE9903;
  }
}
@-ms-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #dfdfdf;
  }
  100% {
    fill: #FE9903;
  }
}
@-webkit-keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #dfdfdf;
  }
  100% {
    fill: #FE9903;
  }
}
@keyframes fillpath {
  0% {
    fill: #FE9903;
  }
  50% {
    fill: #dfdfdf;
  }
  100% {
    fill: #FE9903;
  }
}
.error-wrap {
  position: relative;
}
.error-wrap .error-text {
  text-align: center;
  font-size: 1.71428571em;
  line-height: 28px;
  font-weight: bold;
  margin: 300px 0 200px;
}
.error-wrap .error-text a {
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
  position: relative;
}
.error-wrap .error-text a:before {
  display: block;
  content: ' ';
  width: 100%;
  height: 2px;
  background: #009dff;
  position: absolute;
  z-index: 15;
  bottom: 16px;
  opacity: 0;
  left: 0px;
  right: 0;
  margin: 0 auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.error-wrap .error-text a:hover:before {
  opacity: 1;
  bottom: 0px;
}
.error-wrap .error-banner {
  position: absolute;
  top: -260px;
  left: 380px;
}
.error-wrap .error-banner .you-seem-lost {
  position: relative;
  left: -1px;
  -moz-transform-origin: center top;
  -webkit-animation: swing 4s infinite ease-in-out;
  -o-animation: swing 4s infinite ease-in-out;
  -moz-animation: swing 4s infinite ease-in-out;
  animation: swing 4s infinite ease-in-out;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
}
.error-wrap .error-banner .you-seem-lost__container {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: skewY(-4deg);
  width: 290px;
  text-align: center;
}
.error-wrap .error-banner .you-seem-lost__container h3 {
  font-size: 25px;
  color: #fff;
}
@media (max-width: 768px) {
  .error-wrap .error-banner .you-seem-lost__container {
    width: 230px;
  }
  .error-wrap .error-banner .you-seem-lost__container h3 {
    font-size: 20px;
  }
}
@media (max-width: 362px) {
  .error-wrap .error-banner .you-seem-lost__container {
    width: 180px;
  }
}
.error-wrap .error-banner .pin-image {
  position: absolute;
  top: -34px;
  left: 155px;
  z-index: 10;
}
.error-wrap .error-img-left {
  position: absolute;
  top: -95px;
  left: 150px;
}
.error-wrap .error-img-right {
  position: absolute;
  top: -139px;
  right: -41px;
}
@media (max-width: 1199px) {
  .error-wrap .error-img-left {
    left: 63px;
  }
  .error-wrap .error-img-right {
    top: -92px;
    right: -27px;
    width: 318px;
  }
  .error-wrap .error-banner {
    top: -260px;
    left: 300px;
  }
}
@media (max-width: 991px) {
  .error-wrap .error-banner {
    top: -260px;
    left: 186px;
  }
  .error-wrap .error-text {
    font-size: 1.42857143em;
  }
  .error-wrap .error-img-left {
    left: -21px;
  }
  .error-wrap .error-img-right {
    top: -42px;
    right: -35px;
    width: 236px;
  }
}
@media (max-width: 767px) {
  .error-wrap .error-banner {
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 294px;
    top: -209px;
  }
  .error-wrap .error-text {
    margin: 260px 0 200px;
  }
  .error-wrap .error-banner .pin-image {
    left: 111px;
    z-index: 10;
  }
  .error-wrap .error-img-left {
    top: auto;
    bottom: -200px;
    left: 10px;
    z-index: -1;
  }
  .error-wrap .error-img-right {
    top: 90px;
    right: 10px;
    width: 230px;
    z-index: -1;
  }
}
@media (max-width: 480px) {
  .error-wrap .error-img-right {
    top: 144px;
    right: 10px;
    width: 154px;
    z-index: -1;
  }
  .error-wrap .error-img-left {
    left: 10px;
    z-index: -1;
    width: 133px;
  }
}
@media (max-width: 400px) {
  .error-wrap .error-text {
    margin: 260px 0 160px;
  }
  .error-wrap .error-img-right {
    top: 104px;
  }
  .error-wrap .error-img-left {
    bottom: -162px;
  }
}
@media (max-width: 362px) {
  .error-wrap .error-img-right {
    right: -12px;
  }
  .error-wrap .error-img-left {
    left: -12px;
  }
  .error-wrap .error-banner .pin-image {
    left: 90px;
    top: -37px;
  }
  .error-wrap .error-banner {
    width: 242px;
    top: -193px;
  }
}
@-moz-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@-ms-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@-webkit-keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
@keyframes swing {
  0% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  100% {
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  margin: 0;
  padding: 15px;
  color: #444;
  white-space: nowrap;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/sitefiles/images/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 8060;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/sitefiles/images/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  z-index: 8040;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.fancybox-nav {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  z-index: 8040;
  width: 40%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  /* helps IE */
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  z-index: 8040;
  visibility: hidden;
  margin-top: -18px;
  width: 36px;
  height: 34px;
  cursor: pointer;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  overflow: visible !important;
  max-width: 99999px;
  max-height: 99999px;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8010;
  display: none;
  overflow: hidden;
  background: url('/sitefiles/images/fancybox/fancybox_overlay.png') repeat;
}
.fancybox-overlay-fixed {
  position: fixed;
  right: 0;
  bottom: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  position: relative;
  z-index: 8050;
  visibility: hidden;
  text-shadow: none;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  right: 50%;
  bottom: 0;
  z-index: 8050;
  margin-bottom: -35px;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.8);
  background: transparent;
  color: #FFF;
  text-shadow: 0 1px 2px #222;
  white-space: nowrap;
  font-weight: bold;
  line-height: 24px;
  /* Fallback for web browsers that doesn't support RGBa */
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('/sitefiles/images/fancybox/fancybox_sprite@2x.png');
    -moz-background-size: 44px 152px;
    -o-background-size: 44px 152px;
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('/sitefiles/images/fancybox/fancybox_loading@2x.gif');
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
#fancybox-buttons {
  position: fixed;
  left: 0;
  z-index: 8050;
  width: 100%;
}
#fancybox-buttons.top {
  top: 10px;
}
#fancybox-buttons.bottom {
  bottom: 10px;
}
#fancybox-buttons ul {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 166px;
  height: 30px;
  border: 1px solid #111;
  border-radius: 3px;
  background: -moz-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -ms-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -o-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #444444), color-stop(50%, #343434), color-stop(50%, #292929), color-stop(100%, #333333));
  background: -webkit-linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: linear-gradient(top, #444444 0%, #343434 50%, #292929 50%, #333333 100%);
  background: #323232;
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
  list-style: none;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444', endColorstr='#222222', GradientType=0);
}
#fancybox-buttons ul li {
  float: left;
  margin: 0;
  padding: 0;
}
#fancybox-buttons a {
  display: block;
  width: 30px;
  height: 30px;
  outline: none;
  background-color: transparent;
  background-image: url('/sitefiles/images/fancybox/fancybox_buttons.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
  opacity: 0.8;
}
#fancybox-buttons a:hover {
  opacity: 1;
}
#fancybox-buttons a.btnPrev {
  background-position: 5px 0;
}
#fancybox-buttons a.btnNext {
  border-right: 1px solid #3e3e3e;
  background-position: -33px 0;
}
#fancybox-buttons a.btnPlay {
  background-position: 0 -30px;
}
#fancybox-buttons a.btnPlayOn {
  background-position: -30px -30px;
}
#fancybox-buttons a.btnToggle {
  width: 35px;
  border-right: 1px solid #3e3e3e;
  border-left: 1px solid #111;
  background-position: 3px -60px;
}
#fancybox-buttons a.btnToggleOn {
  background-position: -27px -60px;
}
#fancybox-buttons a.btnClose {
  width: 35px;
  border-left: 1px solid #111;
  background-position: -56px 0px;
}
#fancybox-buttons a.btnDisabled {
  opacity: 0.4;
  cursor: default;
}
footer {
  background: #575757;
  color: #fff;
  padding-top: 30px;
  display: none;
}
footer .center-area {
  text-align: center;
}
@media (max-width: 767px) {
  footer .center-area {
    text-align: left;
    margin: 10px 0;
  }
}
footer.tpa-style {
  background: #f5f5f5;
  color: #06164a;
}
footer.tpa-style .green-hover h3 {
  color: #06164a;
}
@media (max-width: 767px) {
  footer.tpa-style .green-hover h3 {
    color: #ffffff;
  }
}
footer.tpa-style .footer-links ul li:before {
  color: #06164a;
}
footer.tpa-style .footer-links ul li a {
  color: #06164a;
}
footer.tpa-style .footer-btm {
  color: #ffffff;
  background: #06164a;
}
footer.tpa-style .footer-btm .bottom-links ul li a:before {
  color: #06164a;
}
footer.tpa-style .footer-btm a {
  color: #ffffff;
}
footer.tpa-style .fa-phone {
  font-size: 19px;
  margin-right: 3px;
}
footer h3 {
  font-size: 1.28571429em;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
footer .green-hover {
  /*&:hover {
            > h3 {
                color: #859A3E;
                .font-size(19);
            }
        }*/
}
footer .green-hover h3 {
  color: #fff;
  font-weight: normal;
}
footer .footer-btm {
  background: #009dff;
  margin-top: 40px;
  padding: 15px 0;
}
footer .footer-btm .bottom-links {
  text-align: right;
}
footer .footer-btm .bottom-links ul {
  padding: 0;
  margin: 0;
}
footer .footer-btm .bottom-links ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  padding-right: 14px;
}
footer .footer-btm .bottom-links ul li:before {
  content: '|';
  display: block;
  color: #fff;
  position: absolute;
  top: 0px;
  right: 4px;
}
footer .footer-btm .bottom-links ul li:last-child {
  padding-right: 0;
}
footer .footer-btm .bottom-links ul li:last-child:before {
  display: none;
}
footer .footer-btm .bottom-links ul li a {
  text-decoration: none;
  color: #fff;
}
footer .footer-btm .bottom-links ul li a:hover {
  text-decoration: underline;
}
footer .footer-links {
  float: left;
  width: 50%;
}
footer .footer-links ul {
  padding: 0;
  margin: 0;
}
footer .footer-links ul li {
  list-style-type: none;
  padding-left: 9px;
  position: relative;
}
footer .footer-links ul li:before {
  display: block;
  content: ' ';
  background: #06164a;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 0px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
footer .footer-links ul li:hover:before {
  background: #06164a;
  width: 2px;
  height: 14px;
  position: absolute;
  top: 2px;
  left: 0px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 0;
}
footer .footer-links ul li a {
  color: #fff;
  text-decoration: none;
}
footer .social-links {
  float: left;
  width: 50%;
}
footer .social-links .social-link {
  width: 33px;
  height: 33px;
  display: inline-block;
  margin-right: 6px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -moz-transition-property: transform;
  -o-transition-property: transform;
  transition-property: transform;
}
footer .social-links .social-link:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  -moz-transition-property: transform, opacity;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
footer .social-links .social-link:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}
footer .social-links .social-link:hover:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}
footer .social-links .social-link .i-youtube {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/youtube-icon.svg) no-repeat;
}
footer .social-links .social-link .i-twitter {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/twitter-icon.svg) no-repeat;
}
footer .social-links .social-link .i-linkedin {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/linkedin-icon.svg) no-repeat;
}
footer .social-links .social-link .i-facebook {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(/SiteFiles/Images/facebook-icon.svg) no-repeat;
}
footer .footer-info {
  float: left;
  width: 50%;
}
.InnerPage footer {
  display: block;
}
.cookie-panel {
  background: #171717;
  color: #fff;
  opacity: 0.9;
  bottom: 0;
  box-shadow: -1px -2px 2px 2px #999;
  display: none;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.cookie-panel .cookie-close {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 1.5em;
  color: #fff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cookie-panel .cookie-close:hover {
  color: #06164a;
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.cookie-panel .cookie-title {
  font-size: 2.57142857em;
  color: #009dff;
  font-weight: bold;
}
.cookie-container .cookie-panel-gdpr {
  background: #171717;
  opacity: 0.9;
  color: #fff;
  box-shadow: -1px -2px 2px 2px #999;
  padding: 20px;
  width: 100%;
}
.cookie-container .cookie-panel-gdpr .cookie-close {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 1.5em;
  color: #fff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cookie-container .cookie-panel-gdpr .cookie-close:hover {
  color: #06164a;
  -moz-transform: rotateZ(90deg);
  -ms-transform: rotateZ(90deg);
  -o-transform: rotateZ(90deg);
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.cookie-container .cookie-tab-ctrl {
  background: #171717;
  color: #fff;
  display: inline-block;
  padding: 13px;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.IE8 footer {
  display: block !important;
}
@media (max-width: 991px) {
  footer {
    padding-top: 20px;
  }
  footer .footer-info img {
    width: 44px;
  }
}
@media (max-width: 767px) {
  footer .green-btm .bottom-links {
    text-align: left;
    margin-top: 5px;
  }
}
@media (max-width: 550px) {
  footer {
    padding-top: 20px;
  }
  footer address {
    margin-bottom: 10px;
  }
  footer .container {
    padding: 0;
  }
  footer .footer-links {
    width: 100%;
    text-align: center;
    margin: 10px 0 5px;
    background: #757575;
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 11px;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  footer .footer-links.footer-open {
    background: #838383;
  }
  footer .footer-links.footer-open h3:after {
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
  }
  footer .footer-links:hover {
    background: #838383;
  }
  footer .footer-links ul {
    display: none;
  }
  footer .footer-links ul li {
    padding: 8px 0;
  }
  footer .footer-links ul li:last-child {
    margin-bottom: 7px;
  }
  footer .footer-links ul li:hover {
    background: #757575;
  }
  footer .footer-links ul li:hover a {
    color: #ff7600;
  }
  footer .footer-links ul li a {
    display: block;
    font-size: 1.21428571em;
    -moz-transition: color 0.4s ease-in-out;
    -o-transition: color 0.4s ease-in-out;
    -webkit-transition: color 0.4s ease-in-out;
    transition: color 0.4s ease-in-out;
  }
  footer .footer-links ul li:before {
    display: none;
  }
  footer .footer-links h3 {
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 12px 0px 9px;
  }
  footer .footer-links h3:after {
    font-family: 'FontAwesome';
    content: '\f13a';
    display: block;
    font-size: 22px;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 8px;
    right: 18px;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  footer .green-hover:hover h3 {
    font-size: 1.28571429em;
    color: #fff;
  }
  footer .social-links {
    padding: 16px 0 8px;
    width: 100%;
    text-align: center;
  }
  footer .social-links h3 {
    display: none;
  }
  footer .footer-info {
    width: 100%;
    text-align: center;
  }
  footer .footer-info h3 {
    display: none;
  }
}
.form-box {
  background: #f1f1f1 url(app/modules/~@imgOld/form-repeat.png) repeat 0px 0px;
  padding: 60px 60px 28px 60px;
  margin: 0px 0 20px;
}
.form-box label {
  font-size: 1.42857143em;
  font-weight: normal;
  color: #06164a;
  margin-bottom: 10px;
}
.form-box input,
.form-box .form-control,
.form-box textarea {
  margin-bottom: 30px;
  background: #fff;
  border: none !important;
  height: 54px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 10px 15px;
  color: #7b7b7b;
}
.form-box input:focus,
.form-box .form-control:focus,
.form-box textarea:focus {
  border: 1px solid #06164a !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
}
.form-box input[type='submit'] {
  float: right;
}
.form-box .editing-form-control-nested-control .checkbox {
  float: left;
}
.form-box .explanation-text {
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: 12px;
}
.enquiry-form .alert {
  margin-top: 0;
}
textarea {
  min-height: 110px;
  height: auto !important;
  color: #7b7b7b;
  padding: 15px;
}
.small-input {
  outline: none;
}
.EditingFormErrorLabel,
.ErrorLabel {
  width: 100%;
  display: block;
  line-height: 1.3em;
  margin-top: 10px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.hideErrorLabels .ErrorLabel {
  padding: 0px;
  border: 0px;
  float: none;
}
.enquiry-form {
  text-align: left;
}
input,
select,
textarea {
  color: #4d4d4d;
}
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(138, 138, 138, 0.75);
}
input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(138, 138, 138, 0.75);
}
input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(138, 138, 138, 0.75);
}
input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(138, 138, 138, 0.75);
}
.btn,
button,
input[type='button'],
input[type='submit'] {
  display: inline-block;
  position: relative;
  margin: 0;
  border: 0;
  padding: 12px 36px;
  color: #ffffff;
  background-color: #06164a;
  font-size: 1.28571429em;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -moz-transition: background ease 0.3s;
  -o-transition: background ease 0.3s;
  -webkit-transition: background ease 0.3s;
  transition: background ease 0.3s;
}
.btn:hover,
button:hover,
input[type='button']:hover,
input[type='submit']:hover,
.btn:focus,
button:focus,
input[type='button']:focus,
input[type='submit']:focus {
  color: #ffffff;
  background-color: #0a2479;
}
input[type='checkbox'],
input[type='radio'] {
  /*display: none;*/
}
.checkbox input[type='checkbox'] {
  margin-top: 2px;
  height: auto;
  position: relative;
  display: block;
  float: left;
  margin-left: 0px;
  margin-bottom: 0px;
}
.label-check,
.label-radio {
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0 26px 0 0;
  padding: 0 0 0 26px;
  vertical-align: middle;
  line-height: 1.57142857em;
}
.label-check::before,
.label-radio::before,
.label-check::after,
.label-radio::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 14px;
  width: 14px;
  margin-top: -7px;
}
.label-check::before,
.label-radio::before {
  background: #ffffff;
}
.label-check::after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0;
  color: #575757;
  line-height: 1.07142857em;
}
.label-check.checked::after {
  font-size: 1em;
}
.label-radio::before {
  border-radius: 7px;
}
.label-radio::after {
  left: 3px;
  height: 8px;
  width: 8px;
  margin-top: -4px;
  border-radius: 4px;
}
.label-radio.checked::after {
  background: #575757;
}
.error-control {
  border: 1px solid #a94442;
  background: #f9f1f1;
}
.error-message {
  display: block;
  padding: 8px;
  color: #a94442;
}
.alert {
  width: 100%;
  float: left;
  line-height: 1.3em;
  margin-top: 10px;
}
.ErrorLabel {
  margin: 10px 0;
}
.input-box {
  clear: both;
}
.LoginPanelPasswordRetrieval {
  display: inline-block;
}
.EditingFormInfoLabel,
.InfoLabel {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
}
.no-width {
  width: auto;
}
.checkbox-list-vertical input[type='checkbox'] {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.checkbox-list-vertical input[type='checkbox'] + label {
  padding-left: 32px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 1em;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox-list-vertical input[type='checkbox']:checked + label {
  background-position: 0 -20px;
}
.checkbox-list-vertical label {
  background-image: url(app/modules/~@imgOld/checkbox2.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #7b7b7b;
}
@media (max-width: 480px) {
  .form-box {
    padding: 38px 26px 22px 26px;
    margin-bottom: 10px;
  }
}
.blog-comments .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.no-js-btn {
  display: none !important;
}
.gdpr-checkbox.checkbox input[type='checkbox'] {
  position: fixed;
  width: 13px;
}
.form-group--conditions {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
  max-height: 40vh;
}
.terms-checkbox:disabled + .terms-checkbox__label {
  opacity: 0.5;
  cursor: not-allowed;
}
.terms-btn:disabled {
  cursor: not-allowed;
}
.editing-form-control-nested-control a {
  margin-top: 10px;
}
.download-panel .btn-primary:disabled {
  background-color: #575757;
  border-color: #575757;
}
.primary-header {
  position: relative;
}
.primary-header--padded {
  margin-bottom: 56px;
}
.HomePage header {
  opacity: 0;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: block!important;
  top: 0;
}
header {
  background: #ffffff;
  /*border: 1px solid #f3f3f3;*/
  position: absolute;
  z-index: 3000;
  width: 100%;
  left: 0;
}
header .header__main {
  display: flex;
}
header .header__main .depot-area--btn {
  padding: 12px 25px 12px 15px;
  border: 1px solid #06164a;
  color: #06164a;
  display: inline-block;
}
header .header__main .depot-area--btn .depot-icon {
  margin-right: 10px;
}
header .header__main .depot-area--btn .fa {
  font-size: 1.28571429em;
}
header .header__main .search-area {
  display: flex;
  align-self: center;
  position: relative;
  margin-left: 20px;
}
header .header__main .search-icon .fa {
  font-size: 1.57142857em;
  color: #ffffff;
}
header .header__main .drop-search {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  overflow: hidden;
}
header .header__social-area {
  list-style: none;
  display: flex;
  align-self: center;
  margin: 0 25px 0 0;
}
@media (max-width: 767px) {
  header .header__social-area {
    padding-left: 0;
  }
}
header .header__social-area li {
  padding: 0 14px;
}
header .header__social-area .fa {
  font-size: 1.42857143em;
  color: #06164a;
}
header .header__main-left {
  flex: 0 0 auto;
}
header .header__main-right {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  flex: 1 0 auto;
}
header.tpa-style {
  position: relative;
  left: auto;
}
@media (max-width: 767px) {
  header.tpa-style {
    padding-top: 1px;
  }
}
header.tpa-style .logged-in {
  text-align: right;
  padding-top: 10px;
}
header.show-number .top-links .right-links .header-numbers {
  display: block;
}
header .desktop-nav {
  padding: 0 18px;
  position: relative;
  background-color: #009dff;
}
header .desktop-nav nav ul {
  margin: 0;
  padding: 0;
}
header .desktop-nav nav ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
header .desktop-nav nav ul li .drop {
  z-index: 10;
  opacity: 0;
  display: none;
  position: absolute;
  top: 67px;
  padding: 70px 60px;
  left: 0;
  background: #fff;
  width: 100%;
  border-top: 5px solid #26358c;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
header .desktop-nav nav ul li .drop .dropdown-logo {
  margin-top: 15px;
}
header .desktop-nav nav ul li .drop ul li {
  display: block;
}
header .desktop-nav nav ul li .drop ul li a {
  padding: 8px 0;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  color: #06164a;
  height: auto;
  line-height: 1;
}
header .desktop-nav nav ul li .drop ul li a:before {
  position: absolute;
  top: 4px;
  left: -9px;
  display: block;
  content: ' ';
  width: 2px;
  height: 0;
  background: #06164a;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
header .desktop-nav nav ul li .drop ul li a:hover {
  background: none;
  color: #009dff;
}
header .desktop-nav nav ul li .drop ul li a:hover:before {
  height: 27px;
}
header .desktop-nav nav ul li .drop h3 {
  font-size: 1.28571429em;
  margin-top: 0;
  margin-bottom: 20px;
  color: #06164a;
}
header .desktop-nav nav ul li.drop-open > a {
  background-color: #26358c;
}
header .desktop-nav nav ul li.drop-open > a:hover {
  -moz-animation: none;
  -o-animation: none;
  -webkit-animation: none;
  animation: none;
}
header .desktop-nav nav ul li a {
  color: #ffffff;
  display: block;
  font-size: 1.14285714em;
  text-decoration: none;
  padding: 20px 20px;
  height: 67px;
  line-height: 30px;
}
header .desktop-nav nav ul li a:hover,
header .desktop-nav nav ul li a.active {
  background-color: #26358c;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
header .desktop-nav nav ul li .home-icon {
  font-size: 1.57142857em;
}
header .top-links {
  min-height: 46px;
  line-height: 46px;
  padding: 0 0 0 18px;
  background-color: #06164a;
}
header .top-links .top-links__inner {
  display: flex;
}
header .top-links .top-links__inner a {
  color: #ffffff;
}
header .top-links .left-links {
  flex: 1 0 auto;
  display: inline-block;
}
header .top-links .left-links .menu-btn {
  display: none;
}
header .top-links .left-links ul {
  padding: 0;
  margin: 0;
}
header .top-links .left-links ul li {
  display: inline-block;
  list-style-type: none;
  padding-right: 15px;
}
header .top-links .left-links ul li a {
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}
header .top-links .left-links ul li a:before {
  content: ' ';
  display: block;
  height: 2px;
  width: 0;
  background: #ffffff;
  position: absolute;
  bottom: 10px;
  left: 1px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .left-links ul li a:hover {
  text-decoration: none;
  color: #ffffff;
}
header .top-links .left-links ul li a:hover:before {
  width: 100%;
}
header .top-links .center-area {
  flex: 1 0 auto;
}
header .top-links .center-area .center-area__inner {
  font-size: 1.57142857em;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 991px) {
  header .top-links .center-area .center-area__inner {
    font-size: 1.28571429em;
    margin: 0 5px;
  }
}
header .top-links .center-area .fa-phone {
  font-size: 19px;
  margin-right: 3px;
}
header .top-links .right-links {
  display: inline-block;
  position: relative;
}
header .top-links .right-links .header-numbers {
  position: absolute;
  top: 83px;
  right: 20px;
  text-align: right;
  display: none;
}
header .top-links .right-links .header-numbers a {
  display: inline-block;
  min-height: 0;
  line-height: normal;
  padding: 0;
  margin: 0;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}
header .top-links .right-links .header-numbers a:before {
  content: ' ';
  display: block;
  height: 2px;
  width: 0;
  background: #06164a;
  position: absolute;
  bottom: 0px;
  left: 1px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .header-numbers a.telNo {
  font-size: 2.57142857em;
  line-height: 100%;
}
header .top-links .right-links .header-numbers a.telNo:hover {
  text-decoration: none;
  color: #06164a;
}
header .top-links .right-links .header-numbers a.telNo:hover:before {
  width: 100%;
}
header .top-links .right-links .header-numbers .help {
  margin-top: -15px;
}
header .top-links .right-links .header-numbers .help a {
  display: inline-block;
  background: #ebebeb;
  padding: 3px 6px;
  color: #666666;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .header-numbers .help a span {
  font-weight: bold;
  color: #06164a;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .header-numbers .help a:hover {
  background: #666666;
  color: #fff;
}
header .top-links .right-links .header-numbers .help a:hover span {
  color: #fff;
}
header .top-links .right-links .icon-list {
  display: inline-block;
}
header .top-links .right-links .twitter-typeahead {
  float: left;
  width: 100%;
  background-color: #ffffff;
}
header .top-links .right-links .search-area {
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: #ffffff;
  padding: 0 10px 0 30px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .search-area .drop-search {
  display: none;
  position: absolute;
  top: 46px;
  right: 0;
  width: 300px;
  /*border: 1px solid #fff;*/
  padding: 1px;
  z-index: 10;
}
header .top-links .right-links .search-area .drop-search input[type='search'] {
  width: 100%;
  height: 46px;
  line-height: 20px;
  border: 1px solid #767676;
  margin: 0;
  padding: 10px;
  color: #a0a0a0;
  outline: none;
  font-size: 1.28571429em;
  background: #fff;
}
header .top-links .right-links .search-area .search-icon {
  font-size: 1.28571429em;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .search-area:hover {
  background: #767676;
  top: 0px;
}
header .top-links .right-links .logged-in {
  display: inline-block;
  z-index: 1;
  line-height: 1.5em;
  text-align: right;
}
header .top-links .right-links .logged-in .login-icon {
  margin-right: 10px;
}
header .top-links .right-links .logged-in .links {
  color: #ffffff;
}
header .top-links .right-links .logged-in a {
  color: #ffffff;
}
header .top-links .right-links .login-area {
  cursor: pointer;
  position: relative;
  top: 0;
  display: inline-block;
  background: #06164a;
  color: #ffffff;
  padding: 0 10px 0 30px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .login-area .inner-border {
  border: 1px solid #06164a;
  padding: 20px;
  padding-bottom: 6px;
  background-color: #fff;
  z-index: 15;
}
header .top-links .right-links .login-area .LoginPanelPasswordRetrieval span {
  width: 100%;
  display: block;
  line-height: 1.3em;
  margin-top: 10px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
header .top-links .right-links .login-area .LoginPanelPasswordRetrieval .form-control-error {
  background-color: transparent;
  padding: 0px;
}
header .top-links .right-links .login-area .drop-login {
  display: none;
  width: 272px;
  z-index: 14;
  position: absolute;
  background: #fff;
  right: 0;
  border-top: 1px solid #fff;
  top: 46px;
}
header .top-links .right-links .login-area .drop-login .register-btn {
  display: block;
  position: relative;
  width: 50%;
  float: left;
  text-align: center;
  font-size: 1.42857143em;
  color: #06164a;
  text-decoration: none;
}
header .top-links .right-links .login-area .drop-login .register-btn:before {
  display: block;
  content: ' ';
  width: 63%;
  height: 2px;
  background: #06164a;
  position: absolute;
  z-index: 15;
  bottom: 16px;
  opacity: 0;
  left: 0px;
  right: 0;
  margin: 0 auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .login-area .drop-login .register-btn:hover:before {
  opacity: 1;
  bottom: 5px;
}
header .top-links .right-links .login-area .drop-login .login-btn {
  position: relative;
  width: 50%;
  float: left;
  background: #06164a;
  color: #fff;
  font-size: 1.42857143em;
  padding: 0 15px;
  text-decoration: none;
  display: block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .login-area .drop-login .login-btn:hover {
  background: rgba(6, 22, 74, 0.8);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
header .top-links .right-links .login-area .drop-login .login-btn:before {
  display: block;
  content: ' ';
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat 0px -52px;
  position: absolute;
  top: 13px;
  right: 13px;
  width: 30px;
  height: 19px;
}
header .top-links .right-links .login-area .drop-login .forgot-password {
  color: #06164a;
  font-size: 1.07142857em;
  text-decoration: none;
  text-align: right;
  display: block;
  width: 100%;
}
header .top-links .right-links .login-area .drop-login .forgot-password:hover {
  text-decoration: underline;
}
header .top-links .right-links .login-area .drop-login .input-box {
  position: relative;
}
header .top-links .right-links .login-area .drop-login .input-box.user:before {
  position: absolute;
  top: 0;
  font-size: 1.42857143em;
  right: 14px;
  display: block;
  font-family: 'FontAwesome';
  content: '\f007';
  color: #575757;
  cursor: default;
}
header .top-links .right-links .login-area .drop-login .input-box.password:before {
  position: absolute;
  top: 0;
  font-size: 1.42857143em;
  right: 14px;
  display: block;
  font-family: 'FontAwesome';
  content: '\f023';
  color: #575757;
  cursor: default;
}
header .top-links .right-links .login-area .drop-login .input-box input {
  font-size: 1.07142857em;
  outline: none;
  height: 46px;
  border: 1px solid #06164a;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 44px 0 15px;
}
header .top-links .right-links .login-area .drop-login .alert {
  background: none;
  padding: 0;
  border: none;
  margin: 0;
  position: relative;
  top: -9px;
  color: #c70704;
}
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval .btn,
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval button,
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval input[type='button'],
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval input[type='submit'] {
  display: inline-block;
  position: relative;
  margin: 0;
  border: 0;
  padding: 12px 50px 12px 17px;
  margin-bottom: 15px;
}
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval input[type='submit'] {
  background-position: center right;
}
header .top-links .right-links .login-area .drop-login .LoginPanelPasswordRetrieval span {
  background: none;
  padding: 0;
  border: none;
  margin: 0;
  position: relative;
  top: 7px;
  color: #c70704;
}
header .top-links .right-links .login-area .login-icon {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.07142857em;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .login-area .checkbox input[type=checkbox] {
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin-top: -1px;
}
header .top-links .right-links .login-area .checkbox label {
  color: #06164a;
  vertical-align: middle;
}
header .top-links .right-links .login-area:hover {
  background: rgba(6, 22, 74, 0.8);
}
header .top-links .right-links .tech-area {
  top: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: #ffffff;
  padding: 0 10px 0 42px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .tech-area .fa,
header .top-links .right-links .tech-area a {
  color: #ffffff;
}
header .top-links .right-links .tech-area .tech-icon {
  position: absolute;
  top: 0;
  left: 14px;
  font-size: 1.35714286em;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header .top-links .right-links .tech-area:hover {
  background: rgba(0, 157, 255, 0.8);
}
header .top-links .right-links .ipad-telno {
  display: none;
}
header .top-links .right-links .country-area {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  cursor: pointer;
}
header .top-links .right-links .country-area:hover {
  background-color: #009dff;
}
header .top-links .right-links .country-area .other-coutries {
  display: none;
  width: 65px;
  padding-bottom: 14px;
  position: absolute;
  left: -10px;
  top: 36px;
  z-index: 10;
  background: #06164a;
}
header .top-links .right-links .country-area .other-coutries a {
  display: block;
  color: #ffffff;
  height: 30px;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-left: 10px;
}
header .top-links .right-links .country-area .other-coutries a:hover {
  color: #ffffff;
}
header .top-links .right-links .country-area a {
  padding: 0 5px;
  color: #ffffff;
  margin-left: 0px;
}
header .top-links .right-links .country-area a:hover {
  color: #ffffff;
}
header .top-links .right-links .country-area .country-icon {
  font-size: 1.35714286em;
  padding-right: 1px;
  width: 15px;
  height: 15px;
  background-image: url('/Sitefiles/Images/svg/globe.svg');
  background-size: 15px 15px;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -3px;
}
header .top-links .right-links .country-area .country-icon i {
  display: none;
}
header .top-links .right-links .basket-area {
  display: inline-block;
  margin-right: 20px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  cursor: pointer;
}
header .top-links .right-links .basket-area:hover {
  background-color: #009dff;
}
header .top-links .right-links .basket-area a {
  padding: 0 5px;
  color: #ffffff;
}
header .top-links .right-links .basket-area a:hover {
  color: #ffffff;
}
header .top-links .right-links .basket-area .basket-icon {
  font-size: 1.28571429em;
  padding-right: 6px;
}
header .top-links .right-links .depot-area {
  display: none;
  margin-right: 20px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
header .top-links .right-links .depot-area:hover {
  background-color: #009dff;
}
header .top-links .right-links .depot-area a {
  padding: 0 5px;
  color: #ffffff;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .depot-area a:hover {
  color: #ffffff;
}
header .top-links .right-links .depot-area .depot-icon {
  font-size: 1.28571429em;
  padding-right: 1px;
}
header .top-links .right-links .mail-area {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .top-links .right-links .mail-area:hover {
  background-color: #009dff;
}
header .top-links .right-links .mail-area a {
  padding: 0 5px;
  color: #ffffff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
header .top-links .right-links .mail-area a:hover {
  color: #ffffff;
}
header .top-links .right-links .mail-area .mail-icon {
  font-size: 1em;
  padding-right: 6px;
}
header .no-overflow {
  overflow: hidden;
}
header .logo {
  margin: 32px 0 28px 18px;
}
.IE8 header .desktop-nav nav ul li {
  float: left;
}
.IE8 header .desktop-nav nav ul li ul li {
  float: none;
}
hr,
.grey-bar {
  display: block;
  position: relative;
  height: 8px;
  width: 0px;
  -moz-transition: width 2.5s ease-in-out;
  -o-transition: width 2.5s ease-in-out;
  -webkit-transition: width 2.5s ease-in-out;
  transition: width 2.5s ease-in-out;
  background: #dfdfdf;
}
hr.animate,
.grey-bar.animate {
  width: 100%;
}
hr {
  width: 100% !important;
}
@media (max-width: 1199px) {
  header .desktop-nav nav ul li a {
    padding: 18px 7px;
  }
  header .desktop-nav nav ul li .drop {
    padding: 45px 60px;
  }
}
@media (max-width: 991px) {
  header.show-number .top-links .right-links .header-numbers {
    display: none;
  }
  header .top-links .right-links .logged-in {
    top: 90px;
  }
  .IE8 header .desktop-nav nav ul li {
    float: none;
  }
  .grey-bar {
    width: 100%;
  }
  header {
    position: relative;
    top: 0;
    opacity: 1;
  }
  header .top-links .right-links .login-area,
  header .top-links .right-links .ipad-search {
    opacity: 1;
  }
  header .top-links .right-links div span {
    opacity: 1;
  }
  header .top-links .left-links .menu-btn {
    opacity: 1;
  }
  header .desktop-nav {
    display: none;
    padding: 0;
    background: #f2f2f2;
    width: 35%;
  }
  header .desktop-nav .container {
    width: 100%;
    padding: 0;
  }
  header .desktop-nav nav ul li {
    display: block;
    margin: 0;
  }
  header .desktop-nav nav ul li.drop-open a {
    background: #06164a;
    color: #fff;
  }
  header .desktop-nav nav ul li.drop-open ul li a {
    font-size: 1.35714286em;
    background: none;
    color: #575757;
  }
  header .desktop-nav nav ul li a {
    padding-left: 20px;
    color: #06164a;
    font-size: 1.42857143em;
  }
  header .desktop-nav nav ul li a.active {
    color: #fff;
  }
  header .desktop-nav nav ul li a:hover {
    background: #06164a;
    color: #fff;
  }
  header .desktop-nav nav ul li .drop {
    border: none;
    position: absolute;
    top: 0;
    padding: 25px;
    left: 100%;
  }
  header .desktop-nav nav ul li .drop p {
    font-size: 1.07142857em;
  }
  header .desktop-nav nav ul li .drop h3 {
    margin: 15px 0;
    font-size: 1.42857143em;
    color: #06164a;
  }
  header .top-links {
    background: #06164a;
    height: 70px;
    line-height: 70px;
    padding-left: 0;
  }
  header .top-links .left-links ul {
    display: none;
  }
  header .top-links .left-links .menu-btn {
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    margin-top: 12px;
    display: block;
    width: 138px;
    height: 46px;
    background: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
  }
  header .top-links .left-links .menu-btn:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    background: #ff7600;
    -moz-transition: width 0.6s ease-in-out;
    -o-transition: width 0.6s ease-in-out;
    -webkit-transition: width 0.6s ease-in-out;
    transition: width 0.6s ease-in-out;
  }
  header .top-links .left-links .menu-btn .menu-icon {
    position: absolute;
    z-index: 11;
    top: 3px;
    left: 3px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    height: 40px;
    width: 50px;
    background: #06164a;
    display: block;
    padding: 4px 9px;
    -moz-transition: left 0.6s ease-in-out;
    -o-transition: left 0.6s ease-in-out;
    -webkit-transition: left 0.6s ease-in-out;
    transition: left 0.6s ease-in-out;
  }
  header .top-links .left-links .menu-btn .menu-icon .line {
    width: 100%;
    height: 4px;
    background: #fff;
    display: block;
    margin: 3px 0;
  }
  header .top-links .left-links .menu-btn .menu-icon .line.one {
    top: 0;
    position: relative;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  header .top-links .left-links .menu-btn .menu-icon .line.two {
    opacity: 1;
    top: 0;
    position: relative;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  header .top-links .left-links .menu-btn .menu-icon .line.three {
    opacity: 1;
    top: 0;
    position: relative;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  header .top-links .left-links .menu-btn .menu-icon .line.four {
    position: relative;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  header .top-links .left-links .menu-btn .closed {
    position: absolute;
    height: 46px;
    line-height: normal;
    top: 9px;
    right: 16px;
    font-size: 1.57142857em;
    color: #999999;
    -moz-transition: right 0.6s ease-in-out;
    -o-transition: right 0.6s ease-in-out;
    -webkit-transition: right 0.6s ease-in-out;
    transition: right 0.6s ease-in-out;
  }
  header .top-links .left-links .menu-btn .opened {
    position: absolute;
    height: 46px;
    line-height: normal;
    top: 9px;
    left: -63px;
    font-size: 1.57142857em;
    color: #fff;
    -moz-transition: left 0.6s ease-in-out;
    -o-transition: left 0.6s ease-in-out;
    -webkit-transition: left 0.6s ease-in-out;
    transition: left 0.6s ease-in-out;
  }
  header .top-links .left-links .menu-btn.menu-open:before {
    width: 100%;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon {
    left: 85px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.one {
    -moz-transform: translateY(-10px) rotate(45deg);
    -ms-transform: translateY(-10px) rotate(45deg);
    -o-transform: translateY(-10px) rotate(45deg);
    -webkit-transform: translateY(-10px) rotate(45deg);
    transform: translateY(-10px) rotate(45deg);
    top: 21px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.two {
    opacity: 0;
    -moz-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
    top: 14px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.three {
    opacity: 0;
    -moz-transform: translateY(-10px) rotate(45deg);
    -ms-transform: translateY(-10px) rotate(45deg);
    -o-transform: translateY(-10px) rotate(45deg);
    -webkit-transform: translateY(-10px) rotate(45deg);
    transform: translateY(-10px) rotate(45deg);
    top: 8px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.four {
    -moz-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  header .top-links .left-links .menu-btn.menu-open .closed {
    right: -65px;
  }
  header .top-links .left-links .menu-btn.menu-open .opened {
    left: 15px;
  }
  header .top-links .right-links {
    height: 70px;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .icon-list {
    height: 70px;
    display: flex;
    margin: 0 5px;
  }
  header .top-links .right-links .icon-list a {
    text-decoration: none;
    color: #fff;
  }
  header .top-links .right-links .icon-list a:hover {
    color: #fff;
  }
  header .top-links .right-links div span {
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    text-align: center;
  }
  header .top-links .right-links .ipad-search {
    height: 100%;
    position: relative;
    float: left;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    color: #fff;
    border-left: 2px solid #ff7600;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  header .top-links .right-links .ipad-search:hover {
    background: #ff7600;
  }
  header .top-links .right-links .ipad-search.active {
    background: #ff7600;
  }
  header .top-links .right-links .ipad-search .search-icon {
    padding: 0 14px;
    color: #fff;
    display: block;
    font-size: 1.42857143em;
  }
  header .top-links .right-links .ipad-search .drop-search {
    display: none;
    position: absolute;
    top: 69px;
    height: 68px;
    font-size: 1.21428571em;
    left: 0;
    padding: 10px;
    background: #ff7600;
    line-height: normal;
  }
  header .top-links .right-links .ipad-search .drop-search input[type='search'] {
    outline: none;
    padding: 10px;
    height: 48px;
    border: none;
  }
  header .top-links .right-links .search-area {
    display: none;
  }
  header .top-links .right-links .tech-area {
    display: none;
    color: #fff;
    height: 100%;
  }
  header .top-links .right-links .tech-area .tech-icon {
    color: #fff;
    font-size: 2.57142857em;
  }
  header .top-links .right-links .login-area {
    -moz-transition: opacity 1.2s ease-in-out;
    -o-transition: opacity 1.2s ease-in-out;
    -webkit-transition: opacity 1.2s ease-in-out;
    transition: opacity 1.2s ease-in-out;
    color: #ffffff;
    padding: 0;
    height: 100%;
    font-size: 1em;
  }
  header .top-links .right-links .login-area .login-click {
    padding: 0 16px 0 48px;
  }
  header .top-links .right-links .login-area:hover {
    background: #ff7600;
  }
  header .top-links .right-links .login-area:hover .login-icon {
    font-size: 1.28571429em;
  }
  header .top-links .right-links .login-area .login-icon {
    color: #ffffff;
    font-size: 1.28571429em;
  }
  header .top-links .right-links .country-area {
    color: #fff !important;
    border-left: 2px solid #ff7600;
    border-right: 2px solid #ff7600;
    height: 100%;
    padding: 0 15px 0 6px;
    margin: 0;
    font-size: 1em;
  }
  header .top-links .right-links .country-area .other-coutries {
    top: 70px;
    width: 100%;
    background: #ff7600;
    z-index: 21;
  }
  header .top-links .right-links .country-area .other-coutries a {
    height: 59px;
    padding-left: 8px;
  }
  header .top-links .right-links .country-area:hover {
    background: #ff7600;
  }
  header .top-links .right-links .country-area .country-icon {
    padding-right: 8px;
    color: #fff;
    font-size: 1.07142857em;
  }
  header .top-links .right-links .country-area .country {
    position: relative;
  }
  header .top-links .right-links .basket-area {
    color: #fff !important;
    border-left: 2px solid #ff7600;
    height: 100%;
    padding: 0 10px;
    margin: 0;
    font-size: 1em;
  }
  header .top-links .right-links .basket-area:hover {
    background: #ff7600;
  }
  header .top-links .right-links .basket-area .basket-icon {
    color: #fff;
    padding-right: 8px;
    font-size: 22px;
  }
  header .top-links .right-links .basket-area .number {
    position: relative;
  }
  header .top-links .right-links .depot-area {
    color: #fff;
    border-left: 2px solid #ff7600;
    height: 100%;
    padding: 0 20px;
    margin: 0;
  }
  header .top-links .right-links .depot-area:hover {
    background: #ff7600;
  }
  header .top-links .right-links .depot-area .depot-icon {
    padding-right: 0px;
    color: #fff;
    font-size: 1.28571429em;
  }
  header .top-links .right-links .mail-area {
    color: #fff;
    border-left: 2px solid #ff7600;
    height: 100%;
    padding: 0 10px;
    margin: 0;
  }
  header .top-links .right-links .mail-area:hover {
    background: #ff7600;
  }
  header .top-links .right-links .mail-area .mail-icon {
    color: #fff;
    font-size: 1.42857143em;
    padding-right: 0;
  }
  header .top-links .right-links .links {
    font-size: 1em;
  }
  .HomePage header .top-links .right-links .login-area,
  .HomePage header .top-links .right-links .ipad-search {
    opacity: 0;
  }
  .HomePage header .top-links .right-links div span {
    opacity: 0;
  }
  .HomePage header .top-links .left-links .menu-btn {
    opacity: 0;
  }
  .HomePage header.fade-in .top-links .right-links .login-area,
  .HomePage header.fade-in .top-links .right-links .ipad-search {
    opacity: 1;
  }
  .HomePage header.fade-in .top-links .right-links div span {
    opacity: 1;
  }
  .HomePage header.fade-in .top-links .left-links .menu-btn {
    opacity: 1;
  }
  header .top-links .right-links .login-area {
    -moz-transition: background 0.4s ease-in-out;
    -o-transition: background 0.4s ease-in-out;
    -webkit-transition: background 0.4s ease-in-out;
    transition: background 0.4s ease-in-out;
  }
  header .top-links .right-links .login-area.active {
    background: #ff7600;
  }
  header .top-links .right-links .login-area .drop-login {
    line-height: normal;
    font-size: 14px;
    top: 70px;
  }
  header .top-links .right-links .login-area .drop-login .login-btn {
    padding: 8px 15px;
  }
  header .top-links .right-links .login-area .drop-login .register-btn {
    padding: 8px 15px;
  }
  header .top-links .right-links .login-area .drop-login .input-box:before {
    top: 12px !important;
  }
  header .top-links .right-links .login-area .drop-login .forgot-password {
    margin-top: 10px;
  }
  header .desktop-nav nav ul li .drop .dropdown-logo {
    margin-top: 0px;
  }
}
@media (max-width: 850px) {
  header .top-links .right-links .ipad-search,
  header .top-links .right-links .depot-area,
  header .top-links .right-links .basket-area,
  header .top-links .right-links .country-area {
    padding: 0 10px;
  }
  header .top-links .right-links .mail-area {
    padding: 0 0px 0 10px;
  }
  header .top-links .right-links .country-area .other-coutries a {
    height: 50px;
    padding-left: 7px;
  }
  header .top-links .right-links .ipad-telno {
    padding: 0 16px;
  }
  header .top-links .right-links .ipad-search .search-icon {
    padding: 0 6px;
  }
}
@media (max-width: 767px) {
  header .top-links .right-links .ipad-telno {
    border: none;
    padding: 0 16px;
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
  header .top-links .right-links .logged-in {
    top: 0px;
    padding: 18px 0px;
    position: relative;
    font-size: 1.14285714em;
  }
  .back-to-desktop {
    display: none;
  }
  .grey-bar {
    display: none !important;
  }
  header {
    position: static;
  }
  header .logo {
    position: absolute;
    margin: 12px 0 13px 18px;
    top: 0px;
    left: 108px;
    padding-right: 25px;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  header .moved-nav {
    position: absolute;
    background: #f2f2f2;
    height: 100%;
    z-index: 3000;
    top: 0;
    left: -350px;
  }
  header .desktop-nav {
    width: 350px;
    display: block !important;
  }
  header .desktop-nav nav ul li .drop {
    border: none;
    position: relative;
    top: 0;
    width: 100% !important;
    padding: 15px 5px;
    left: 0;
  }
  header .desktop-nav nav ul li .drop h3,
  header .desktop-nav nav ul li .drop p {
    display: none;
  }
  header .top-links {
    margin-top: 100px;
    padding: 0;
    line-height: normal;
  }
  header .top-links .left-links .menu-btn {
    opacity: 1;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: #dadada;
    border-radius: 0;
    height: 100px;
    width: 100px;
  }
  header .top-links .left-links .menu-btn.menu-open:before {
    display: none;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon {
    left: 0;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.one {
    top: 27px;
    left: 18px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.two {
    top: 14px;
    left: 16px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.three {
    top: 3px;
    left: 18px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.four {
    left: 17px;
    top: -9px;
  }
  header .top-links .left-links .menu-btn.menu-open .closed,
  header .top-links .left-links .menu-btn.menu-open .opened {
    display: none;
    right: 0;
    left: 0;
  }
  header .top-links .left-links .menu-btn .closed,
  header .top-links .left-links .menu-btn .opened {
    display: none;
    right: 0;
    left: 0;
  }
  header .top-links .left-links .menu-btn .menu-icon {
    top: 20px;
    left: 17px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 50px;
    width: 61px;
    background: none;
    padding: 4px 0px;
  }
  header .top-links .left-links .menu-btn .menu-icon .line {
    width: 100%;
    height: 8px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    display: block;
    margin: 4px 0;
  }
  header .top-links .right-links {
    height: 70px;
    width: 100%;
    float: none;
    display: flex;
  }
  header .top-links .right-links .icon-list {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  header .top-links .right-links .login-area {
    border: none;
    float: none;
    display: inline-block;
    vertical-align: middle;
    z-index: 9000;
  }
  header .top-links .right-links .login-area .login-click {
    padding: 0 16px;
  }
  header .top-links .right-links .login-area .login-icon {
    position: relative;
    top: 0;
    left: 0;
  }
  header .top-links .right-links .country-area {
    border: none;
    padding: 0 16px;
    float: none;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .country-area .other-coutries {
    top: 70px;
    width: 100%;
    background: #ff7600;
    z-index: 21;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .top-links .right-links .country-area .other-coutries a {
    height: 53px;
    padding-left: 0;
    margin: 0;
  }
  header .top-links .right-links .basket-area {
    border: none;
    padding: 0 16px;
    float: none;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .depot-area {
    border: none;
    padding: 0 16px;
    float: none;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .mail-area {
    border: none;
    float: none;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .tech-area {
    border: none;
    padding: 0 16px;
    float: none;
  }
  header .top-links .right-links .ipad-search {
    border: none;
    padding: 0 16px;
    float: none;
    display: flex;
    align-items: center;
  }
  header .top-links .right-links .ipad-search .search-icon {
    padding: 0;
  }
  header .top-links .right-links .ipad-search .drop-search {
    z-index: 9000;
  }
  header .top-links .right-links .search-area {
    border: none;
    padding: 0 16px;
    display: none !important;
    float: none;
  }
  header .top-links .right-links .login-area .drop-login .login-btn {
    text-align: left;
  }
}
@media (max-width: 570px) {
  header .top-links .right-links .basket-area {
    padding: 0 8px;
  }
  header .top-links .right-links .country-area {
    padding: 0 8px;
  }
}
@media (max-width: 535px) {
  header .top-links .right-links .logged-in {
    width: auto;
    padding-left: 0px;
    padding-right: 3px;
    right: 0px;
    float: right;
  }
  header .top-links .right-links .logged-in .links {
    float: right;
    clear: both;
  }
  header .logo {
    position: absolute;
    margin: 15px 0 24px 18px;
    top: 0px;
    left: 108px;
    width: 60%;
    padding-right: 25px;
  }
  header .top-links .right-links .ipad-telno {
    padding: 0 10px;
    display: none;
  }
  header .top-links .right-links .ipad-telno .tel-icon {
    font-size: 1.85714286em;
  }
  header .top-links .right-links .ipad-search {
    padding: 0 10px 0 5px;
  }
  header .top-links .right-links .ipad-search .search-icon {
    font-size: 1.85714286em;
  }
  header .top-links .right-links .depot-area {
    padding: 0 10px;
  }
  header .top-links .right-links .depot-area .depot-icon {
    font-size: 1.28571429em;
  }
  header .top-links .right-links .basket-area {
    padding: 0 5px;
  }
  header .top-links .right-links .basket-area .basket-icon {
    font-size: 1.42857143em;
  }
  header .top-links .right-links .basket-area .number {
    font-size: 0.57142857em;
    top: -5px;
  }
  header .top-links .right-links .country-area {
    padding: 0 5px;
  }
  header .top-links .right-links .country-area .country-icon {
    font-size: 1.42857143em;
  }
  header .top-links .right-links .country-area .country {
    font-size: 0.57142857em;
    position: relative;
    top: -5px;
  }
  header .top-links .right-links .mail-area {
    padding: 0 10px;
  }
  header .top-links .right-links .mail-area .mail-icon {
    font-size: 1.85714286em;
  }
  header .top-links .right-links .tech-area {
    padding: 0 5px;
  }
  header .top-links .right-links .tech-area .tech-icon {
    font-size: 1.42857143em;
  }
  header .top-links .right-links .login-area {
    padding: 0;
  }
  header .top-links .right-links .login-area .login-click {
    padding: 0 16px;
  }
  header .top-links .right-links .login-area:hover .login-icon {
    font-size: 1.42857143em;
  }
  header .top-links .right-links .login-area .login-icon {
    font-size: 1.42857143em;
    top: 17px !important;
  }
  header .top-links .right-links .search-area {
    padding: 0 5px;
  }
  header .top-links .right-links .search-area .search-icon {
    font-size: 1.42857143em;
  }
}
@media (max-width: 450px) {
  header .moved-nav {
    left: -244px;
  }
  header .desktop-nav {
    width: 244px;
  }
}
@media (max-width: 400px) {
  header .top-links {
    margin-top: 80px;
  }
  header .top-links .left-links .menu-btn {
    height: 80px;
    width: 80px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon {
    left: -7px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.one {
    top: 26px;
    left: 20px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.two {
    top: 14px;
    left: 20px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.three {
    top: 6px;
    left: 19px;
  }
  header .top-links .left-links .menu-btn.menu-open .menu-icon .line.four {
    left: 19px;
    top: -5px;
  }
  header .top-links .left-links .menu-btn .right-links .country-area {
    padding: 0 5px;
  }
  header .top-links .left-links .menu-btn .right-links .country-area .country-icon {
    font-size: 1.42857143em;
  }
  header .top-links .left-links .menu-btn .right-links .country-area .country {
    font-size: 0.57142857em;
    position: relative;
    top: -5px;
  }
  header .top-links .left-links .menu-btn .menu-icon {
    top: 14px;
    left: 13px;
    height: 50px;
    width: 50px;
  }
  header .top-links .left-links .menu-btn .menu-icon .line {
    height: 6px;
  }
  header .logo {
    margin: 0;
    top: 10px;
    left: 94px;
    width: 50%;
    padding-right: 0;
  }
}
@media (max-width: 315px) {
  header .top-links .right-links .country-area {
    padding: 0 5px;
  }
  header .top-links .right-links .country-area .country-icon {
    font-size: 1.42857143em;
  }
  header .top-links .right-links .country-area .country {
    font-size: 0.57142857em;
    position: relative;
    top: -12px;
  }
}
.right-links .fa {
  color: #ffffff;
}
@media (max-width: 992px) {
  .right-links .fa {
    color: #ffffff;
  }
}
.right-links .login-area .fa,
.right-links .search-icon .fa {
  color: #ffffff;
}
.header__mob-number {
  display: block;
  padding: 20px;
  font-size: 20px;
  color: #06164a;
}
.header__mob-number:hover {
  text-decoration: none;
}
.sub-nav {
  background-color: #26358c;
}
.sub-nav--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
  width: 100%;
}
.sub-nav__inner {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 767px) {
  .sub-nav__inner {
    flex-direction: column;
    display: none;
  }
}
@media (max-width: 767px) {
  .sub-nav__inner.is-active {
    display: flex;
  }
}
.sub-nav__inner li {
  display: inline-block;
}
@media (max-width: 767px) {
  .sub-nav__inner li {
    display: block;
    width: 100%;
    text-align: center;
  }
}
.sub-nav__inner a {
  font-size: 1.14285714em;
  transition: border 0.5s;
  padding: 18px 15px 15px;
  border-bottom: 3px solid #26358c;
  color: #ffffff;
  display: inline-block;
}
.sub-nav__inner a:hover,
.sub-nav__inner a.is-active {
  border-bottom: 3px solid #009dff;
  text-decoration: none;
}
.sub-nav__mob-btn {
  display: none;
  cursor: pointer;
}
@media (max-width: 767px) {
  .sub-nav__mob-btn {
    font-size: 1.14285714em;
    display: block;
    text-align: center;
    padding: 18px 15px;
    background-color: #26358c;
    width: 100%;
  }
}
.sub-nav__mob-btn:active,
.sub-nav__mob-btn:focus {
  outline: none;
  background-color: #26358c;
}
@media (max-width: 767px) {
  .sub-nav__mob-btn:hover {
    text-decoration: none;
    background-color: #ff7600;
  }
}
@media (max-width: 767px) {
  .sub-nav__mob-btn.is-active {
    background-color: #ff7600;
  }
}
.HomePage header {
  -moz-transition-delay: 0s;
  -o-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.HomePage header .grey-bar {
  -moz-transition-delay: 0.9s;
  -o-transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.animate-banner {
  opacity: 0!important;
  position: relative;
  top: 0;
  left: 100%;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.banner-area {
  overflow: hidden;
  opacity: 0;
}
.banner-area .owl-buttons .owl-prev,
.banner-area .owl-buttons .owl-next {
  position: absolute;
  z-index: 8000;
  top: 50%;
  margin-top: -13px !important;
  opacity: 1 !important;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  background: url(app/partials/~@imgOld/tpa-site/svg/icon-arrow-circle.svg) no-repeat !important;
  transform: translateY(-50%);
}
.banner-area .owl-buttons .owl-prev {
  left: 30px;
}
.banner-area .owl-buttons .owl-next {
  right: 30px;
  transform: rotate(-180deg);
}
.banner-area .item {
  position: relative;
}
.banner-area .item .banner-overlay {
  position: absolute;
  z-index: 900;
  bottom: 200px;
  left: 0px;
  right: 0px;
}
@media (min-width: 1200px) {
  .banner-area .item .banner-overlay .container {
    padding-left: 0;
  }
}
.banner-area .item .banner-overlay .banner-title {
  display: inline-block;
  padding: 20px;
  font-size: 2.57142857em;
  color: #ffffff;
  background: #06164a;
  position: relative;
  z-index: 1;
}
.banner-area .item .banner-overlay .banner-title a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .banner-area .item .banner-overlay .banner-title a {
    color: #009dff;
  }
}
.banner-area .item .banner-overlay .banner-title a:hover {
  color: #ffffff;
}
@media (max-width: 767px) {
  .banner-area .item .banner-overlay .banner-title a:hover {
    color: #29adff;
  }
}
.banner-area .item .banner-overlay .banner-text {
  margin: -27px 0px 0px 34px;
  background-color: #ffffff;
  width: 396px;
  display: block;
  padding: 40px 20px 30px 20px;
  font-size: 1.28571429em;
}
.banner-area .item .banner-overlay .banner-text a {
  color: #4d4d4d;
  text-decoration: none;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.animate-banner.slide-in {
  opacity: 1!important;
  left: 0;
}
.animate-banner.slide-in .banner-area {
  opacity: 1!important;
}
.homepage-signposts {
  position: relative;
  top: -36px;
  margin-bottom: 10px;
}
.homepage-signposts .signpost-item {
  opacity: 0;
  width: 25%;
  float: left;
  padding: 0 15px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget {
  min-height: 95px;
  /*a:hover {
                .signpost .signpost-icon img {
                    left: 5px;
                    width: 54px;
                }
            }*/
}
.homepage-signposts .signpost-item .widget.sign-post-widget a {
  padding: 18px 20px;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost {
  display: flex;
  align-items: center;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
  display: block;
  text-align: center;
  padding: 0;
}
.homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
  font-size: 1.64285714em;
  line-height: 26px;
  display: block;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    padding-right: 0;
  }
}
.load-home-content {
  opacity: 0;
  -moz-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  -webkit-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
}
.load-home-content.fade-in {
  opacity: 1!important;
}
.homepage-strip {
  text-align: center;
  padding: 0 0 50px;
}
.homepage-strip p {
  font-size: 1.57142857em;
  line-height: 36px;
}
@media (max-width: 767px) {
  .homepage-strip p {
    font-size: 1em;
    line-height: 18px;
  }
}
.homepage-strip h1 {
  color: #06164a;
}
@media (max-width: 767px) {
  .homepage-strip h1 {
    font-size: 1.78571429em;
  }
}
.home-widget-wrap {
  margin: 60px 0;
}
@media (max-width: 1590px) {
  .banner-area .item .banner-overlay .banner-text {
    width: 550px;
  }
  .banner-area .item .banner-overlay {
    bottom: 120px;
  }
}
@media (max-width: 1390px) {
  .banner-area .item .banner-overlay {
    bottom: 75px;
    padding-left: 80px;
  }
  .banner-area .owl-buttons .owl-prev {
    left: 2px;
  }
  .banner-area .owl-buttons .owl-next {
    right: 2px;
  }
}
@media (max-width: 1270px) {
  .banner-area .item .banner-overlay {
    bottom: 65px;
    padding-left: 80px;
  }
  .banner-area .item .banner-overlay .banner-text {
    width: 780px;
  }
}
@media (max-width: 1199px) {
  .banner-area .item .banner-overlay {
    bottom: 100px;
    padding-left: 80px;
  }
  .banner-area .item .banner-overlay .banner-text {
    width: 840px;
    padding: 40px 20px 12px 20px;
  }
  .homepage-signposts {
    position: relative;
    top: -25px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget {
    /*a:hover {
                .signpost .signpost-icon img {
                    left: 3px;
                    width: 43px;
                }
            }*/
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
    width: 56px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
    left: 4px;
    width: 39px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.35714286em;
    line-height: 25px;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.35714286em;
    line-height: 25px;
  }
}
@media (max-width: 1040px) {
  .banner-area .item .banner-overlay {
    bottom: 26px;
    padding-left: 44px;
  }
}
@media (max-width: 991px) {
  .banner-area .item .banner-overlay {
    bottom: auto;
    top: 48px;
    padding-left: 0;
    padding-right: 10%;
  }
  .banner-area .item .banner-overlay .banner-text {
    width: 100%;
  }
  .homepage-signposts {
    top: 8px;
  }
  .homepage-signposts .signpost-item {
    width: 25%;
    float: left;
    padding: 0 3px;
  }
  .homepage-signposts .signpost-item:last-child {
    display: none;
  }
}
@media (max-width: 767px) {
  .homepage-signposts .signpost-item {
    width: 100%;
  }
  .banner-area .item .banner-overlay .banner-title {
    display: inline-block;
    padding: 14px 0;
    font-size: 1.85714286em;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.71428571em;
    line-height: 31px;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.71428571em;
    line-height: 25px;
  }
  .banner-area .item .banner-overlay .banner-text {
    padding: 0;
    margin: 0;
  }
  .banner-area .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
  }
  .banner-area .owl-buttons .owl-prev,
  .banner-area .owl-buttons .owl-next {
    top: 40%;
  }
}
@media (max-width: 550px) {
  .home-widget-wrap {
    margin: 25px 0 5px;
  }
  .banner-area .owl-buttons .owl-prev,
  .banner-area .owl-buttons .owl-next {
    top: 20%;
  }
  .banner-area .item .banner-overlay {
    top: 5px;
    left: 0;
    position: relative;
    padding-right: 0;
  }
  .banner-area .item .banner-overlay .container {
    padding: 0;
  }
  .banner-area .item .banner-overlay .banner-title {
    display: inline-block;
    padding: 9px;
    padding-bottom: 0;
    font-size: 20px;
    color: #009dff;
    background: #fff;
  }
  .banner-area .item .banner-overlay .banner-text {
    width: 100%;
    margin: 0;
    padding: 10px 10px;
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.21428571em;
    line-height: 22px;
  }
  .widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
    padding: 1px 2px!important;
  }
  .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
    font-size: 1.21428571em;
  }
  .widget.sign-post-widget .signpost .postcode-select {
    padding: 0 7px!important;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
    width: 48px;
  }
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
    left: -2px;
  }
  /*.homepage-signposts .signpost-item .widget.sign-post-widget a:hover .signpost .signpost-icon img {
        left: -3px;
    }*/
  .homepage-signposts {
    top: -2px;
  }
  .homepage-signposts .widget {
    margin-bottom: 7px;
  }
  .homepage-signposts .chosen-container .chosen-results li {
    height: 42px;
    line-height: 16px;
    padding-left: 12px;
    padding-top: 13px;
    padding-right: 9px;
  }
}
@media (max-width: 335px) {
  .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
    font-size: 1.21428571em;
    line-height: 22px;
  }
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group.required label::after {
  content: "\0000a0*";
  display: inline-block;
  color: #a94442;
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group .error-control {
  border: 1px solid #a94442;
  background: #ce8483;
}
.ko-form .ko-form-outer .ko-form-inner .ko-form-category .form-group .error-message {
  display: block;
  padding: 8px;
  color: #a94442;
}
.media-asset--video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}
.media-asset--video iframe {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.article-title {
  font-size: 25px;
}
.media-hub {
  position: relative;
  padding-bottom: 157px;
}
.media-hub .owl-carousel .owl-wrapper-outer.autoHeight {
  height: auto!important;
}
.media-hub .row {
  margin-right: -10px;
  margin-left: -10px;
}
.media-hub .row .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}
.media-hub .owl-theme .owl-controls {
  margin: 0;
}
.media-hub .owl-theme .owl-controls .owl-buttons div {
  position: absolute;
  top: 50%;
  left: -98px;
  margin: -38px 0 0 0;
  width: 77px;
  height: 77px;
  padding: 0;
  background: none;
  opacity: 1;
  border-radius: 0;
  z-index: 10;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .owl-nav-prev,
.media-hub .owl-theme .owl-controls .owl-buttons div .owl-nav-next {
  background: url('/SiteFiles/Images/tpa-site/svg/icon-arrow-circle.svg') no-repeat;
  display: block;
  width: 77px;
  height: 77px;
  z-index: 2;
  position: relative;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span {
  width: 0;
  position: absolute;
  top: 50%;
  left: 68px;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  text-align: left;
  display: block;
  padding: 5px 0;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-prev {
  right: auto;
  left: 70px;
  text-align: left;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-prev .inner-span {
  display: inline-block;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-next {
  right: 0;
  left: 70px;
  text-align: left;
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span.text-next .inner-span {
  padding: 5px 20px 5px 10px;
  display: inline-block;
  transform: rotateZ(180deg);
}
.media-hub .owl-theme .owl-controls .owl-buttons div .text-span .inner-span {
  background: #ff7600;
  padding: 5px 10px 5px 20px;
  color: #fff;
  font-size: 1.42857143em;
  text-transform: uppercase;
  line-height: normal;
  white-space: nowrap;
}
.media-hub .owl-theme .owl-controls .owl-buttons div:hover .text-span {
  width: 200px;
}
.media-hub .owl-theme .owl-controls .owl-buttons div.owl-prev,
.media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
  text-indent: 0;
}
.media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
  left: auto;
  right: -98px;
}
.media-hub .case-box {
  border: 1px solid #dfdfdf;
  padding: 15px;
  margin-bottom: 15px;
}
.media-hub .case-box:last-child {
  margin: 0;
}
.media-hub .case-box .case-top {
  position: relative;
  padding-right: 90px;
}
.media-hub .case-box .case-top.twit-icon {
  padding-right: 0;
}
.media-hub .case-box .case-top.twit-icon a {
  display: none;
}
.media-hub .case-box .case-top.twit-icon h5 {
  padding-left: 60px;
  position: relative;
}
.media-hub .case-box .case-top.twit-icon h5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat -41px 0;
  width: 30px;
  height: 38px;
}
.media-hub .case-box .case-top.youtube-icon {
  padding-right: 0;
}
.media-hub .case-box .case-top.youtube-icon h5 {
  padding-left: 60px;
  position: relative;
}
.media-hub .case-box .case-top.youtube-icon h5:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat 0px 0;
  width: 30px;
  height: 38px;
}
.media-hub .case-box .case-top h5 {
  display: inline-block;
  background: #ff7600;
  padding: 7px 15px;
  margin-left: -15px;
  color: #fff;
}
.media-hub .case-box .case-top a {
  position: absolute;
  top: 0;
  right: 0;
  color: #ff7600;
}
.media-hub .case-box.brand-orange .case-top h5 {
  background: #ff7600;
}
.media-hub .case-box.brand-orange .case-top a {
  color: #ff7600;
}
.media-hub .case-box.brand-orange .case-bottom .case-text h6 {
  color: #ff7600;
}
.media-hub .case-box.brand-orange .case-bottom .case-text a {
  color: #ff7600;
}
.media-hub .case-box.brand-green .case-top h5 {
  background: #06164a;
}
.media-hub .case-box.brand-green .case-top a {
  color: #06164a;
}
.media-hub .case-box.brand-green .case-bottom .case-text h6 {
  color: #06164a;
}
.media-hub .case-box.big-box {
  margin-bottom: 0;
}
.media-hub .case-box.big-box .case-bottom .case-image {
  /*width: 65%;*/
  width: 100%;
  padding-right: 0px;
}
.media-hub .case-box.big-box .case-bottom .case-text {
  width: 35%;
  width: 100%;
  margin-top: 20px;
}
.media-hub .case-box .case-bottom .case-image {
  float: left;
  width: 40%;
  padding-right: 20px;
}
.media-hub .case-box .case-bottom .case-text {
  float: left;
  width: 60%;
}
.media-hub .case-box .case-bottom .case-text h6 {
  color: #ff7600;
  margin: 0 0 5px 0;
}
.media-hub .media-view {
  margin: 0 auto;
  width: 74%;
  position: absolute;
  bottom: 50px;
  left: 13%;
}
.media-hub .media-view h5 {
  margin: 0 0 15px;
}
.media-hub .media-view .grey-bar {
  margin: 0 0 15px;
}
.media-hub .media-view .grey-bar:before {
  display: none;
}
.media-hub .media-view .grey-bar .green-block {
  position: absolute;
  top: 0;
  right: auto;
  left: -9px;
  content: ' ';
  display: block;
  height: 8px;
  width: 16px;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  background: #06164a;
}
.media-hub .media-view span {
  cursor: pointer;
}
.media-hub .media-view span:hover {
  color: #06164a;
}
.media-hub .media-view .synced span {
  color: #06164a;
}
.IE8 .media-hub .owl-theme .owl-controls .owl-buttons div .text-span {
  display: none!important;
}
.article-wrap {
  position: relative;
  margin: 15px 0;
}
.article-wrap .media-filter {
  width: 518px;
  padding: 9px;
  background: #ff7600;
  position: absolute;
  right: 0;
  top: -74px;
}
.article-wrap .media-filter .filter-inner {
  width: 100%;
  display: block;
}
.article-wrap .media-filter label {
  display: inline-block;
  color: #fff;
  font-size: 1.28571429em;
  font-weight: normal;
  margin-right: 8px;
}
.article-wrap .media-filter select {
  width: 165px!important;
  margin-bottom: 0;
}
.article-wrap .media-filter .filter {
  display: inline-block;
  width: 200px;
  margin-right: 22px;
}
.article-wrap .media-filter .filter.no-marg {
  margin-right: 0;
}
.article-wrap .media-filter .chosen-container {
  height: 32px;
  margin-bottom: 0;
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-drop,
.article-wrap .media-filter .chosen-container .chosen-results li {
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-drop.highlighted,
.article-wrap .media-filter .chosen-container .chosen-results li.highlighted {
  background: #009dff;
}
.article-wrap .media-filter .chosen-container .chosen-single {
  height: 32px;
  background: #f2f2f2;
}
.article-wrap .media-filter .chosen-container .chosen-single span {
  height: 32px;
  line-height: 32px;
}
.article-wrap .media-filter .chosen-container .chosen-single div {
  padding: 3px 18px;
}
.article-wrap .article-item {
  margin-bottom: 45px;
  padding: 0 20px;
}
.article-wrap .article-item .grey-bar {
  margin-top: 25px;
}
.article-wrap .article-item .article {
  display: table;
  width: 100%;
}
.article-wrap .article-item .article a {
  text-decoration: none;
  display: block;
  position: relative;
}
.article-wrap .article-item .article a:hover .hover-overlay {
  background: #000;
  opacity: 0.4;
}
.article-wrap .article-item .article a:hover .overlay {
  height: 74px;
  width: 90px;
}
.article-wrap .article-item .article a:hover .overlay img {
  width: 56px;
}
.article-wrap .article-item .article .article-img {
  width: 222px;
  max-width: 222px;
  display: table-cell;
  vertical-align: top;
  position: relative;
}
.article-wrap .article-item .article .article-img .fancybox {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.article-wrap .article-item .article .article-img .fancybox:before {
  content: '\f16a';
  font-family: 'FontAwesome';
  font-size: 4.71428571em;
  color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 49%;
  left: 0;
  right: 0;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .fancybox:hover:before {
  opacity: 1;
  font-size: 74px;
}
.article-wrap .article-item .article .article-img img {
  max-width: 222px;
}
.article-wrap .article-item .article .article-img .hover-overlay {
  opacity: 0;
  background: none;
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 58px;
  width: 78px;
  background-color: #ff7600;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55%;
  z-index: 30;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay img {
  width: 42px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-img .overlay.news {
  background-image: url(/sitefiles/images/news-feature-icon.png);
}
.article-wrap .article-item .article .article-img .overlay.casestudy {
  background-image: url(/sitefiles/images/news-feature-icon.png);
}
.article-wrap .article-item .article .article-img .overlay.blog {
  background-image: url(/sitefiles/images/blog-feature-icon.png);
}
.article-wrap .article-item .article .article-desc {
  padding-left: 40px;
  display: table-cell;
  vertical-align: top;
}
.article-wrap .article-item .article .article-desc h3 {
  color: #666666;
  font-size: 1.35714286em;
  margin-bottom: 15px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.article-wrap .article-item .article .article-desc h3:hover {
  color: #06164a;
}
.article-wrap .article-item .blog-author {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
}
.article-wrap .article-item .blog-author .fa {
  font-size: 1.42857143em;
  padding-right: 8px;
}
.article-wrap .article-item .tags-used {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
}
.article-wrap .article-item .tags-used .fa {
  font-size: 1.42857143em;
  padding-right: 6px;
  padding-left: 8px;
  position: relative;
  top: 3px;
}
.article-wrap .article-item .tags-used ul {
  padding: 0;
  display: inline-block;
}
.article-wrap .article-item .tags-used ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 0px;
}
.article-wrap .article-item .tags-used ul li:before {
  display: none;
}
.IE8 .tags-used {
  display: block!important;
}
.IE8 .tags-used ul li:before {
  display: none;
}
.IE8 .article-wrap .article-item .article {
  display: block;
}
.IE8 .article-wrap .article-item .article .article-img {
  display: block;
  width: 100%;
}
.IE8 .article-wrap .article-item .article .article-desc {
  display: block;
  margin-top: 15px;
  padding-left: 0;
  width: 100%;
}
.IE8 .article-wrap .article-item .article a:hover .hover-overlay {
  background: url(/sitefiles/images/opacity-40.png) repeat 0px 0px;
  opacity: 1;
}
.hub-content {
  margin-bottom: 40px;
}
.hub-content .grey-bar {
  margin-top: 45px;
}
.hub-content .blog-author {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
  float: left;
}
.hub-content .blog-author .fa {
  font-size: 1.42857143em;
  padding-right: 8px;
}
.hub-content .tags-used {
  display: inline-block;
  font-size: 0.92857143em;
  margin-top: 8px;
  float: left;
}
.hub-content .tags-used .fa {
  font-size: 1.42857143em;
  padding-right: 6px;
  padding-left: 8px;
  position: relative;
  top: 3px;
}
.hub-content .tags-used ul {
  padding: 0;
  display: inline-block;
}
.hub-content .tags-used ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 0px;
}
.hub-content .tags-used ul li:before {
  display: none;
}
.post-area {
  position: relative;
  padding-left: 85px;
  float: left;
  margin-right: 21px;
  margin-bottom: 18px;
  max-width: 100%;
}
.post-area .left-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 78px;
  background: #06164a;
  color: #fff;
}
.post-area .left-type .date {
  position: absolute;
  top: 30px;
  left: 17px;
  font-size: 1.28571429em;
  text-align: center;
}
.post-area .left-type .date span {
  width: 100%;
  display: block;
  padding-left: 4px;
}
.post-area .left-type .date strong {
  display: block;
  width: 100%;
  font-size: 2.28571429em;
  margin-bottom: 5px;
}
.post-area .left-type .post-type {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 6px solid #fff;
  padding: 12px;
  width: 100%;
  text-align: center;
}
.post-area .left-type .post-type.bg-orange {
  background: #ff7600;
}
.post-area .left-type .post-type.bg-green {
  background: #06164a;
}
.post-area .left-type .post-type.bg-grey {
  background: #575757;
}
.post-area .left-type .post-type.bg-secondary {
  background: #009dff;
}
.post-area .left-type .post-type img {
  width: 40px;
}
@media (max-width: 1330px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 1199px) {
  .post-area {
    width: 422px;
  }
  .article-wrap .media-filter {
    width: 430px;
  }
  .article-wrap .media-filter .filter {
    width: 160px;
    margin-right: 20px;
  }
  .article-wrap .media-filter .filter .chosen-container .chosen-single span {
    padding-right: 7px;
  }
  .article-wrap .media-filter .filter .chosen-container .chosen-results li {
    padding-right: 6px;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: -98px;
    opacity: 1;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -98px;
  }
  .article-wrap .article-item .article .article-img {
    width: 178px;
    max-width: 178px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 178px;
  }
  .article-wrap .article-item .article .article-desc {
    padding-left: 22px;
  }
}
@media (max-width: 1130px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 991px) {
  .media-hub .case-box .case-bottom iframe {
    min-width: 136px!important;
  }
  .post-area {
    width: 290px;
  }
  .article-wrap {
    padding-top: 66px;
  }
  .article-wrap.list-no-filter {
    padding-top: 0px;
  }
  .article-wrap .media-filter {
    width: 100%;
    position: absolute;
    right: auto;
    top: -14px;
  }
  .article-wrap .media-filter .filter {
    width: 178px;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: -98px;
    opacity: 1;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -98px;
  }
  .media-hub .case-box .case-bottom .case-image,
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 100%;
    margin-bottom: 15px;
  }
  .media-hub .case-box .case-bottom .case-text,
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 100%;
  }
  .article-wrap .article-item .article .article-img {
    width: 133px;
    max-width: 133px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 133px;
  }
  .article-wrap .article-item .article .article-img .overlay {
    padding: 7px 12px;
  }
  .article-wrap .article-item .article .article-img .overlay img {
    width: 32px;
  }
  .article-wrap .article-item .article a:hover .overlay img {
    width: 45px;
  }
}
@media (max-width: 905px) {
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    left: 0;
    opacity: 0.5;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0;
  }
}
@media (max-width: 767px) {
  .article-wrap .media-filter .filter {
    width: 160px;
  }
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 65%;
  }
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 35%;
  }
  .media-hub .case-box .case-bottom .case-image {
    width: 40%;
  }
  .media-hub .case-box .case-bottom .case-text {
    width: 60%;
  }
  .media-hub .row .col-sm-6 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .media-hub .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .media-hub .case-box.big-box {
    margin-bottom: 15px;
  }
  .media-hub {
    padding: 0;
  }
  .media-hub .media-view {
    position: static;
    margin: 0 auto 25px;
    width: 100%;
  }
  .media-hub .media-view .grey-bar {
    display: block!important;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons div {
    position: fixed;
  }
  .article-item .grey-bar {
    display: block!important;
  }
  .media-hub .owl-theme .owl-controls .owl-buttons {
    display: none;
  }
  .media-hub .case-box:last-child {
    margin-bottom: 15px;
  }
  .hub-content .tags-used,
  .hub-content .blog-author {
    float: none;
  }
}
@media (max-width: 550px) {
  .post-area {
    width: 100%;
    float: none;
  }
  .hub-content {
    margin-bottom: 20px;
  }
  .hub-content .grey-bar {
    display: block!important;
    margin-top: 25px;
  }
}
@media (max-width: 480px) {
  .article-wrap {
    padding-top: 160px;
  }
  .article-wrap.list-no-filter {
    padding-top: 0px;
  }
  .article-wrap .media-filter {
    padding: 16px;
  }
  .article-wrap .media-filter .filter {
    width: 100%;
    margin-bottom: 15px;
  }
  .article-wrap .media-filter .filter select {
    width: 100%!important;
    height: 40px;
  }
  .article-wrap .media-filter .filter.no-marg {
    margin: 0;
  }
  .media-hub .case-box .case-bottom .case-image,
  .media-hub .case-box.big-box .case-bottom .case-image {
    width: 100%;
  }
  .media-hub .case-box .case-bottom .case-text,
  .media-hub .case-box.big-box .case-bottom .case-text {
    width: 100%;
  }
  .media-hub .case-box .case-bottom .case-text h6 {
    margin-top: 10px;
  }
  .article-wrap .article-item .article {
    display: block;
    width: 100%;
  }
  .article-wrap .article-item .article .article-img {
    width: 100%;
    max-width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .article-wrap .article-item .article .article-img img {
    max-width: 100%;
  }
  .article-wrap .article-item .article .article-desc {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .article-wrap .article-item {
    margin-bottom: 25px;
    padding: 0 10px;
  }
  .article-wrap .article-item .tags-used {
    display: block;
  }
  .article-wrap .article-item .tags-used .fa {
    padding-left: 0;
  }
}
.replaced .article-item {
  opacity: 0;
}
@media (max-width: 360px) {
  .post-area .left-type .date {
    top: 20px;
    font-size: 1.28571429em;
  }
}
.BlogCommentsTitle {
  margin-bottom: 20px;
  display: block;
}
.CommentUserName {
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}
.CommentDate {
  margin-top: 5px;
  display: block;
  font-size: 80%;
}
@-webkit-keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.HomePage .no-js {
  -webkit-animation: opac 3s;
  animation: opac 3s;
}
.no-js .js-btn {
  display: none !important;
}
.no-js .no-js-btn {
  display: block !important;
}
.no-js .js-div {
  display: none !important;
}
.no-js .no-js-div {
  display: block !important;
}
.no-js .no-js-div .login-click a {
  color: #ffffff;
}
.no-js .add-to-basket,
.no-js .basket-icon-button {
  display: none !important;
}
.no-js .enquire-now {
  display: block;
  padding-left: 12px !important;
  float: right;
}
.no-js header {
  opacity: 1 !important;
  transition: none;
  display: block;
  top: 0px;
}
.no-js header .depot-area,
.no-js header .search-area,
.no-js header .basket-area,
.no-js header .country-area {
  display: none !important;
}
.no-js header .signoutLink {
  display: none;
}
.no-js header .top-links .left-links .menu-btn {
  display: none !important;
}
.no-js header .top-links .right-links {
  min-width: 30%;
}
.no-js header .top-links .right-links .ipad-search {
  display: none !important;
}
.no-js header .top-links .right-links .mail-icon {
  opacity: 1 !important;
}
.no-js .main-content .owl-carousel {
  display: block;
}
.no-js .main-content .owl-carousel .item {
  display: none;
}
.no-js .main-content .owl-carousel .item:first-child {
  display: block;
}
.no-js .main-content .owl-carousel.media-main .item {
  display: block;
  padding: 0 0 20px 0;
}
.no-js .homepage-signposts .signpost-item {
  opacity: 1;
  width: 25%;
}
.no-js .tech-library .login-register .login-box .login-box-back {
  position: initial !important;
  max-height: none !important;
}
.no-js .tech-library .login-register .login-box:hover.green-login {
  background: #009dff !important;
}
.no-js .tech-library .login-register .login-box:hover.orange-login {
  background: #06164a !important;
}
.no-js .tech-library .login-register .login-box .login-inner .close-box {
  display: none;
}
.no-js .media-filter,
.no-js .date-filter {
  display: none !important;
}
.no-js #ProductsList .orig,
.no-js .article-wrap .orig {
  display: block;
}
.no-js .tech-library .tech-search {
  display: none;
}
.no-js .media-hub .media-view {
  display: none;
}
.no-js .animate-banner {
  opacity: 1 !important;
  left: 0%;
}
.no-js .animate-banner .home-banner {
  opacity: 1 !important;
}
.no-js .animate-banner .home-banner .item {
  display: none;
}
.no-js .animate-banner .home-banner .item:first-child {
  display: block;
}
.no-js .load-home-content {
  opacity: 1 !important;
}
.no-js .technical-widget .orig {
  display: block;
}
.no-js footer {
  display: block !important;
}
@media (max-width: 991px) {
  .no-js header .desktop-nav {
    display: block;
    width: 100%;
  }
  .no-js header .desktop-nav ul li {
    width: 100%;
    display: inline-block;
  }
  .no-js header .desktop-nav ul li a {
    padding: 10px 14px;
    font-size: 1.2em;
  }
  .no-js header .login-area {
    opacity: 1 !important;
  }
  .no-js header .login-area a {
    padding-top: 10px;
  }
  .no-js header .login-area a span {
    opacity: 1 !important;
  }
  .no-js .footer-links ul {
    display: block;
  }
}
@media (max-width: 767px) {
  .no-js .back-to-desktop {
    display: block;
  }
  header .top-links .right-links .login-area .login-icon {
    top: 13px;
  }
}
@media (max-width: 767px) {
  .no-js header .logo {
    position: absolute;
    margin: 12px 0 13px 18px;
    padding-right: 25px;
    transition: all 0.5s ease-in-out;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 550px) {
  .no-js header .logo {
    text-align: center;
    margin: 10px auto;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .no-js .homepage-signposts .signpost-item {
    opacity: 1;
    width: 50%;
  }
  .no-js .homepage-signposts .signpost-item:last-child {
    display: block;
  }
  .no-js .homepage-signposts .signpost-item .widget.sign-post-widget {
    min-height: 105px;
  }
}
.solution-box {
  padding: 15px 25px 25px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  margin-bottom: 60px;
}
.product-filter {
  background: #009dff;
  padding: 5px 10px;
  text-align: center;
  margin-bottom: 30px;
  min-height: 54px;
}
.product-filter .filter-inner {
  display: inline-block;
  width: auto;
}
.product-filter label {
  display: block;
  float: left;
  color: #fff;
  margin-right: 13px;
  font-size: 1.28571429em;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 12px;
}
.product-filter select {
  width: 300px;
  display: block;
  float: left;
  margin: 5px 0 0 0;
  height: 33px;
}
.product-filter .chosen-container {
  display: block;
  float: left;
  width: 268px !important;
  height: 33px;
  margin: 5px 0 0 0;
  background: #f2f2f2;
}
.product-filter .chosen-container .chosen-single span {
  height: 33px;
  line-height: 35px;
  padding-left: 9px;
  text-align: left;
  padding-right: 19px;
}
.product-filter .chosen-container .chosen-single {
  height: 33px;
  padding-left: 0;
}
.product-filter .chosen-container .chosen-single div {
  padding: 3px 10px;
  width: 42px;
}
.product-filter .chosen-container .chosen-results li {
  padding-left: 6px;
  padding-right: 9px;
  text-align: left;
  height: 33px;
  line-height: 25px;
}
.product-filter .prod-keyword-search {
  float: left;
}
.product-filter .prod-keyword-search label {
  display: none;
}
.product-filter .prod-keyword-search #tbProductFilter {
  margin-left: 15px;
  padding: 6px 10px 6px;
  border: none;
  float: left;
  margin-top: 6px;
  position: relative;
}
.product-filter .prod-keyword-search #btnProductFilter {
  padding: 7px 18px 5px 18px;
  background-image: none;
  margin-top: 6px;
  margin-left: 6px;
  float: left;
}
.product-view {
  margin-bottom: 40px;
}
.product-view ul {
  margin-bottom: 25px;
  padding-left: 16px;
}
.product-view ul li {
  margin-bottom: 10px;
}
.product-view .post-area {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 85px;
}
.product-view .post-area .left-type {
  left: auto;
  right: 0;
}
.product-view .post-area .left-type .new {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: #ff7600;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 1.28571429em;
  padding-top: 20px;
}
.product-view .post-area .product-carousel {
  aspect-ratio: 1.70469799;
}
.product-view table {
  font-size: 86%;
  margin-bottom: 40px;
}
.product-view .smallprint {
  font-size: 1em;
  margin-bottom: 30px;
  display: block;
}
.product-view .btn {
  margin-bottom: 40px;
}
.add-to-basket {
  position: relative;
}
.popup-basket-msg {
  font-size: 1.28571429em;
  display: none;
  position: absolute;
  bottom: 105px;
  background-color: #06164a;
  padding: 10px 10px 0px;
  color: #fff;
  text-align: center;
  z-index: 10;
  right: 0px;
}
.popup-basket-msg.iconview {
  width: 255px;
}
.popup-basket-msg .message i {
  margin-right: 10px;
}
.popup-basket-msg .message a {
  color: #fff;
}
@media (max-width: 767px) {
  .product-filter .prod-keyword-search {
    clear: both;
    margin-left: 0px;
  }
  .product-filter .prod-keyword-search label {
    display: block;
  }
  .product-filter .prod-keyword-search #tbProductFilter {
    margin-left: 1px;
    left: 0px;
    padding: 6px 15px 6px 9px;
  }
}
@media (max-width: 362px) {
  .product-view .post-area .left-type .new {
    width: 52px;
    height: 28px;
    padding-top: 5px;
  }
}
.signpost-banner {
  padding: 5px 30px;
  opacity: 0;
  top: -64px;
  transition: opacity 0.3s ease-in, top 0.3s ease-in;
}
.signpost-banner__link:hover {
  text-decoration: none;
}
.signpost-banner__container {
  display: flex;
  justify-content: center;
}
@media (max-width: 996px) {
  .signpost-banner__container {
    flex-direction: column;
    align-items: center;
  }
}
.signpost-banner__left-container {
  display: flex;
  align-items: center;
}
@media (max-width: 996px) {
  .signpost-banner__left-container {
    margin-bottom: 10px;
  }
}
.signpost-banner__highlighted-text {
  padding: 10px 20px;
  font-size: 27px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-weight: 700;
  margin-right: 20px;
}
.signpost-banner__image {
  margin-right: 20px;
}
.signpost-banner__right-container {
  display: flex;
  align-items: flex-end;
}
.signpost-banner__rich-text {
  font-size: 19px;
  line-height: 19px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
  margin-bottom: 5px;
}
.signpost-banner__rich-text p {
  font-size: 19px;
  line-height: 19px;
  font-family: 'ff-dax-pro', Arial, sans-serif;
}
.signpost-banner__rich-text strong {
  font-family: 'ff-dax-pro', Arial, sans-serif;
  font-weight: 700;
}
.styleguide {
  position: relative;
}
.styleguide .styleguide-nav {
  display: block;
  position: fixed;
  width: 160px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  top: 192px;
  right: 0;
  z-index: 99;
}
.styleguide .styleguide-nav ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.styleguide .styleguide-nav ul li {
  display: block;
}
.styleguide .styleguide-nav ul li:before {
  display: none;
}
.styleguide .styleguide-nav ul li a {
  display: block;
  line-height: 1.5em;
  color: #ff7600;
}
.styleguide .styleguide-nav ul li a:hover {
  color: #ff7600;
}
.styleguide .style-section {
  display: block;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.styleguide .style-section .style-section-title {
  display: block;
  margin: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
  background-color: #f5f5f5;
}
.styleguide .style-section .style-section-content {
  display: block;
  margin: 0;
  border-radius: 0 0 4px 4px;
  padding: 15px;
}
.styleguide .swatch {
  display: block;
  margin-bottom: 15px;
  width: 23%;
  float: left;
  height: 142px;
  margin: 0 1% 16px;
  border: 1px solid #a1a1a1;
  border-radius: 6px;
}
.styleguide .swatch .swatch-colour {
  display: block;
  height: 100px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
}
.styleguide .swatch .swatch-detail {
  display: block;
  border-radius: 0 0 4px 4px;
  border: 1px solid #a1a1a1;
  background-color: #a1a1a1;
  text-align: center;
  color: #fff;
}
.styleguide .swatch .swatch-detail .swatch-hex,
.styleguide .swatch .swatch-detail .swatch-name {
  display: block;
}
.styleguide .swatch .swatch-detail .swatch-hex {
  text-transform: lowercase;
}
.styleguide .style-source-hover {
  position: relative;
}
.styleguide .style-source-hover .style-source-btn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 32px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.styleguide .style-source-hover .style-source-btn::before {
  content: '\f121';
  font-family: 'FontAwesome';
}
.styleguide .style-source-hover:hover .style-source-btn {
  display: block;
}
.styleguide .style-source-hover.style-section .style-source-btn {
  height: 46px;
  line-height: 46px;
}
@media (max-width: 767px) {
  .styleguide .styleguide-nav {
    display: none;
  }
}
.tabbed-area {
  display: block;
  height: 100%;
  margin-bottom: 32px;
}
.section--info-promos .tabbed-area {
  height: 60%;
}
.tabbed-area__title {
  display: block;
  /*color: #ffffff;*/
  line-height: 1;
  text-align: center;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.5);
}
.tabbed-area__navigation {
  display: none;
}
.tabbed-area__tab {
  display: block;
  width: 100%;
  outline: none;
  margin: 0;
  border: 0;
  padding: 8px 32px;
  /*background: #5f259f;
    color: #ffffff;*/
  line-height: 1.5;
  transition: background-color ease 0.3s, color ease 0.3s;
}
.tabbed-area__tab--active,
.tabbed-area__tab:focus,
.tabbed-area__tab:hover {
  /*background: #ffffff;
        color: #5f259f;*/
}
.tabbed-area__content {
  display: none;
  padding: 24px;
  height: 100%;
  width: 100%;
  border: 1px solid #dfdfdf;
  overflow: auto;
}
.tabbed-area__content--active {
  display: block;
}
button.tabbed-area__tab.tabbed-area__tab--active {
  background: url(/sitefiles/Images/nav-lines.png) repeat 0 0;
  color: #000;
}
.tabbed-area__content .row {
  display: block;
  margin: 0 -20px;
}
.tabbed-area__content .column {
  display: block;
  float: left;
  width: 50%;
  padding: 0 20px;
}
.tabbed-area__content img {
  height: auto !important;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .tabbed-area__title {
    height: 80px;
    margin: 0;
    text-align: left;
  }
  .tabbed-area__navigation {
    display: inline-block;
    height: 64px;
  }
  .tabbed-area__navigation .tabbed-area__tab {
    display: inline-block;
  }
  .tabbed-area__tab {
    display: none;
    height: 100%;
    width: auto;
    outline: none;
    margin: 0;
    border: 0;
    padding: 0 32px;
    /*background: #5f259f;
        color: #ffffff;*/
    line-height: 1.33333;
    transition: background-color ease 0.3s, color ease 0.3s;
  }
  .tabbed-area__tab--active,
  .tabbed-area__tab:focus,
  .tabbed-area__tab:hover {
    /*background: #ffffff;
            color: #5f259f;*/
  }
}
@media screen and (min-width: 992px) {
  .tabbed-area__tab {
    line-height: 1;
  }
}
@media screen and (min-width: 1200px) {
  .tabbed-area__tab {
    line-height: 1.5;
  }
}
.tech-library {
  margin-bottom: 50px;
  min-height: 500px;
}
.tech-library .tech-header h1 {
  float: left;
  width: 50%;
  margin: 7px 0;
}
.tech-library .tech-header .tech-library-buttons {
  float: left;
  width: 50%;
  margin-bottom: 20px;
  text-align: right;
}
.tech-library .tech-search {
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat;
  padding: 40px;
  position: relative;
  margin-bottom: 50px;
}
.tech-library .tech-search .inner-search {
  background: #fff;
  position: relative;
  overflow: hidden;
}
.tech-library .tech-search .inner-search.typed .search-text {
  left: -200px;
}
.tech-library .tech-search .inner-search.typed input[type='submit'] {
  right: 22px;
}
.tech-library .tech-search input[type='text'],
.tech-library .tech-search input[type='search'] {
  width: 100%;
  border: none;
  background: transparent;
  height: auto;
  line-height: normal;
  padding: 15px 85px 15px 40px;
  font-size: 2.85714286em;
  position: relative;
  z-index: 2;
  outline: none;
}
.tech-library .tech-search input[type='text']:focus + input[type='submit'] {
  right: 22px;
}
.tech-library .tech-search input[type='text']:focus ~ .search-text {
  left: -200px;
}
.tech-library .tech-search .search-text {
  position: absolute;
  top: 36px;
  left: 40px;
  font-size: 2.85714286em;
  color: #06164a;
  -moz-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  -webkit-transition: left 0.4s ease;
  transition: left 0.4s ease;
  z-index: 0;
}
.tech-library .tech-search .search-text i {
  margin-right: 15px;
}
.tech-library .tech-search input[type='submit'] {
  position: absolute;
  top: 18px;
  right: -100px;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat 0 -294px;
  text-indent: -2000em;
  width: 54px;
  height: 54px;
  -moz-transition: right 0.4s ease;
  -o-transition: right 0.4s ease;
  -webkit-transition: right 0.4s ease;
  transition: right 0.4s ease;
  z-index: 3;
}
.tech-library h3 {
  color: #4d4d4d;
}
.tech-library .files {
  margin-bottom: 40px;
}
.tech-library .files button {
  background: #e4e9d4;
  color: #06164a;
  padding: 5px 15px;
  margin-bottom: 40px;
}
.tech-library .files button:hover {
  background: #06164a;
  color: #e4e9d4;
}
.tech-library .login-register {
  padding: 40px;
  background: url(app/modules/~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  margin: 30px 0 40px;
}
.tech-library .login-register .reg-box .EditingFormInfoLabel {
  float: left;
}
.tech-library .login-register .widget {
  margin: 0;
}
.tech-library .login-register .login-box {
  color: #ffffff;
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
  position: relative;
}
.tech-library .login-register .login-box .alert {
  margin-top: 0;
}
.tech-library .login-register .login-box .form-control-error .alert {
  margin-top: 20px !important;
}
.tech-library .login-register .login-box .LoginPanelPasswordRetrieval {
  display: block;
}
.tech-library .login-register .login-box.orange-login {
  background: #ff7600;
}
.tech-library .login-register .login-box.orange-login .login-box-back {
  background: #ff7600;
}
.tech-library .login-register .login-box.orange-login .login-btn,
.tech-library .login-register .login-box.orange-login input[type='submit'] {
  background-image: url(app/modules/~@imgOld/submit-arrow-orange.png);
  color: #ff7600;
  background-position: right;
}
.tech-library .login-register .login-box.green-login {
  background: #06164a;
}
.tech-library .login-register .login-box.green-login .login-box-back {
  background: #06164a;
}
.tech-library .login-register .login-box.green-login .login-btn,
.tech-library .login-register .login-box.green-login input[type='submit'] {
  color: #06164a;
}
.tech-library .login-register .login-box.grey-login {
  background: #575757;
}
.tech-library .login-register .login-box.grey-login .login-box-back {
  background: #575757;
}
.tech-library .login-register .login-box:hover.orange-login {
  background: #ff9133;
}
.tech-library .login-register .login-box:hover.green-login {
  background: #0a2479;
}
.tech-library .login-register .login-box:hover.grey-login {
  background: #717171;
}
.tech-library .login-register .login-box .login-box-top {
  display: block;
  padding: 18px 12px;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  cursor: pointer;
}
.tech-library .login-register .login-box .login-box-top .signpost {
  display: table;
  width: 100%;
  height: 54px;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  text-align: center;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-icon img {
  position: absolute;
  top: 0px;
  left: 21px;
  width: 46px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.tech-library .login-register .login-box .login-box-top .signpost .signpost-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.85714286em;
  line-height: 29px;
  color: #fff;
}
.tech-library .login-register .login-box .login-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  display: block;
  -moz-transition: max-height 0.8s ease-in-out;
  -o-transition: max-height 0.8s ease-in-out;
  -webkit-transition: max-height 0.8s ease-in-out;
  transition: max-height 0.8s ease-in-out;
  -webkit-transition-delay: 0;
  /* Safari */
  -moz-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
  z-index: 2;
}
.tech-library .login-register .login-box .login-box-back .login-inner {
  padding: 40px 30px 30px;
  position: relative;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox {
  display: flex;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox input {
  flex: 1 1 15%;
}
.tech-library .login-register .login-box .login-box-back .login-inner .checkbox label {
  flex: 1 1 85%;
}
.tech-library .login-register .login-box .login-box-back .login-inner .close-box {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 1.64285714em;
  cursor: pointer;
}
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn,
.tech-library .login-register .login-box .login-box-back .login-inner input,
.tech-library .login-register .login-box .login-box-back .login-inner textarea {
  display: block;
  width: 100%;
  border: none;
  margin-bottom: 20px;
  padding: 15px 40px 15px 15px;
  border-radius: 0px;
  height: auto;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.email-icon,
.tech-library .login-register .login-box .login-box-back .login-inner input[type='email'] {
  background: #fff url(/sitefiles/images/email-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.name-icon {
  background: #fff url(/sitefiles/images/name-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.company-icon {
  background: #fff url(/sitefiles/images/company-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.password-icon,
.tech-library .login-register .login-box .login-box-back .login-inner input[type='password'] {
  background: #fff url(/sitefiles/images/password-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner input.address-icon {
  background: #fff url(/sitefiles/images/address-icon.jpg) no-repeat right top;
}
.tech-library .login-register .login-box .login-box-back .login-inner a {
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
}
.tech-library .login-register .login-box .login-box-back .login-inner a:hover {
  color: #ff7600;
}
.tech-library .login-register .login-box .login-box-back .login-inner input[type='submit'],
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn {
  background-color: #fff;
  width: auto;
  display: inline-block;
  margin: 0;
  font-size: 1.28571429em;
  vertical-align: middle;
}
.tech-library .login-register .login-box .login-box-back .login-inner .login-btn {
  color: #06164a;
  background: #fff url(app/modules/~@imgOld/submit-arrow-blue.png) no-repeat right center;
  float: right;
}
.tech-library .login-register .login-box.open-box.reg-box .login-box-back {
  max-height: 1000px;
}
.tech-library .login-register .login-box.open-box .login-box-top {
  opacity: 0;
}
.tech-library .login-register .login-box.open-box .login-box-back {
  max-height: 1000px;
  -webkit-transition-delay: 0.5s;
  /* Safari */
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  z-index: 10;
}
.folder-item {
  margin-bottom: 30px;
  width: 100%;
  display: table;
}
.folder-item .folder {
  display: table-cell;
  width: 54px;
  vertical-align: top;
}
.folder-item .folder-desc {
  display: table-cell;
  padding-left: 10px;
  vertical-align: top;
}
.folder-item .folder-desc a {
  text-decoration: none;
}
.folder-item .folder-desc h4 {
  color: #06164a;
  font-size: 1.21428571em;
  margin-top: 3px;
  margin-bottom: 0;
  -moz-transition: color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
}
.folder-item .folder-desc h4:hover {
  color: #092170;
}
.folder-item .folder-desc p {
  margin: 0;
  font-size: 0.92857143em;
  color: #999999;
}
.intro-tech {
  margin-bottom: 40px;
}
.intro-tech .grey-bar {
  margin-top: 30px;
}
.youtube-btn,
.download-btn {
  padding: 10px 25px 8px 88px !important;
  position: relative;
  display: inline-block !important;
  font-size: 1.9em !important;
  margin-top: 15px;
}
.youtube-btn:before,
.download-btn:before {
  display: block;
  font-family: 'FontAwesome';
  font-size: 1.42857143em;
  position: absolute;
  top: 3px;
  color: #ffffff;
  left: 29px;
  right: auto;
}
.youtube-btn:before {
  content: '\f144';
  color: #009dff;
  line-height: 52px;
}
.download-btn:before {
  content: '\f019';
}
@media (max-width: 1199px) {
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-icon {
    width: 77px;
  }
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-icon img {
    left: 7px;
  }
}
@media (max-width: 991px) {
  .tech-library .tech-search .search-text {
    top: 50%;
    margin-top: -7px;
  }
  .tech-library .tech-search input[type='submit'] {
    top: 50%;
    margin-top: -28px;
  }
}
@media (max-width: 767px) {
  .tech-library .tech-header h1,
  .tech-library .tech-header .tech-library-buttons {
    float: none;
    width: 100%;
    text-align: left;
  }
  .intro-tech,
  .tech-library .files {
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .tech-library .login-register {
    padding: 15px;
  }
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-text {
    font-size: 1.6em;
  }
  .tech-library .tech-header .tech-library-buttons .btn {
    margin-bottom: 15px;
  }
  .tech-library .tech-search {
    padding: 40px 20px;
  }
  .tech-library .tech-search .search-text {
    font-size: 1.85714286em;
    top: 50%;
    margin: -10px 0 0 0;
  }
  .tech-library .tech-search input[type='text'],
  .tech-library .tech-search input[type='search'] {
    padding: 15px 72px 15px 14px;
  }
  .tech-library .tech-search input[type='submit'] {
    background: url('/sitefiles/images/sprite-tpa.png') no-repeat -55px -294px;
    top: 50%;
    margin-top: -26px;
  }
  .youtube-btn {
    font-size: 1.4em !important;
  }
}
.replaced .tech-item {
  opacity: 0;
}
#ItemDetails {
  margin-bottom: 30px;
  clear: both;
}
#ItemDetails .tech-icon {
  float: left;
  margin-right: 1%;
  width: 19%;
}
#ItemDetails .tech-icon img {
  max-width: 100%;
}
#ItemDetails .tech-desc {
  float: left;
  width: 80%;
}
#ItemDetails .tech-desc .tags {
  font-size: 0.92857143em;
}
#ItemDetails .tech-desc .tags ul {
  padding: 0;
}
#ItemDetails .tech-desc .tags ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 3px;
}
#ItemDetails .tech-desc .tags ul li:before {
  display: none;
}
#ItemDetails .tech-desc .tags ul a {
  position: relative;
  display: inline-block;
  padding-right: 9px;
  color: #06164a;
}
#ItemDetails .tech-desc .tags ul a:hover {
  color: #081d62;
}
#ItemDetails .tech-desc .tags ul a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
  background: none;
}
.input-group .twitter-typeahead {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.twitter-typeahead .tt-hint {
  color: #585858;
  color: rgba(88, 88, 88, 0.75);
}
.twitter-typeahead .tt-menu {
  display: block;
  width: 100%;
  padding: 4px 0;
  background-color: #fff;
  box-shadow: #585858 2px 2px 4px;
  box-shadow: rgba(88, 88, 88, 0.25) 2px 2px 4px;
}
.twitter-typeahead .tt-menu .tt-dataset {
  display: block;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion {
  display: block;
  padding: 5px 12px;
  cursor: pointer;
  min-height: 60px;
  clear: both;
  color: #666666;
  line-height: 1.4em;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion:hover {
  color: #fff;
  background-color: #ff7600;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion p {
  margin: 10px 0 0;
}
.twitter-typeahead .tt-menu .tt-dataset .tt-suggestion img {
  max-width: 50px;
  float: left;
  margin-right: 15px;
}
.widget {
  margin-bottom: 25px;
}
.widget.side-nav-widget {
  border: 1px solid #bebebe;
}
.widget.side-nav-widget .link-wrap {
  padding: 10px 0;
}
.widget.side-nav-widget h3 {
  margin: 0;
  font-size: 1.28571429em;
  padding: 18px;
  background: #06164a;
  color: #fff;
}
.widget.side-nav-widget ul {
  padding: 0;
  margin: 0;
}
.widget.side-nav-widget ul li {
  display: block;
  list-style-type: none;
  position: relative;
  padding-left: 0;
}
.widget.side-nav-widget ul li:before {
  position: absolute;
  top: 12px;
  left: 20px;
  display: none;
  width: 13px;
  height: 13px;
  color: #009dff;
}
.widget.side-nav-widget ul li:hover:before {
  display: block;
  -moz-animation: wigglearrow 0.7s ease-in-out forwards;
  -o-animation: wigglearrow 0.7s ease-in-out forwards;
  -webkit-animation: wigglearrow 0.7s ease-in-out forwards;
  animation: wigglearrow 0.7s ease-in-out forwards;
}
.widget.side-nav-widget ul li.active:before {
  display: block;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat -4px -80px;
}
.widget.side-nav-widget ul li.active a {
  background: #ebebeb;
  color: #06164a;
}
.widget.side-nav-widget ul li a {
  display: block;
  padding: 9px 30px 6px 49px;
  font-size: 1.14285714em;
  color: #575757;
  font-weight: bold;
  text-decoration: none;
  -moz-transition: background 0.3s ease-in-out;
  -o-transition: background 0.3s ease-in-out;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  font-weight: 400;
}
.widget.side-nav-widget ul li a:hover,
.widget.side-nav-widget ul li a.active {
  background: #ebebeb;
  color: #06164a;
}
.widget.side-nav-widget.enquiry-basket-guide {
  border: none;
  position: relative;
}
.widget.side-nav-widget.enquiry-basket-guide.always-show {
  display: block !important;
}
.widget.side-nav-widget.enquiry-basket-guide.compact {
  display: none;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox {
  display: block;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .row {
  margin: 20px 0 0 0;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-1,
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-2 {
  padding: 0;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-1 {
  width: 6.6%;
}
.widget.side-nav-widget.enquiry-basket-guide.letterbox .col-xs-2 {
  width: 20%;
}
.widget.side-nav-widget.enquiry-basket-guide img {
  display: block;
  margin: auto;
  margin-top: 30px;
}
.widget.side-nav-widget.enquiry-basket-guide .rotate90 {
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 0;
}
@media (max-width: 991px) {
  .widget.side-nav-widget.enquiry-basket-guide .rotate90 {
    margin-top: 25px;
  }
}
.widget.side-nav-widget.enquiry-basket-guide .rotate180 {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.widget.side-nav-widget.enquiry-basket-guide table,
.widget.side-nav-widget.enquiry-basket-guide td {
  border: none;
  white-space: normal;
}
.widget.side-nav-widget.enquiry-basket-guide table.no-padding,
.widget.side-nav-widget.enquiry-basket-guide td.no-padding {
  padding: 0;
}
.widget.side-nav-widget.enquiry-basket-guide .circle {
  background: #009dff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  display: block;
  position: relative;
  border: 2px solid #dfdfdf;
  margin: auto;
  text-align: center;
  vertical-align: bottom;
  font-size: 1.8em;
}
.widget.side-nav-widget.enquiry-basket-guide .circle .fa {
  color: #ffffff;
}
.widget.side-nav-widget.enquiry-basket-guide p {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.widget.twitter-widget iframe {
  width: 100% !important;
}
.widget.sign-post-widget {
  -moz-transition: background 0.5s ease-in-out;
  -o-transition: background 0.5s ease-in-out;
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}
.widget.sign-post-widget.theme-1-signpost {
  background-color: #06164a;
}
.widget.sign-post-widget.theme-2-signpost {
  background-color: #009dff;
}
.widget.sign-post-widget.theme-3-signpost {
  background-color: #4d4d4d;
}
.widget.sign-post-widget.orange-signpost {
  background: #ff7600;
}
.widget.sign-post-widget.green-signpost {
  background: #06164a;
}
.widget.sign-post-widget.grey-signpost {
  background: #575757;
}
.widget.sign-post-widget.blue-signpost {
  background: #009dff;
}
.widget.sign-post-widget.mid-blue-signpost {
  background: #26358c;
}
.widget.sign-post-widget.red-signpost {
  background: #e94d3a;
}
.widget.sign-post-widget:hover.orange-signpost {
  background: #ff9133;
}
.widget.sign-post-widget:hover.green-signpost {
  background: #0a2479;
}
.widget.sign-post-widget:hover.grey-signpost {
  background: #717171;
}
.widget.sign-post-widget:hover.blue-signpost {
  background: #33b1ff;
}
.widget.sign-post-widget:hover.mid-blue-signpost {
  background: #3144b4;
}
.widget.sign-post-widget:hover.red-signpost {
  background: #ee7668;
}
.widget.sign-post-widget.tech-file a .signpost .signpost-icon img {
  top: -3px !important;
  width: 50px;
}
.widget.sign-post-widget a {
  outline: none;
  text-decoration: none;
  display: block;
  padding: 18px 8px;
  /*&:hover {
                .signpost .signpost-icon img {
                    width: 57px;
                    top: -4px;
                    left: 18px;
                }
            }*/
}
.widget.sign-post-widget .signpost {
  display: table;
  width: 100%;
}
.widget.sign-post-widget .signpost .depot-title {
  font-size: 1.64285714em;
  line-height: 29px;
  color: #fff;
  text-align: center;
  padding: 10px 0 3px 0;
}
.widget.sign-post-widget .signpost .postcode-select {
  padding: 0 20px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container {
  height: 36px;
  line-height: 36px;
  margin-bottom: 17px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
  padding: 1px 12px;
  height: 36px;
  line-height: 36px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single div {
  padding: 5px 15px;
}
.widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single span {
  padding-right: 0;
  height: 36px;
  line-height: 36px;
}
.widget.sign-post-widget .signpost .signpost-icon {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 70px;
  text-align: center;
}
.widget.sign-post-widget .signpost .signpost-icon img {
  top: 0px;
  left: 21px;
  width: 46px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.sign-post-widget .signpost .signpost-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.85714286em;
  line-height: 29px;
  color: #fff;
  max-width: 170px;
}
@media (max-width: 991px) {
  .widget.sign-post-widget .signpost .signpost-text {
    font-size: 20px;
  }
}
.widget.sign-post-widget .dropdown-signpost {
  display: block;
}
.widget.banner-widget {
  position: relative;
  padding-right: 38px;
}
.widget.banner-widget a {
  text-decoration: none;
}
.widget.banner-widget a:hover h3 {
  background: #081d62;
}
.widget.banner-widget h3 {
  background: #06164a;
  margin: 0;
  color: #fff;
  font-size: 1.14285714em;
  padding: 10px 15px;
  -moz-transition: background 0.4s ease-in-out;
  -o-transition: background 0.4s ease-in-out;
  -webkit-transition: background 0.4s ease-in-out;
  transition: background 0.4s ease-in-out;
}
.widget.banner-widget .new-item {
  position: absolute;
  top: 0;
  padding: 16px 10px;
  left: 0;
  text-transform: uppercase;
  font-size: 1.35714286em;
  color: #fff;
  background: #ff7600;
}
.widget.banner-widget #widget-banner .item {
  position: relative;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -38px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-next {
  opacity: 1;
  margin: 14px 5px;
  text-indent: -9999px;
  display: block;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat -4px -105px;
  width: 26px;
  height: 26px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-next:hover {
  background-position: -36px -105px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-prev {
  opacity: 1;
  margin: 14px 5px;
  text-indent: -9999px;
  display: block;
  background: url('/sitefiles/images/sprite-tpa.png') no-repeat -4px -139px;
  width: 26px;
  height: 26px;
}
.widget.banner-widget #widget-banner .owl-controls .owl-buttons .owl-prev:hover {
  background-position: -36px -139px;
}
.widget.technical-widget {
  position: relative;
}
.widget.technical-widget .login-links {
  position: absolute;
  top: 0px;
  right: 0px;
}
.widget.technical-widget .login-links a {
  position: relative;
  display: inline-block;
  padding-right: 10px;
}
.widget.technical-widget .login-links a:last-child:before {
  display: none;
}
.widget.technical-widget .login-links a:before {
  display: block;
  content: '/';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
}
.widget.technical-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 114px;
}
.widget.technical-widget a {
  text-decoration: none;
}
.widget.technical-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.technical-widget .tech-item {
  display: table;
  width: 100%;
  margin-bottom: 25px;
}
.widget.technical-widget .tech-item .tech-icon {
  display: table-cell;
  vertical-align: top;
  width: 52px;
  max-width: 52px;
}
.widget.technical-widget .tech-item .tech-desc {
  display: table-cell;
  vertical-align: top;
}
.widget.technical-widget .tech-item .tech-desc h3 {
  font-size: 1.21428571em;
  color: #009dff;
  font-weight: normal;
  margin: 0 0 3px;
  padding-right: 0;
}
.widget.technical-widget .tech-item .tech-desc h3:hover {
  color: #24abff !important;
}
.widget.technical-widget .tech-item .tech-desc .tags {
  font-size: 0.92857143em;
}
.widget.technical-widget .tech-item .tech-desc .tags ul {
  padding: 0;
}
.widget.technical-widget .tech-item .tech-desc .tags ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 3px;
}
.widget.technical-widget .tech-item .tech-desc .tags ul li:before {
  display: none;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a {
  position: relative;
  display: inline-block;
  padding-right: 9px;
  color: #06164a;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a:hover {
  color: #081d62;
}
.widget.technical-widget .tech-item .tech-desc .tags ul a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: -4px;
  background: none;
}
.widget.advertise-widget {
  position: relative;
}
.widget.advertise-widget .owl-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.widget.advertise-widget .owl-theme .owl-controls .owl-page span {
  display: block;
  margin: 5px 3px;
  width: 10px;
  height: 10px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: #4f4f4f;
}
.widget.advertise-widget .item img {
  width: 100%;
}
.widget.article-widget {
  position: relative;
}
.widget.article-widget .article-links {
  position: absolute;
  top: 0px;
  right: 0px;
}
.widget.article-widget .article__thumb {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.widget.article-widget .article-desc__date {
  margin-bottom: 10px;
}
.InnerPage .widget.article-widget .jilted-title {
  margin-top: 0px;
  margin-bottom: 20px;
}
.widget.article-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 66px;
}
.widget.article-widget a {
  text-decoration: none;
}
.widget.article-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.article-widget .article-item-wrap {
  margin-bottom: 30px;
  position: relative;
  line-height: 0;
}
.widget.article-widget .article-item-wrap:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
}
.widget.article-widget .article-item-wrap:hover .icon-area {
  background: #ff7600;
}
.widget.article-widget .article-item-wrap > a {
  display: inline-block;
  margin-right: 20px;
  width: 100%;
}
.widget.article-widget .article-item-wrap .icon-area {
  width: 34px;
  height: 34px;
  display: block;
  position: absolute;
  background: #009dff;
  bottom: 0;
  right: -20px;
}
@media (max-width: 991px) {
  .widget.article-widget .article-item-wrap .icon-area {
    right: 20px;
  }
}
@media (max-width: 767px) {
  .widget.article-widget .article-item-wrap .icon-area {
    right: 0;
  }
}
.widget.article-widget .article-item-wrap .grey-divide {
  display: block;
  position: relative;
  height: 8px;
  width: 100%;
  background: #cccccc;
}
.widget.article-widget .article-item-wrap .grey-divide:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 9px;
  width: 35px;
  background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 1px;
}
.widget.article-widget .article-item-wrap .article-item {
  position: relative;
  display: flex;
  width: 100%;
  transition: box-shadow 0.5s;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0);
}
.widget.article-widget .article-item-wrap .article-item .article-icon {
  vertical-align: top;
  width: 100%;
  max-width: 97px;
}
.widget.article-widget .article-item-wrap .article-item .article-desc {
  vertical-align: top;
  padding: 10px 45px 10px 15px;
  background-color: #ffffff;
  min-height: 90px;
  width: 100%;
}
.widget.article-widget .article-item-wrap .article-item .article-desc h3 {
  font-size: 0.92857143em;
  color: #06164a;
  font-weight: normal;
  margin: 0 0 3px;
  padding-right: 0;
  line-height: 1;
}
.widget.article-widget .article-item-wrap .article-item .article-desc h3:hover {
  color: rgba(6, 22, 74, 0.8);
}
.widget.article-widget .article-item-wrap .article-item .article-desc p {
  display: none;
}
.widget.article-widget .article-item-wrap .article-item .article-desc small {
  font-size: 0.85714286em;
  display: block;
  color: #009dff;
  line-height: 1;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags {
  font-size: 0.92857143em;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags a {
  position: relative;
  display: inline-block;
  padding-right: 13px;
}
.widget.article-widget .article-item-wrap .article-item .article-desc .tags a:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
.widget.blog-widget {
  position: relative;
  padding-top: 39px;
}
.widget.blog-widget .blog-profile {
  position: absolute;
  top: 0;
  left: 0;
}
.widget.blog-widget .blog-links {
  position: absolute;
  top: 0;
  right: 0px;
}
.widget.blog-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-left: 62px;
  padding-right: 66px;
  position: relative;
  top: -37px;
}
.widget.blog-widget a {
  text-decoration: none;
}
.widget.blog-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
}
.widget.blog-widget .blog-item-wrap {
  margin-bottom: 0px;
}
.widget.blog-widget .blog-item-wrap .grey-divide {
  display: block;
  position: relative;
  height: 8px;
  width: 100%;
  background: #cccccc;
}
.widget.blog-widget .blog-item-wrap .grey-divide:before {
  position: absolute;
  top: 0;
  right: 0;
  content: ' ';
  display: block;
  height: 9px;
  width: 35px;
  background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0px 1px;
}
.widget.blog-widget .blog-item-wrap .blog-item {
  display: table;
  width: 100%;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 105px;
  max-width: 105px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon a {
  position: relative;
  display: block;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon a:hover .transparent-overlay {
  background: #000;
  opacity: 0.4;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-icon .transparent-overlay {
  background: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
  display: table-cell;
  vertical-align: top;
  padding-left: 15px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc h3 {
  font-size: 1.21428571em;
  color: #009dff;
  font-weight: normal;
  margin: 0 0 3px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  top: 0;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc h3:hover {
  color: #24abff !important;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments {
  font-size: 0.92857143em;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul {
  padding: 0;
  margin: 0 0 4px;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:last-child:before {
  display: none;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:before {
  display: block;
  content: '|';
  width: 13px;
  text-align: center;
  position: absolute;
  color: #06164a;
  top: 0;
  left: auto;
  right: -4px;
  background: none;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul a {
  display: block;
  padding-right: 9px;
  color: #06164a;
}
.widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul a:hover {
  color: #081d62;
}
.widget.block-content-widget {
  position: relative;
}
.widget.block-content-widget .block-links {
  position: absolute;
  top: 0;
  right: 0px;
}
.widget.block-content-widget h3 {
  font-size: 1.35714286em;
  color: #575757;
  font-weight: bold;
  margin: 0 0 20px;
  padding-right: 66px;
}
.widget.block-content-widget a {
  text-decoration: none;
}
.widget.block-content-widget p {
  font-size: 0.92857143em;
  line-height: 17px;
  color: #707070;
  width: 92%;
}
.widget.video-popup-widget a {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  text-decoration: none;
}
.widget.video-popup-widget a:before {
  content: '\f16a';
  font-family: 'FontAwesome';
  font-size: 4.71428571em;
  color: #ffffff;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.video-popup-widget a:hover:before {
  opacity: 1;
  font-size: 74px;
}
.widget.video-popup-widget a:hover h3 {
  background: #092170;
}
.widget.video-popup-widget a h3 {
  margin: 0;
  color: #ffffff;
  background: #06164a;
  padding: 18px 10px;
  font-size: 1.28571429em;
  text-align: left;
  -moz-transition: background 0.4s ease-in-out;
  -o-transition: background 0.4s ease-in-out;
  -webkit-transition: background 0.4s ease-in-out;
  transition: background 0.4s ease-in-out;
}
.widget.standard-page-banner .page-banner-inner .item {
  position: relative;
}
.widget.standard-page-banner .page-banner-inner .item .text-overlay {
  position: absolute;
  top: 50px;
  left: 0;
}
.widget.standard-page-banner .page-banner-inner .item .text-overlay h3 {
  background: #fff;
  color: #575757;
  font-size: 2.57142857em;
  display: inline-block;
  padding: 8px 25px;
  text-transform: lowercase;
}
.widget.standard-page-banner .page-banner-inner .item .text-overlay h3:first-letter {
  text-transform: uppercase;
}
.widget.standard-page-banner .page-banner-inner .item .banner-logo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.widget.standard-page-banner .page-banner-inner .item .banner-logo img {
  padding: 15px;
  background: #fff;
  display: inline-block;
}
.widget.standard-page-banner .page-banner-inner.owl-theme .owl-controls {
  margin: 0;
}
.widget.standard-page-banner .page-banner-inner .owl-buttons .owl-prev,
.widget.standard-page-banner .page-banner-inner .owl-buttons .owl-next {
  position: absolute;
  z-index: 8000;
  top: 50%;
  margin-top: -13px !important;
  opacity: 1 !important;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  background: url(app/partials/~@imgOld/tpa-site/svg/icon-arrow-circle.svg) no-repeat !important;
  transform: translateY(-50%);
}
.widget.standard-page-banner .page-banner-inner .owl-buttons .owl-prev {
  left: 30px;
}
.widget.standard-page-banner .page-banner-inner .owl-buttons .owl-next {
  right: 30px;
  transform: rotate(-180deg);
}
.widget.featured-products .featured-title h5 {
  float: left;
}
.widget.featured-products .featured-title a {
  float: right;
}
.widget.featured-products .featured-product {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  max-height: 263px;
  height: 263px;
}
.widget.featured-products .featured-product:hover .featured-text h6 {
  background: #ff7600;
}
.widget.featured-products .featured-product.tpa-style {
  max-height: 155px;
}
@media (max-width: 992px) {
  .widget.featured-products .featured-product.tpa-style {
    max-height: 200px;
  }
}
@media (max-width: 767px) {
  .widget.featured-products .featured-product.tpa-style {
    max-height: 1000px;
    height: auto;
  }
}
.widget.featured-products .featured-product.tpa-style img {
  width: 100%;
}
.widget.featured-products .featured-product .new {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  background: #009dff;
  width: 60px;
  height: 48px;
  text-align: center;
  font-size: 1em;
  padding-top: 15px;
}
.widget.featured-products .featured-product .featured-text {
  top: 100%;
  position: absolute;
  left: 0;
  background: rgba(6, 22, 74, 0.8);
  height: 100%;
  bottom: 0;
  -moz-transition: top 0.3s ease;
  -o-transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease;
  transition: top 0.3s ease;
  width: 100%;
}
.widget.featured-products .featured-product .featured-text a {
  display: block;
  text-decoration: none;
}
.widget.featured-products .featured-product .featured-text h6 {
  width: 100%;
  background: #06164a;
  padding: 12px 16px;
  height: 67px;
  color: #ffffff;
  margin: -67px 0 0 0;
  -moz-transition: margin 0.3s ease;
  -o-transition: margin 0.3s ease;
  -webkit-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
  line-height: 24px;
}
.widget.featured-products .featured-product .featured-text p {
  padding: 14px;
  color: #ffffff;
}
.widget.featured-products .featured-product__btn {
  width: 100%;
  height: 155px;
  border: 1px solid #575757;
  background: transparent;
  color: #06164a;
  border: background 0.3s ease;
}
.widget.featured-products .featured-product__btn:hover {
  background: #ff7600;
  color: #ffffff;
}
.widget.contact-widget h3 {
  font-size: 2em;
  color: #575757;
  font-weight: normal;
}
.widget.contact-widget label {
  font-size: 2.28571429em;
  padding-right: 5px;
  font-weight: normal;
  color: #06164a;
}
.widget.contact-widget a {
  font-size: 2.28571429em;
  font-weight: normal;
  color: #06164a;
  text-decoration: none;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget.contact-widget a:hover {
  color: #0a2479;
}
.widget.contact-widget .tel-contact {
  margin-bottom: 14px;
}
.widget.contact-widget .email-contact {
  margin: 18px 0 25px;
}
.widget.contact-widget .email-contact label {
  font-size: 1.14285714em;
  padding-right: 10px;
  font-weight: bold;
}
.widget.contact-widget .email-contact a {
  font-size: 1.14285714em;
  font-weight: bold;
}
.widget .icon-area {
  z-index: 50;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.widget .video .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/video-feature.png) no-repeat 0px 0px;
}
.widget .news .icon-area .icon-img,
.widget .article-item-wrap .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/news-feature.png) no-repeat 0px 0px;
}
.widget .casestudy .icon-area .icon-img {
  display: block;
  width: 34px;
  height: 34px;
  background: url(app/modules/~@imgOld/case-feature.png) no-repeat 0px 0px;
}
.blog-tags {
  position: relative;
  font-size: 0.92857143em;
  padding-left: 22px;
  clear: both;
}
.blog-tags:before {
  position: absolute;
  top: 0px;
  left: 0;
  display: block;
  content: '\f02b';
  font-family: 'FontAwesome';
  width: 18px;
  height: 18px;
  color: #009dff;
}
.blog-tags ul {
  padding: 0;
}
.blog-tags ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
}
.blog-tags ul li:last-child:before {
  display: none;
}
.blog-tags ul li:before {
  display: block;
  /*content: '|';*/
  width: 13px;
  text-align: center;
  position: absolute;
  color: #06164a;
  top: 0;
  right: -4px;
  background: none;
}
.blog-tags ul a {
  display: block;
  padding-right: 4px;
  color: #06164a;
}
.blog-tags ul a:hover {
  color: #081d62;
}
.case-box .blog-tags ul li:before {
  content: none;
}
.case-box .blog-tags ul li a {
  padding-right: 3px;
}
.media-hub .case-box .blog-top {
  padding-right: 65px;
}
.media-hub .case-box .blog-top h5 {
  padding-left: 53px;
}
.media-hub .case-box .blog-top img {
  position: absolute;
  left: -8px;
  top: -10px;
  width: 50px;
}
@media (min-width: 767px) {
  .everything-else .widget.featured-products .featured-product:hover .featured-text {
    top: 0;
  }
  .everything-else .widget.featured-products .featured-product:hover .featured-text h6 {
    margin: 0;
  }
}
.IE8 .widget.featured-products .featured-product .featured-text {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
}
.strip {
  background: #dfdfdf;
  margin-bottom: 4px;
  padding: 14px 10px 14px 42px;
}
.strip .strip-content {
  display: table;
  width: 100%;
}
.strip .strip-content .small-icon {
  display: table-cell;
  vertical-align: middle;
  width: 22px;
}
.strip .strip-content .strip-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.28571429em;
  color: #000;
  padding-left: 10px;
}
.IE8 .widget.article-widget .article-item-wrap .article-item .article-icon a:hover .transparent-overlay,
.IE8 .widget.blog-widget .blog-item-wrap .blog-item .blog-icon a:hover .transparent-overlay {
  background: transparent url(/sitefiles/images/opacity-40.png) repeat 0px 0px !important;
  opacity: 1 !important;
}
.IE8 .video-popup-widget.widget a:before {
  left: 50%;
  margin-left: -30px;
  right: auto;
}
@media (max-width: 1199px) {
  .widget.sign-post-widget {
    /*a:hover {
                .signpost .signpost-icon {
                    img {
                        left: 6px;
                        width: 55px;
                    }
                }
            }*/
  }
  .widget.sign-post-widget .signpost .signpost-icon {
    width: 45px;
  }
  .widget.sign-post-widget .signpost .signpost-icon img {
    left: 7px;
  }
  .widget.standard-page-banner .page-banner-inner .item .text-overlay {
    top: 0;
  }
  .widget.standard-page-banner .page-banner-inner .item .banner-logo img {
    max-width: 170px;
  }
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: none;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: block;
  }
}
@media (max-width: 991px) {
  .widget.standard-page-banner .page-banner-inner .item .text-overlay h3 {
    font-size: 1.4em;
    padding: 15px;
    margin: 0;
  }
  .widget.map-filter ul li a {
    padding: 7px 30px 5px 49px;
  }
  .widget.contact-widget label {
    font-size: 1.85714286em;
  }
  .widget.contact-widget a {
    font-size: 1.85714286em;
  }
  .widget.contact-widget .email-contact {
    margin: 13px 0 21px;
    font-size: 0.85714286em;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    display: none;
  }
  .widget.blog-widget h3 {
    margin: 0;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .widget.side-nav-widget {
    display: none;
  }
  .widget.map-filter {
    display: block;
  }
  .widget.advertise-widget {
    text-align: center;
  }
  .widget.map-filter ul li a {
    padding: 10px 30px 9px 49px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    width: 105px;
  }
  .widget.blog-widget h3 {
    margin: 0 0 20px;
  }
  .striped-bg-faint-line .strip {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: block;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: none;
  }
}
@media (max-width: 480px) {
  .widget.standard-page-banner .page-banner-inner .item .text-overlay {
    position: static;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments {
    font-size: 0.85714286em;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li:before {
    display: block;
    content: '|';
    width: 9px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc .comments ul li a {
    padding-right: 6px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-icon {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 91px;
  }
  .widget.blog-widget .blog-item-wrap .blog-item .blog-desc {
    padding-left: 15px;
  }
}
@media (max-width: 575px) {
  .widget.enquiry-basket-guide.side-nav-widget.letterbox {
    display: none;
  }
  .widget.enquiry-basket-guide.side-nav-widget.compact {
    display: block;
  }
}
/* Widget Keyframes */
@-moz-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@-ms-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@-webkit-keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
@keyframes wigglearrow {
  0% {
    left: 20px;
  }
  25% {
    left: 17px;
  }
  50% {
    left: 20px;
  }
  75% {
    left: 17px;
  }
  100% {
    left: 20px;
  }
}
.large-cta {
  position: relative;
  background-color: #009dff;
}
.large-cta.mid-colour {
  background-color: #26358c;
}
.large-cta.dark-colour {
  background-color: #06164a;
}
.large-cta.red-colour {
  background-color: #e94d3a;
}
.large-cta:hover .large-cta__angle-middle {
  background-color: #ff7600;
}
@media (min-width: 992px) {
  .large-cta:hover .large-cta__angle-top {
    left: 78%;
  }
}
@media (min-width: 992px) {
  .large-cta:hover .large-cta__content-inner {
    right: 60px;
  }
}
.large-cta:hover .jilted-title {
  color: #009dff;
  background-color: #ffffff;
  padding-right: 30px;
}
.large-cta:hover.mid-colour .jilted-title {
  color: #26358c;
}
.large-cta:hover.dark-colour .jilted-title {
  color: #06164a;
}
.large-cta .widget.sign-post-widget {
  text-align: right;
  padding: 25px 10px;
}
.large-cta__holder {
  position: relative;
  margin-top: 30px;
}
.large-cta__holder:before {
  content: '';
  position: absolute;
  top: -12px;
  left: 0;
  height: 23px;
  width: 100%;
  background-image: url('/SiteFiles/Images/blue-stripes.png');
  background-repeat: repeat-x;
}
.large-cta__inner {
  overflow: hidden;
  max-height: 289px;
}
@media (max-width: 767px) {
  .large-cta__inner {
    height: auto;
    max-height: 1000px;
  }
}
.large-cta__media-area {
  transition: transform 0.5s;
  position: relative;
  display: inline-block;
  transform: translateX(-150%);
}
.is-active .large-cta__media-area {
  transform: translateX(0);
}
@media (max-width: 991px) {
  .is-active .large-cta__media-area {
    transform: translateX(-33%);
  }
}
@media (max-width: 767px) {
  .is-active .large-cta__media-area {
    transform: translateX(0);
    overflow: hidden;
  }
}
.large-cta__angle-middle {
  transition: background-color 0.5s;
  display: block;
  background-color: #ffffff;
  position: absolute;
  top: -140px;
  right: 40px;
  transform: rotate(40deg);
  width: 100px;
  height: 550px;
}
@media (max-width: 991px) {
  .large-cta__angle-middle {
    right: 90px;
    width: 30px;
  }
}
@media (max-width: 767px) {
  .large-cta__angle-middle {
    display: none;
  }
}
.large-cta__angle-top {
  transition: left 0.5s;
  display: block;
  width: 300px;
  height: 741px;
  background-color: #009dff;
  position: absolute;
  top: -220px;
  left: 98%;
  transform: rotate(40deg);
  z-index: 2;
}
@media (max-width: 991px) {
  .large-cta__angle-top {
    left: 85%;
  }
}
@media (max-width: 767px) {
  .large-cta__angle-top {
    display: none;
  }
}
.mid-colour .large-cta__angle-top {
  background-color: #26358c;
}
.dark-colour .large-cta__angle-top {
  background-color: #06164a;
}
.large-cta__content {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  max-width: 1170px;
  height: 289px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .large-cta__content {
    padding: 20px 10px;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    transform: translateX(0);
  }
}
.large-cta__content .jilted-title {
  transition: all 0.5s;
  color: #ffffff;
  background-color: transparent;
  padding: 10px 15px;
  margin-left: -15px;
  margin-bottom: 5px;
}
.large-cta__content-inner {
  transition: right 0.5s;
  width: 60%;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .large-cta__content-inner {
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    transform: translateY(0);
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .large-cta__content-inner {
    width: 55%;
  }
}
@media (min-width: 901px) and (max-width: 991px) {
  .large-cta__content-inner {
    width: 60%;
  }
}
@media (min-width: 992px) and (max-width: 1140px) {
  .large-cta__content-inner {
    width: 40%;
  }
}
@media (min-width: 1141px) and (max-width: 1310px) {
  .large-cta__content-inner {
    width: 50%;
  }
}
.large-cta__content-inner h3 {
  font-size: 2em;
  color: #ffffff;
}
.large-cta__content-inner p {
  font-size: 1.28571429em;
  color: #ffffff;
}
.large-cta__content-inner .btn--transparent {
  font-size: 1.14285714em;
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.large-cta__content-inner .btn--transparent:hover {
  background-color: #ff7600;
  border-color: #ff7600;
}
.FRFR .tech-area a {
  font-size: 11px;
  line-height: 44px;
}
.download-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.download-panel__close-button {
  position: absolute;
  top: 10px;
  right: 15px;
}
.download-panel__close-icon {
  font-size: 20px;
  cursor: pointer;
}
.download-panel .form-box {
  position: relative;
  min-width: 320px;
  max-width: 800px;
  max-height: 80vh;
}
@media (max-width: 991px) {
  .download-panel .form-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: 0 auto 20px;
  }
}
.download-panel .form-box input[type="submit"] {
  float: none;
  display: block;
  width: 100%;
}
