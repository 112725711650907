@import '../partials/_mixins';
@import '../partials/_variables';

.chosen-container {
    height: 54px;
    width: 100%!important;
    background: #fff;
    margin-bottom: 30px;
    .font-size(14);

    .chosen-single {
        border: none;
        -moz-border-radius-bottomright: 0;
        border-bottom-right-radius: 0;
        -moz-border-radius-bottomleft: 0;
        border-bottom-left-radius: 0;
        background: #fff;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 54px;

        div {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 48px;
            height: 100%;
            padding: 14px 10px;

            b {
                display: block;
                width: 26px;
                height: 26px;
                background: @brand-primary url(@sprite-path) no-repeat -38px -48px;
            }
        }

        span {
            display: block;
            height: 54px;
            line-height: 54px;
            color: lighten(@brand-grey, 14%);
            padding-left: 7px;
            padding-right: 40px;
        }
    }

    .chosen-drop {
        border: none;
    }

    .chosen-results li {
        height: 42px;
        line-height: 17px;
        background: #fff;
        color: lighten(@brand-grey, 14%);
        padding-left: 12px;
        padding-right: 40px;

        &:before {
            display: none;
        }

        &.highlighted {
            background: @brand-primary;
        }
    }
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -38px -48px;
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.chosen-container-active.chosen-with-drop .chosen-single {
    border: none;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
