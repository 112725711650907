.owl-carousel {
  &--default {
    .share-carousel-style-btn();
  }
}
.owl-carousel--default .owl-buttons .owl-next, .owl-carousel--default .owl-buttons .owl-prev {
  margin-top: 0 !important;
}
.owl-carousel--default .owl-buttons .owl-next {
  transform: rotate(-180deg) translateY(50%);
}

.owl-carousel .owl-buttons .owl-next,
.owl-carousel .owl-buttons .owl-prev {
  position: absolute;
  z-index: 8000;
  top: 50%;
  margin-top: -13px!important;
  opacity: 1!important;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
  // background: url('/SiteFiles/Images/tpa-site/svg/icon-arrow-circle.svg') no-repeat!important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.owl-carousel .owl-buttons .owl-next {
  right: 30px;
}

.owl-carousel .owl-buttons .owl-prev {
  left: 30px;
}