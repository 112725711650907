@import '../partials/_mixins';
@import '../partials/_variables';

.primary-header {
  position: relative;

  &--padded {
    margin-bottom: 56px;
  }
}

.HomePage header {
  opacity: 0;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  display: block!important;
  top: 0;
}

header {
  background: @brand-white;
  /*border: 1px solid #f3f3f3;*/
  position: absolute;
  z-index: 3000;
  width: 100%;
  left: 0;

  .header__main {
    display: flex;

    .depot-area--btn {
      padding: 12px 25px 12px 15px;
      border: 1px solid @brand-primary;
      color: @brand-primary;
      display: inline-block;

      .depot-icon {
        margin-right: 10px;
      }

      .fa {
        .font-size(18);
      }
    }

    .search-area {
      display: flex;
      align-self: center;
      position: relative;
      margin-left: 20px;
    }

    .search-icon {
      .fa {
        .font-size(22);
        color: @brand-white;        
      }
    }

    .drop-search {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      overflow: hidden;
    }
    
  }

  .header__social-area {
      list-style: none;
      display: flex;
      align-self: center;
      margin: 0 25px 0 0;

      @media (max-width: 767px) {
        padding-left: 0;
      }

      li {
        padding: 0 14px;
      }

      .fa {
        .font-size(20);
        color: @brand-primary;        
      }
    }

  .header__main-left {
    flex: 0 0 auto;
  }

  .header__main-right {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    flex: 1 0 auto;
  }

  &.tpa-style {
    position: relative;
    left: auto;

    @media (max-width: 767px) {
      padding-top: 1px;
    }

    .logged-in {
      text-align: right;
      padding-top: 10px;
    }
  }

  &.show-number {
    .top-links .right-links .header-numbers {
      display: block;
    }
  }

  .desktop-nav {
    padding: 0 18px;
    position: relative;
    background-color: @brand-secondary;

    nav {
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;

          // &.home {
          //   a {
          //     background: url(/sitefiles/Images/nav-lines.png) repeat 0px 0px;
          //   }
          // }

          .drop {
            z-index: 10;
            opacity: 0;
            display: none;
            position: absolute;
            top: 67px;
            padding: 70px 60px;
            left: 0;
            background: #fff;
            width: 100%;
            border-top:5px solid @brand-tertiary;
            border-left: 1px solid @brand-light-grey;
            border-right: 1px solid @brand-light-grey;
            border-bottom: 1px solid @brand-light-grey;

            .dropdown-logo {
              margin-top: 15px;
            }

            ul {
              li {
                display: block;

                a {
                  padding: 8px 0;
                  -moz-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  -webkit-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out;
                  position: relative;
                  color: @brand-primary;
                  height: auto;
                  line-height: 1;

                  &:before {
                    position: absolute;
                    top: 4px;
                    left: -9px;
                    display: block;
                    content: ' ';
                    width: 2px;
                    height: 0;
                    background: @brand-primary;
                    -moz-transition: all 0.5s ease-in-out;
                    -o-transition: all 0.5s ease-in-out;
                    -webkit-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                  }

                  &:hover {
                    background: none;
                    color: @brand-secondary;

                    &:before {
                      height: 27px;
                    }
                  }
                }
              }
            }

            h3 {
              .font-size(18);
              margin-top: 0;
              margin-bottom: 20px;
              color: @brand-primary;
            }
          }

          &.drop-open {
            > a {
              // color: darken(@base-font-color, 10%);
              // background: url(/sitefiles/Images/nav-lines.png) repeat 0px 0px;
              background-color: @brand-tertiary;

              &:hover {
                -moz-animation: none;
                -o-animation: none;
                -webkit-animation: none;
                animation: none;
              }
            }
          }

          a {
            color: @brand-white;
            display: block;
            .font-size(16);
            text-decoration: none;
            padding: 20px 20px;
            height: 67px;
            line-height: 30px;

            &:hover,
            &.active {
              background-color: @brand-tertiary;
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              -webkit-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
            }
          }

          .home-icon {
            .font-size(22);
          }
        }
      }
    }
  }

  .top-links {
    min-height: 46px;
    line-height: 46px;
    padding: 0 0 0 18px;
    background-color: @brand-primary;    

    .top-links__inner {
      display: flex;

      a {
        color: @brand-white;
      }
    }

    .left-links {
      flex: 1 0 auto;
      display: inline-block;

      .menu-btn {
        display: none;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          list-style-type: none;
          padding-right: 15px;

          a {
            color: @brand-white;
            text-decoration: none;
            display: inline-block;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            position: relative;

            &:before {
              content: ' ';
              display: block;
              height: 2px;
              width: 0;
              background: @brand-white;
              position: absolute;
              bottom: 10px;
              left: 1px;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              text-decoration: none;
              color: @brand-white;

              &:before {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .center-area {
      flex: 1 0 auto;

      .center-area__inner {
        .font-size(22);
        text-align: center;
        color: @brand-white;

        @media (max-width: 991px) {
          .font-size(18);
          margin: 0 5px;
        }
      }

      .fa-phone {
        font-size: 19px;
        margin-right: 3px;
      }
    }

    .right-links {
      // float: right;
      display: inline-block;
      position: relative;

      .header-numbers {
        position: absolute;
        top: 83px;
        right: 20px;
        text-align: right;
        display: none;

        a {
          display: inline-block;
          min-height: 0;
          line-height: normal;
          padding: 0;
          margin: 0;
          text-decoration: none;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          position: relative;

          &:before {
            content: ' ';
            display: block;
            height: 2px;
            width: 0;
            background: @brand-primary;
            position: absolute;
            bottom: 0px;
            left: 1px;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }

          &.telNo {
            .font-size(36);
            line-height: 100%;

            &:hover {
              text-decoration: none;
              color: @brand-primary;

              &:before {
                width: 100%;
              }
            }
          }
        }

        .help {
          margin-top: -15px;

          a {
            display: inline-block;
            background: #ebebeb;
            padding: 3px 6px;
            color: @font-grey;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            span {
              font-weight: bold;
              color: @brand-primary;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              background: @font-grey;
              color: #fff;

              span {
                color: #fff;
              }
            }
          }
        }
      }

      .icon-list {
        // float: right;
        display: inline-block;
      }

      .twitter-typeahead {
        float: left;
        width: 100%;
        background-color: @brand-white;
      }

      .search-area {
        // float: left;
        // top: 0px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        // background: @brand-grey;
        color: @brand-white;
        padding: 0 10px 0 30px;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        .drop-search {
          display: none;
          position: absolute;
          top: 46px;
          right: 0;
          width: 300px;
          /*border: 1px solid #fff;*/
          padding: 1px;
          z-index: 10;

          input[type='search'] {
            width: 100%;
            height: 46px;
            line-height: 20px;
            border: 1px solid #767676;
            margin: 0;
            padding: 10px;
            color: #a0a0a0;
            outline: none;
            .font-size(18);
            background: #fff;
          }
        }

        .search-icon {
          // position: absolute;
          // left: 13px;
          // top: 0;
          .font-size(18);
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        &:hover {
          background: #767676;
          top: 0px;
        }
      }

      .logged-in {
        display: inline-block;
        z-index: 1;
        // float: right;
        // clear: both;
        line-height: 1.5em;
        text-align: right;
        // position: absolute;
        // right: 20px;
        // width: 400px;
        // top: 57px;

        .login-icon {
          margin-right: 10px;
        }

        .links {
          color: @brand-white;
        }

        a {
          color: @brand-white;
        }
      }

      .login-area {
        // float: left;
        cursor: pointer;
        position: relative;
        top: 0;
        display: inline-block;
        background: @brand-primary;
        color: @brand-white;
        padding: 0 10px 0 30px;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        .inner-border {
          border: 1px solid @brand-primary;
          padding: 20px;
          padding-bottom: 6px;
          background-color: #fff;
          z-index: 15;
        }

        .LoginPanelPasswordRetrieval {
          span {
            width: 100%;
            display: block;
            line-height: 1.3em;
            margin-top: 10px;
            color: #a94442;
            background-color: #f2dede;
            border-color: #ebccd1;
            padding: 15px;
            margin-bottom: 20px;
            border: 1px solid transparent;
            border-radius: 4px;
          }

          .form-control-error {
            background-color: transparent;
            padding: 0px;
          }
        }

        .drop-login {
          display: none;
          width: 272px;
          z-index: 14;
          position: absolute;
          background: #fff;
          right: 0;
          border-top: 1px solid #fff;
          top: 46px;

          .register-btn {
            display: block;
            position: relative;
            width: 50%;
            float: left;
            text-align: center;
            .font-size(20);
            color: @brand-primary;
            text-decoration: none;

            &:before {
              display: block;
              content: ' ';
              width: 63%;
              height: 2px;
              background: @brand-primary;
              position: absolute;
              z-index: 15;
              bottom: 16px;
              opacity: 0;
              left: 0px;
              right: 0;
              margin: 0 auto;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              &:before {
                opacity: 1;
                bottom: 5px;
              }
            }
          }

          .login-btn {
            position: relative;
            width: 50%;
            float: left;
            background: @brand-primary;
            color: #fff;
            .font-size(20);
            padding: 0 15px;
            text-decoration: none;
            display: block;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            &:hover {
              background: fadeout(@brand-primary, 20%);
              -moz-border-radius: 10px;
              -webkit-border-radius: 10px;
              border-radius: 10px;
            }

            &:before {
              display: block;
              content: ' ';
              background: url(@sprite-path) no-repeat 0px -52px;
              position: absolute;
              top: 13px;
              right: 13px;
              width: 30px;
              height: 19px;
            }
          }

          .forgot-password {
            color: @brand-primary;
            .font-size(15);
            text-decoration: none;
            text-align: right;
            display: block;
            width: 100%;

            &:hover {
              text-decoration: underline;
            }
          }

          .input-box {
            position: relative;

            &.user {
              &:before {
                position: absolute;
                top: 0;
                .font-size(20);
                right: 14px;
                display: block;
                font-family: 'FontAwesome';
                content: '\f007';
                color: @brand-grey;
                cursor: default;
              }
            }

            &.password {
              &:before {
                position: absolute;
                top: 0;
                .font-size(20);
                right: 14px;
                display: block;
                font-family: 'FontAwesome';
                content: '\f023';
                color: @brand-grey;
                cursor: default;
              }
            }

            input {
              .font-size(15);
              outline: none;
              height: 46px;
              border: 1px solid @brand-primary;
              width: 100%;
              margin-bottom: 20px;
              padding: 0 44px 0 15px;
            }
          }

          .alert {
            background: none;
            padding: 0;
            border: none;
            margin: 0;
            position: relative;
            top: -9px;
            color: #c70704;
          }

          .LoginPanelPasswordRetrieval {
            .btn,
            button,
            input[type='button'],
            input[type='submit'] {
              display: inline-block;
              position: relative;
              margin: 0;
              border: 0;
              padding: 12px 50px 12px 17px;
              margin-bottom: 15px;
            }

            input[type='submit'] {
              background-position: center right;
            }

            span {
              background: none;
              padding: 0;
              border: none;
              margin: 0;
              position: relative;
              top: 7px;
              color: #c70704;
            }
          }
        }

        .login-icon {
          position: absolute;
          top: 0;
          left: 10px;
          .font-size(15);
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        .checkbox {
          input[type=checkbox] {
            display: inline-block;
            vertical-align: middle;
            float: none;
            margin-top: -1px;
          }

          label {
            color: @brand-primary;
            vertical-align: middle;
          }
        }

        &:hover {
          background: fadeout(@brand-primary, 20%);
        }
      }

      .tech-area {
        // float: left;
        top: 0;
        cursor: pointer;
        position: relative;
        display: inline-block;
        // background: @brand-secondary;
        color: @brand-white;
        padding: 0 10px 0 42px;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        .fa,
        a {
          color: @brand-white;
        }

        .tech-icon {
          position: absolute;
          top: 0;
          left: 14px;
          .font-size(19);
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }

        &:hover {
          background: fadeout(@brand-secondary, 20%);
        }
      }

      .ipad-telno {
        display: none;
      }

      .country-area {
        // float: left;
        display: inline-block;
        position: relative;
        margin-right: 20px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: @brand-white;
        cursor: pointer;

        &:hover {
          background-color: @brand-secondary;
        }

        .other-coutries {
          display: none;
          width: 65px;
          padding-bottom: 14px;
          position: absolute;
          left: -10px;
          top: 36px;
          z-index: 10;
          background: @brand-primary;

          a {
            padding-left: 10px;
            display: block;
            color: @brand-white;
            height: 30px;
            text-decoration: none;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            padding-left: 10px;

            &:hover {
              color: lighten(@brand-white, 46%);
            }
          }
        }

        a {
          padding: 0 5px;
          color: @brand-white;

          &:hover {
            color: @brand-white;
          }

          margin-left: 0px;
        }

        .country-icon {
          .font-size(19);
          padding-right: 1px;
          width: 15px;
          height: 15px;
          background-image: url('/Sitefiles/Images/svg/globe.svg');
          background-size: 15px 15px;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          margin-top: -3px;

          i {
            display: none;
          }
        }
      }

      .basket-area {
        // float: left;
        display: inline-block;
        margin-right: 20px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: @brand-white;
        cursor: pointer;

        &:hover {
          background-color: @brand-secondary;
        }

        a {
          padding: 0 5px;
          color: @brand-white;

          &:hover {
            color: @brand-white;
          }
        }

        .basket-icon {
          .font-size(18);
          padding-right: 6px;
        }
      }

      .depot-area {
        // float: left;
        display: none;
        margin-right: 20px;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: @brand-secondary;
        }

        a {
          padding: 0 5px;
          color: @brand-white;
          text-decoration: none;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: @brand-white;
          }
        }

        .depot-icon {
          .font-size(18);
          padding-right: 1px;
          // margin-right: 10px;
        }
      }

      .mail-area {
        // float: left;
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: @brand-secondary;
        }

        a {
          padding: 0 5px;
          color: @brand-white;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          text-decoration: none;

          &:hover {
            color: @brand-white;
          }
        }

        .mail-icon {
          .font-size(14);
          padding-right: 6px;
        }
      }
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  .logo {
    margin: 32px 0 28px 18px;

    // img {
    //   height: 50px;
    //   width: 235px;
    // }
  }
}

.IE8 header .desktop-nav nav ul li {
  float: left;

  ul li {
    float: none;
  }
}

hr,
.grey-bar {
  display: block;
  position: relative;
  height: 8px;
  width: 0px;
  -moz-transition: width 2.5s ease-in-out;
  -o-transition: width 2.5s ease-in-out;
  -webkit-transition: width 2.5s ease-in-out;
  transition: width 2.5s ease-in-out;
  background: @brand-light-grey;

  &.animate {
    width: 100%;
  }
}

hr {
  width: 100% !important;
}

@media (max-width: 1199px) {
  header .desktop-nav nav ul li a {
    padding: 18px 7px;
  }

  header .desktop-nav nav ul li .drop {
    padding: 45px 60px;
  }
}

@media (max-width: 991px) {
  header.show-number {
    .top-links .right-links .header-numbers {
      display: none;
    }
  }

  header .top-links .right-links .logged-in {
    top: 90px;
  }

  .IE8 header .desktop-nav nav ul li {
    float: none;
  }

  .grey-bar {
    width: 100%;
  }

  header {
    position: relative;
    top: 0;
    opacity: 1;

    .top-links .right-links {
      .login-area,
      .ipad-search {
        opacity: 1;
      }

      div span {
        opacity: 1;
      }
    }

    // .top-links .right-links .ipad-telno {
    //   float: left;
    //   display: block;
    //   color: #fff;
    //   border-left: 2px solid #a4b970;
    //   height: 100%;
    //   padding: 0 20px;
    //   margin: 0;

    //   &:hover {
    //     background: #a4b970;
    //   }

    //   .tel-icon {
    //     padding-right: 0px;
    //     color: #fff;
    //     .font-size(36);
    //   }
    // }

    .top-links .left-links .menu-btn {
      opacity: 1;
    }

    .desktop-nav {
      display: none;
      padding: 0;
      background: #f2f2f2;
      width: 35%;

      .container {
        width: 100%;
        padding: 0;
      }

      nav {
        ul {
          li {
            display: block;
            margin: 0;

            &.drop-open {
              a {
                background: @brand-primary;
                color: #fff;
              }

              ul li a {
                .font-size(19);
                background: none;
                color: @brand-grey;

                
              }
            }

            a {
              padding-left: 20px;
              color: @brand-primary;
              .font-size(20);

              &.active {
                color: #fff;
              }

              &:hover {
                background: @brand-primary;
                color: #fff;
              }
            }

            .drop {
              border: none;
              position: absolute;
              top: 0;
              padding: 25px;
              left: 100%;

              p {
                .font-size(15);
              }

              h3 {
                margin: 15px 0;
                .font-size(20);
                color: @brand-primary;
              }
            }
          }
        }
      }
    }

    .top-links {
      background: @brand-primary;
      height: 70px;
      line-height: 70px;
      padding-left: 0;

      .left-links {
        ul {
          display: none;
        }

        .menu-btn {
          -moz-border-radius: 8px;
          -webkit-border-radius: 8px;
          border-radius: 8px;
          margin-top: 12px;
          display: block;
          width: 138px;
          height: 46px;
          background: #fff;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          -moz-transition: opacity 0.25s ease-in-out;
          -o-transition: opacity 0.25s ease-in-out;
          -webkit-transition: opacity 0.25s ease-in-out;
          transition: opacity 0.25s ease-in-out;

          &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            width: 0;
            height: 100%;
            background: @brand-orange;
            -moz-transition: width 0.6s ease-in-out;
            -o-transition: width 0.6s ease-in-out;
            -webkit-transition: width 0.6s ease-in-out;
            transition: width 0.6s ease-in-out;
          }

          .menu-icon {
            position: absolute;
            z-index: 11;
            top: 3px;
            left: 3px;
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            border-radius: 8px;
            height: 40px;
            width: 50px;
            background: @brand-primary;
            display: block;
            padding: 4px 9px;
            -moz-transition: left 0.6s ease-in-out;
            -o-transition: left 0.6s ease-in-out;
            -webkit-transition: left 0.6s ease-in-out;
            transition: left 0.6s ease-in-out;

            .line {
              width: 100%;
              height: 4px;
              background: #fff;
              display: block;
              margin: 3px 0;

              &.one {
                top: 0;
                position: relative;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
              }

              &.two {
                opacity: 1;
                top: 0;
                position: relative;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
              }

              &.three {
                opacity: 1;
                top: 0;
                position: relative;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
              }

              &.four {
                position: relative;
                -moz-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
              }
            }
          }

          .closed {
            position: absolute;
            height: 46px;
            line-height: normal;
            top: 9px;
            right: 16px;
            .font-size(22);
            color: #999999;
            -moz-transition: right 0.6s ease-in-out;
            -o-transition: right 0.6s ease-in-out;
            -webkit-transition: right 0.6s ease-in-out;
            transition: right 0.6s ease-in-out;
          }

          .opened {
            position: absolute;
            height: 46px;
            line-height: normal;
            top: 9px;
            left: -63px;
            .font-size(22);
            color: #fff;
            -moz-transition: left 0.6s ease-in-out;
            -o-transition: left 0.6s ease-in-out;
            -webkit-transition: left 0.6s ease-in-out;
            transition: left 0.6s ease-in-out;
          }

          &.menu-open {
            &:before {
              width: 100%;
            }

            .menu-icon {
              left: 85px;

              .line {
                &.one {
                  -moz-transform: translateY(-10px) rotate(45deg);
                  -ms-transform: translateY(-10px) rotate(45deg);
                  -o-transform: translateY(-10px) rotate(45deg);
                  -webkit-transform: translateY(-10px) rotate(45deg);
                  transform: translateY(-10px) rotate(45deg);
                  top: 21px;
                }

                &.two {
                  opacity: 0;
                  -moz-transform: translateY(-10px) rotate(-45deg);
                  -ms-transform: translateY(-10px) rotate(-45deg);
                  -o-transform: translateY(-10px) rotate(-45deg);
                  -webkit-transform: translateY(-10px) rotate(-45deg);
                  transform: translateY(-10px) rotate(-45deg);
                  top: 14px;
                }

                &.three {
                  opacity: 0;
                  -moz-transform: translateY(-10px) rotate(45deg);
                  -ms-transform: translateY(-10px) rotate(45deg);
                  -o-transform: translateY(-10px) rotate(45deg);
                  -webkit-transform: translateY(-10px) rotate(45deg);
                  transform: translateY(-10px) rotate(45deg);
                  top: 8px;
                }

                &.four {
                  -moz-transform: translateY(-10px) rotate(-45deg);
                  -ms-transform: translateY(-10px) rotate(-45deg);
                  -o-transform: translateY(-10px) rotate(-45deg);
                  -webkit-transform: translateY(-10px) rotate(-45deg);
                  transform: translateY(-10px) rotate(-45deg);
                }
              }
            }

            .closed {
              right: -65px;
            }

            .opened {
              left: 15px;
            }
          }
        }
      }

      .right-links {
        height: 70px;
        display: flex;
        align-items: center;

        .icon-list {
          height: 70px;
          display: flex;
          margin: 0 5px;

          a {
            text-decoration: none;
            color: #fff;

            &:hover {
              color: #fff;
            }
          }
        }

        div span {
          -moz-transition: opacity 1s ease-in-out;
          -o-transition: opacity 1s ease-in-out;
          -webkit-transition: opacity 1s ease-in-out;
          transition: opacity 1s ease-in-out;
          text-align: center;
        }

        .ipad-search {
          height: 100%;
          position: relative;
          float: left;
          display: inline-block;
          margin: 0;
          cursor: pointer;
          color: #fff;
          border-left: 2px solid @brand-orange;
          -moz-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;

          &:hover {
            background: @brand-orange;
          }

          &.active {
            background: @brand-orange;
          }

          .search-icon {
            padding: 0 14px;
            color: #fff;
            display: block;
            .font-size(20);
          }

          .drop-search {
            display: none;
            position: absolute;
            top: 69px;
            height: 68px;
            .font-size(17);
            left: 0;
            padding: 10px;
            background: @brand-orange;
            line-height: normal;

            input[type='search'] {
              outline: none;
              padding: 10px;
              height: 48px;
              border: none;
            }
          }
        }

        .search-area {
          display: none;
        }

        .tech-area {
          display: none;
          color: #fff;
          height: 100%;

          .tech-icon {
            color: #fff;
            .font-size(36);
          }
        }

        .login-area {
          -moz-transition: opacity 1.2s ease-in-out;
          -o-transition: opacity 1.2s ease-in-out;
          -webkit-transition: opacity 1.2s ease-in-out;
          transition: opacity 1.2s ease-in-out;
          color: @brand-white;
          padding: 0;
          height: 100%;
          .font-size(14);

          .login-click {
            padding: 0 16px 0 48px;
          }

          &:hover {
            background: @brand-orange;

            .login-icon {
              .font-size(18);
              // left: 13px;
            }
          }

          .login-icon {
            color: @brand-white;
            .font-size(18);
          }
        }

        .country-area {
          color: #fff !important;
          border-left: 2px solid @brand-orange;
          border-right: 2px solid @brand-orange;
          height: 100%;
          padding: 0 15px 0 6px;
          margin: 0;
          .font-size(14);

          .other-coutries {
            top: 70px;
            width: 100%;
            background: @brand-orange;
            z-index: 21;

            a {
              height: 59px;
              padding-left: 8px;
            }
          }

          &:hover {
            background: @brand-orange;
          }

          .country-icon {
            padding-right: 8px;
            color: #fff;
            .font-size(15);
          }

          .country {
            position: relative;
            // top: -5px;
          }
        }

        .basket-area {
          color: #fff !important;
          border-left: 2px solid @brand-orange;
          height: 100%;
          padding: 0 10px;
          margin: 0;
          .font-size(14);

          &:hover {
            background: @brand-orange;
          }

          .basket-icon {
            color: #fff;
            padding-right: 8px;
            font-size: 22px;
          }

          .number {
            position: relative;
            // top: -3px;
          }
        }

        .depot-area {
          color: #fff;
          border-left: 2px solid @brand-orange;
          height: 100%;
          padding: 0 20px;
          margin: 0;

          &:hover {
            background: @brand-orange;
          }

          .depot-icon {
            padding-right: 0px;
            color: #fff;
            .font-size(18);
          }
        }

        .mail-area {
          color: #fff;
          border-left: 2px solid @brand-orange;
          height: 100%;
          padding: 0 10px;
          margin: 0;

          &:hover {
            background: @brand-orange;
          }

          .mail-icon {
            color: #fff;
            .font-size(20);
            padding-right: 0;
          }
        }

        .links {
          .font-size(14);
        }
      }
    }
  }

  .HomePage {
    header {
      .top-links .right-links {
        .login-area,
        .ipad-search {
          opacity: 0;
        }

        div span {
          opacity: 0;
        }
      }

      .top-links .left-links .menu-btn {
        opacity: 0;
      }

      &.fade-in {
        .top-links .right-links {
          .login-area,
          .ipad-search {
            opacity: 1;
          }

          div span {
            opacity: 1;
          }
        }

        .top-links .left-links .menu-btn {
          opacity: 1;
        }
      }
    }
  }

  header .top-links .right-links .login-area {
    -moz-transition: background 0.4s ease-in-out;
    -o-transition: background 0.4s ease-in-out;
    -webkit-transition: background 0.4s ease-in-out;
    transition: background 0.4s ease-in-out;

    &.active {
      background: @brand-orange;
    }

    .drop-login {
      line-height: normal;
      font-size: 14px;
      top: 70px;

      .login-btn {
        padding: 8px 15px;
      }

      .register-btn {
        padding: 8px 15px;
      }

      .input-box:before {
        top: 12px !important;
      }

      .forgot-password {
        margin-top: 10px;
      }
    }
  }

  header .desktop-nav nav ul li .drop .dropdown-logo {
    margin-top: 0px;
  }
}

@media (max-width: 850px) {
  header .top-links .right-links {
    .ipad-search,
    .depot-area,
    .basket-area,
    .country-area {
      padding: 0 10px;
    }

    .mail-area {
      padding: 0 0px 0 10px;
    }
  }

  header .top-links .right-links .country-area .other-coutries a {
    height: 50px;
    padding-left: 7px;
  }

  header .top-links .right-links .ipad-telno {
    padding: 0 16px;
  }

  header .top-links .right-links .ipad-search .search-icon {
    padding: 0 6px;
  }
}

@media (max-width: 767px) {
  header .top-links .right-links .ipad-telno {
    border: none;
    padding: 0 16px;
    float: none;
    display: table-cell;
    vertical-align: middle;
  }

  header .top-links .right-links .logged-in {
    top: 0px;
    padding: 18px 0px;
    position: relative;
    .font-size(16);
  }

  .back-to-desktop {
    display: none;
  }

  .grey-bar {
    display: none !important;
  }

  header {
    position: static;

    .logo {
      position: absolute;
      margin: 12px 0 13px 18px;
      top: 0px;
      left: 108px;
      padding-right: 25px;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .moved-nav {
      position: absolute;
      background: #f2f2f2;
      height: 100%;
      z-index: 3000;
      top: 0;
      left: -350px;
    }

    .desktop-nav {
      width: 350px;
      display: block !important;

      nav ul li {
        .drop {
          border: none;
          position: relative;
          top: 0;
          width: 100% !important;
          padding: 15px 5px;
          left: 0;

          h3,
          p {
            display: none;
          }
        }
      }
    }

    .top-links {
      margin-top: 100px;
      padding: 0;
      line-height: normal;

      .left-links {
        .menu-btn {
          opacity: 1;
          margin-top: 0;
          position: absolute;
          top: 0;
          left: 0;
          background: #dadada;
          border-radius: 0;
          height: 100px;
          width: 100px;

          &.menu-open {
            &:before {
              display: none;
            }

            .menu-icon {
              left: 0;

              .line {
                &.one {
                  top: 27px;
                  left: 18px;
                }

                &.two {
                  top: 14px;
                  left: 16px;
                }

                &.three {
                  top: 3px;
                  left: 18px;
                }

                &.four {
                  left: 17px;
                  top: -9px;
                }
              }
            }

            .closed,
            .opened {
              display: none;
              right: 0;
              left: 0;
            }
          }

          .closed,
          .opened {
            display: none;
            right: 0;
            left: 0;
          }

          .menu-icon {
            top: 20px;
            left: 17px;
            -moz-border-radius: 0;
            -webkit-border-radius: 0;
            border-radius: 0;
            height: 50px;
            width: 61px;
            background: none;
            padding: 4px 0px;

            .line {
              width: 100%;
              height: 8px;
              -moz-border-radius: 3px;
              -webkit-border-radius: 3px;
              border-radius: 3px;
              background: #fff;
              display: block;
              margin: 4px 0;
            }
          }
        }
      }

      .right-links {
        height: 70px;
        width: 100%;
        float: none;
        display: flex;

        .icon-list {
          display: flex;
          text-align: center;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }

        .login-area {
          border: none;
          float: none;
          display: inline-block;
          vertical-align: middle;
          z-index: 9000;

          .login-click {
            padding: 0 16px;
          }

          // &:hover {
          //   .login-icon {
          //     position: relative;
          //     top: 0;
          //     left: 0;
          //   }
          // }

          .login-icon {
            position: relative;
            top: 0;
            left: 0;
          }
        }

        .country-area {
          border: none;
          padding: 0 16px;
          float: none;
          display: flex;
          align-items: center;

          .other-coutries {
            top: 70px;
            width: 100%;
            background: @brand-orange;
            z-index: 21;
            padding-top: 10px;
            padding-bottom: 10px;

            a {
              height: 53px;
              padding-left: 0;
              margin: 0;
            }
          }
        }

        .basket-area {
          border: none;
          padding: 0 16px;
          float: none;
          display: flex;
          align-items: center;
        }

        .depot-area {
          border: none;
          padding: 0 16px;
          float: none;
          display: flex;
          align-items: center;
        }

        .mail-area {
          border: none;
          float: none;
          padding: 0 16px;
          display: flex;
          align-items: center;
        }

        .tech-area {
          border: none;
          padding: 0 16px;
          float: none;
        }

        .ipad-search {
          border: none;
          padding: 0 16px;
          float: none;
          display: flex;
          align-items: center;

          .search-icon {
            padding: 0;
          }

          .drop-search {
            z-index: 9000;
          }
        }

        .search-area {
          border: none;
          padding: 0 16px;
          display: none !important;
          float: none;
        }
      }
    }
  }

  header .top-links .right-links .login-area .drop-login .login-btn {
    text-align: left;
  }
}

@media (max-width: 570px) {
  header .top-links .right-links {
    .basket-area {
      padding: 0 8px;
    }

    .country-area {
      padding: 0 8px;
    }
  }
}

@media (max-width: 535px) {
  header .top-links .right-links .logged-in {
    width: auto;
    padding-left: 0px;
    padding-right: 3px;
    right: 0px;
    float: right;

    .links {
      float: right;
      clear: both;
    }
  }

  header .logo {
    position: absolute;
    margin: 15px 0 24px 18px;
    top: 0px;
    left: 108px;
    width: 60%;
    padding-right: 25px;
  }

  header .top-links .right-links {
    .ipad-telno {
      padding: 0 10px;
      display: none;

      .tel-icon {
        .font-size(26);
      }
    }

    .ipad-search {
      padding: 0 10px 0 5px;

      .search-icon {
        .font-size(26);
      }
    }

    .depot-area {
      padding: 0 10px;

      .depot-icon {
        .font-size(18);
      }
    }

    .basket-area {
      padding: 0 5px;

      .basket-icon {
        .font-size(20);
      }

      .number {
        .font-size(8);
        top: -5px;
      }
    }

    .country-area {
      padding: 0 5px;

      .country-icon {
        .font-size(20);
      }

      .country {
        .font-size(8);
        position: relative;
        top: -5px;
      }
    }

    .mail-area {
      padding: 0 10px;

      .mail-icon {
        .font-size(26);
      }
    }

    .tech-area {
      padding: 0 5px;

      .tech-icon {
        .font-size(20);
      }
    }

    .login-area {
      padding: 0;

      .login-click {
        padding: 0 16px;
      }

      &:hover {
        .login-icon {
          .font-size(20);
        }
      }

      .login-icon {
        .font-size(20);
        top: 17px !important;
      }
    }

    .search-area {
      padding: 0 5px;

      .search-icon {
        .font-size(20);
      }
    }
  }
}

@media (max-width: 450px) {
  header {
    .moved-nav {
      left: -244px;
    }

    .desktop-nav {
      width: 244px;
    }
  }
}

@media (max-width: 400px) {
  header {
    .top-links {
      margin-top: 80px;

      .left-links {
        .menu-btn {
          height: 80px;
          width: 80px;

          &.menu-open .menu-icon {
            left: -7px;

            .line {
              &.one {
                top: 26px;
                left: 20px;
              }

              &.two {
                top: 14px;
                left: 20px;
              }

              &.three {
                top: 6px;
                left: 19px;
              }

              &.four {
                left: 19px;
                top: -5px;
              }
            }
          }

          .right-links {
            .country-area {
              padding: 0 5px;

              .country-icon {
                .font-size(20);
              }

              .country {
                .font-size(8);
                position: relative;
                top: -5px;
              }
            }
          }

          .menu-icon {
            top: 14px;
            left: 13px;
            height: 50px;
            width: 50px;

            .line {
              height: 6px;
            }
          }
        }
      }
    }

    .logo {
      margin: 0;
      top: 10px;
      left: 94px;
      width: 50%;
      padding-right: 0;
    }
  }
}

@media (max-width: 315px) {
  header {
    .top-links {
      .right-links {
        .country-area {
          padding: 0 5px;

          .country-icon {
            .font-size(20);
          }

          .country {
            .font-size(8);
            position: relative;
            top: -12px;
          }
        }
      }
    }
  }
}

.right-links {
  .fa {
    color: @brand-white;

    @media (max-width: 992px) {
      color: @brand-white;
    }
  }

  .login-area,
  .search-icon {
    .fa {
      color: @brand-white;
    }
  }
}

.header__mob-number {
  display: block;
  padding: 20px;
  font-size: 20px;
  color: @brand-primary;

  &:hover {
    text-decoration: none;
  }
}

// SUB NAVIGATION STYLES
.sub-nav {
  background-color: @brand-tertiary;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: block;
    width: 100%;
  }
}

.sub-nav__inner {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    flex-direction: column;
    display: none;
  }

  &.is-active {
    @media (max-width: 767px) {
      display: flex;
    }
  }

  li {
    display: inline-block;

    @media (max-width: 767px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  a {
    .font-size(16);
    transition: border .5s;
    padding: 18px 15px 15px;
    border-bottom: 3px solid @brand-tertiary;
    color: @brand-white;
    display: inline-block;

    &:hover,
    &.is-active {
      border-bottom: 3px solid @brand-secondary;
      text-decoration: none;
    }
  }
}

.sub-nav__mob-btn {
  display: none;
  cursor: pointer;

  @media (max-width: 767px) {
    .font-size(16);
    display: block;
    text-align: center;
    padding: 18px 15px;
    background-color: @brand-tertiary;
    width: 100%;
  }

  &:active,
  &:focus {
    outline: none;
    background-color: @brand-tertiary;
  }

  &:hover {
    @media (max-width: 767px) {
      text-decoration: none;
      background-color: @brand-orange;
    }
  }

  &.is-active {
    @media (max-width: 767px) {
      background-color: @brand-orange;
    }
  }
}