@import '../partials/_mixins';
@import '../partials/_variables';

// Body
body {
  color: @base-font-color;
  font-family: @base-font-family;
  font-size: ~'@{base-font-size}px';
  line-height: ~'@{base-line-height}px';
}

.main-content {
  margin-top: 10px;
}

// Elements
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @heading-font-family;
  .font-size(25);
  margin: 0 0 20px 0;
}

h1 {
  .font-size(36);
  color: @brand-secondary;
  font-weight: bold;

  .fa {
    margin-right: 10px;
    font-size: 140%;
  }

  small {
    font-size: 60%;
    color: @brand-black;
  }
}

h2 {
  .font-size(25);
  color: @base-font-color;
  font-weight: bold;
}

h3,
.BlogCommentsTitle {
  color: @brand-primary;
  .font-size(25);
  font-weight: bold;
}

h4 {
  .font-size(20);
}

h5 {
  .font-size(18);
}

h6 {
  .font-size(16);
}

a {
  outline: none !important;
  color: @base-link-color;

  &:focus,
  &:hover {
    outline: none !important;
    color: @base-link-active-color;
  }
}

blockquote {
  border-left-color: @base-font-color;
}

select {
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
}

.load-more-btn {
  background: @brand-light-grey;
  color: @brand-primary;
  padding: 5px 15px;
  margin-bottom: 40px;

  &:hover {
    background: @brand-primary;
    color: @brand-white;
  }
}

.large-text {
  .font-size(22);
}

.grey-section {
  margin-top: 25px;
  background-color: #f5f5f5;
  padding-bottom: 50px;
}

.jilted-title {
  display: inline-block;
  margin-top: -25px;
  margin-left: -5px;
  margin-bottom: 50px;
  padding: 15px 50px;
  color: @brand-white;
  background-color: @brand-primary;

  @media (max-width: 991px) {
    padding: 15px 20px;
    font-size: 20px;
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }
}

.share-icons {
  float: right;
  display: inline-block;
  padding-top: 10px;
}

.load-overlay {
  display: none;
}

.HomePage .load-overlay {
  display: block;
  z-index: 9999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .load-icon {
    display: block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
  }
}

.fade-in {
  opacity: 1 !important;
  top: 0 !important;
}

.alLeft {
  text-align: left;
}

.alCenter {
  text-align: center;
}

.alRight {
  text-align: right;
}

.inner-content {
  margin-bottom: 45px;

  .grey-bar {
    margin-top: 45px;
  }
}

// breadcrumbs
.breadcrumbs {
  margin: 0 0 10px;

  a,
  span {
    color: @brand-grey;
    text-decoration: none;
    margin-right: 30px;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      display: block;
      position: absolute;
      // font-family: 'Font Awesome 5 Free';
      top: 5px;
      right: -20px;
      color: @brand-primary;
      font-size: 0.8em;
      background-image: url('/Sitefiles/Images/chevron.png');
      width: 6px;
      height: 10px;
    }

    &.CMSBreadCrumbsCurrentItem {
      &:after {
        content: none;
        // right: -15px;
        // top: -1px;
      }
    }

    &:hover {
      color: @brand-primary;
    }
  }

  .hdn {
    display: none;
  }
}

.breadcrumbs + img,
.top-image {
  margin-bottom: 15px;
}

.striped-bg-faint-line {
  padding: 15px 0 20px;
  // background: url(~@imgOld/faint-line-repeat.png) repeat 0px 0px;
  background-color: #f5f5f5;
  text-align: center;

  & + .grey-section {
    margin-top: 50px;
  }

  label {
    .font-size(16.5);
    font-weight: 400;
    margin: 0 0 10px;
    display: block;
    text-align: center;
  }

  &__inner {
    text-align: center;
  }

  .info-strips {
    display: block;
    float: left;
    margin: 0;
  }

  .strip {
    display: inline-block;
    padding: 14px 25px;
    margin: 0 8px;
  }
}

img {
  max-width: 100%;
}

.marg-btm {
  margin-bottom: 15px;
}

.swipe-table {
  display: none;
  margin-bottom: 8px;
  font-weight: bold;
}

#page-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: #f2f2f2;
}

.inner-wrapper {
  background: #fff;
  min-height: 100vh;
  position: relative;

  @media (max-width: 767px) {
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -moz-transition: transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
  }

  &.move-wrapper {
    -moz-transform: translateX(350px);
    -ms-transform: translateX(350px);
    -o-transform: translateX(350px);
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }

  .is-fixed & {
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    transform: none;
    -moz-transition: transform 0s ease-in-out;
    -o-transition: transform 0s ease-in-out;
    -webkit-transition: transform 0s ease-in-out;
    transition: transform 0s ease-in-out;
  }
}

input[type='submit'],
.btn {
  position: relative;
  outline: none;
  width: auto;
  float: none;
  background: @brand-primary url(~@imgOld/submit-arrow.png) no-repeat left center;
  color: #fff;
  font-size: 1.42857143em;
  padding: 12px 44px 12px 18px;
  text-decoration: none;
  display: inline-block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;

  &.btn--standard {
    padding: 12px 30px;
    background: none;
    background-color: @brand-orange;
    color: @brand-white;
  }

  &.btn-orange {
    background: @brand-orange url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover {
      background: lighten(@brand-orange, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }
  }

  &.btn-grey {
    background: @brand-grey url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover {
      background: lighten(@brand-grey, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }
  }

  &.back-arrow {
    background-image: url(~@imgOld/back-arrow.png);

    &.back-align-left {
      background-position-x: 3px;
    }
  }

  &.with-icon {
    padding: 12px 18px 12px;
    background-image: none !important;

    .fa {
      padding-right: 15px;
    }
  }
}

.enquiry-btn {
  float: right;
  min-width: 163px;
  margin: 0px;
  position: relative;
  right: 0;

  .fa {
    position: absolute;
    z-index: 15;
    left: 0px;
    top: 23px;
    color: #fff;
    .font-size(18);

    .pad-right {
      left: -60px;
      top: 20px;
    }
  }

  input[type='submit'] {
    content: '\f07a';
    background: @brand-orange url(~@imgOld/submit-arrow.png) no-repeat right center;

    &:hover {
      background: lighten(@brand-orange, 10%) url(~@imgOld/submit-arrow.png) no-repeat right center;
    }

    padding: 12px 18px 12px;
    background-image: none !important;
    padding-left: 50px;
  }
}

.placeholder {
  color: #c6c6c6;
}

.CategoryListList {
  .CategoryListItem {
    &:before {
      display: inline-block;
      padding-right: 5px;
      color: @brand-secondary;
      content: '\f061';
      font-family: @fa-f;
    }

    &.CategoryListItem {
      > .CategoryListInner {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}

.main-content {
  ul {
    padding: 0;

    li {
      position: relative;
      color: @base-font-color;
      list-style-type: none;
      padding-left: 16px;

      &:before {
        display: block;
        position: absolute;
        top: calc(0.5em - 3px);
        left: 0;
        width: 10px;
        height: 10px;
        content: ' ';
        border-radius: 50%;
        background-color: @brand-primary;
      }

      a {
        color: @base-font-color;

        &:hover {
          color: @brand-primary;
        }
      }
    }
  }

  .product-view {
    ul {
      padding: 0;
    }
  }

  blockquote {
    border-left-color: @brand-primary;
    .font-size(17);

    footer {
      background: none;
    }
  }

  .table-responsive {
  }

  .table {
    th {
      color: @brand-primary;
      .font-size(18);
      font-weight: normal;
      border-right: 1px solid @brand-primary;
      border-bottom: 2px solid @brand-primary;
      padding-bottom: 3px;
      padding-left: 15px;

      &:last-child {
        /*border-right: none;*/
      }
    }

    td {
      .font-size(16);
    }

    tr {
      td {
        border-right: 1px solid @brand-primary;
        border-bottom: 1px solid @brand-primary;
        border-top: none;
        padding: 15px 10px 3px;

        &:last-child {
          /*border-right: none;*/
        }
      }

      .td-no-border {
        border: none;
      }

      // &:first-child {
      //   td {
      //     border-bottom: 2px solid @brand-primary;
      //   }
      // }
    }
  }
}

.blog-replys .CommentDetail {
  background-color: #fcfcfc; /*#f90 !important;*/
  font-family: 'ff-dax-pro', Arial, sans-serif;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;

  &:nth-child(2n + 1) {
    background-color: #fdfdfd !important;
  }
}

.form-control-error {
  color: @brand-red;
}

.CommentDetail .table-responsive .table {
  border-collapse: collapse;
}
/*User name and admin controls row in a comment*/
.CommentDetail {
  .table tr:nth-child(2n + 1) {
    background-color: #009dff !important;
    color: #fff;
  }

  .table tr:nth-child(3n + 1) {
    background-color: #ebebeb !important;
    color: #009dff;
  }
}

.HomePage .content-wrap {
  opacity: 1;
}

.animate-with-dropdown {
  position: relative;
}

.site-offline {
  header {
    position: relative !important;
    z-index: 3000;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    opacity: 1 !important;
    display: block !important;

    .logo {
      left: 0;
    }
  }

  .error-wrap {
    top: 82px;
  }

  .grey-bar {
    width: 100% !important;
  }
}

.main-content .grey-bar {
  width: 100%;

  &.with-edge {
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      content: ' ';
      display: block;
      height: 8px;
      width: 35px;
      background: url(/sitefiles/images/tpa-site/green-edge.png) no-repeat 0 0;
    }
  }
}

@media (max-width: 1199px) {
  .striped-bg-faint-line label {
    float: none;
    margin: 0 0 10px 0;
  }

  .striped-bg-faint-line {
    float: none;
    display: table;
    width: 100%;

    .strip {
      display: table-cell;
      margin: 0;
      background: none;
      padding: 0 2px;

      .strip-content {
        padding: 14px 10px;
        background: #e3e3e3;
      }
    }
  }
}

@media (max-width: 991px) {
  .content-wrap {
    margin-top: 20px !important;
    opacity: 1 !important;
  }

  .HomePage .content-wrap {
    margin-top: 0 !important;
  }

  .container.no-tab-pad {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .share-icons {
    float: none;
    display: block;
    padding-top: 10px;
  }

  .swipe-table {
    display: block;
  }

  .site-offline {
    header {
      .logo {
        left: 10px;
        width: 300px;
      }
    }
  }

  .inner-content {
    margin-bottom: 25px;
  }

  .mobGap {
    margin-bottom: 25px;
  }

  .striped-bg-faint-line .info-strips {
    display: block;

    .strip {
      display: block;
      float: left;
      margin: 0 2px 2px 0;
      padding: 0;
      width: 48%;

      .strip-content {
        padding: 14px 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  select {
    width: 100% !important;
    height: 40px;
  }

  .site-offline .error-wrap {
    margin-bottom: 300px;
  }

  #advertise-carousel {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .inner-wrapper.move-wrapper {
    -moz-transform: translateX(244px);
    -ms-transform: translateX(244px);
    -o-transform: translateX(244px);
    -webkit-transform: translateX(244px);
    transform: translateX(244px);
  }

  .striped-bg-faint-line .info-strips {
    .strip {
      float: none;
      margin: 0 0 2px 0;
      padding: 0;
      width: 100%;

      .strip-content {
        padding: 14px 10px;
      }
    }
  }

  .btn-orange {
    clear: both;
    right: 30px;
  }
}

.hdn {
  display: none;
}

/* CMS Desk */

.EditMode {
  .homepage-signposts {
    .EditorWidget.WebPartInner {
      float: left;
      width: 20%;

      .WebPartContent {
        .signpost-item {
          width: 100%;
        }
      }
    }
  }

  .striped-bg-faint-line {
    .EditorWidget.WebPartInner {
      float: left;
    }
  }
}

.my-profile-logout {
  text-align: right;
  margin-bottom: 39px;
}

.orig {
  display: none;
}

.load-more-btn {
  display: none;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0px solid !important;
    /*overflow-x: hidden;*/
  }

  .col-sm-6.col-md-4.guest-checkout {
    padding-top: 25px;
  }
}

.option-btn {
  max-width: 188px;
}

.guest-checkout {
}

.no-js-div {
  display: none !important;
}

.enquire-now {
  display: none !important;
}

.consent-item {
  margin-bottom: 34px;

  .btn {
    margin-bottom: 20px;
  }

  .btn-manual-revoke {
    display: inline-block;
    position: relative;
    margin: 0;
    border: 0;
    padding: 12px 36px;
    color: #fff;
    background-color: @brand-orange;
    font-size: 1.28571429em;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 0;
    transition: background 0.3s ease;
    margin-bottom: 20px;
  }

  .grey-bar:last-of-type {
    display: none;
  }
}

// INDENT STRIP STYLES
.striped-bg-faint-line {
  + .indent-strip {
    transform: translateY(0);
    margin-bottom: 0;
  }
}

.indent-strip {
  transform: translateY(-50%);
  margin-bottom: -45px;

  .grey-section & {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    transform: translateY(0);
    margin-bottom: 0;
  }

  .jilted-title {
    margin-top: 0;
    margin-bottom: 0;
    .font-size(28);

    @media (max-width: 767px) {
      .font-size(20);
      margin: 10px 0;
    }
  }

  .widget {
    margin-bottom: 0;
  }
}

.indent-strip__inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

.indent-strip__cell {
  flex: 1 0 33%;
  max-width: 33%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  &:last-child {
    .indent-strip__cell-inner {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  .indent-strip--quad & {
    flex: 1 0 25%;
    max-width: 25%;

    @media (max-width: 991px) {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }
}

.indent-strip__cell-inner {
  padding: 0 20px;

  @media (max-width: 991px) {
    padding: 0 10px;
  }
}

.latest-news-text {
  .font-size(18);
  color: @brand-secondary;
  margin-bottom: 10px;
  display: none;
}

.owl-theme {
  .owl-controls {
    .owl-buttons .owl-next,
    .owl-buttons .owl-prev {
      z-index: 10;
    }

    .owl-buttons .owl-next {
      transform: rotate(-180deg) translateY(50%);
    }
  }
}

.table-responsive .CaptchaTable {
  width: auto;
  td {
    border: none !important;

    img {
      padding-top: 30px;
    }
  }
}
