@import '../partials/_mixins';
@import '../partials/_variables';

.form-box {
  background: #f1f1f1 url(~@imgOld/form-repeat.png) repeat 0px 0px;
  padding: 60px 60px 28px 60px;
  margin: 0px 0 20px;

  label {
    .font-size(20);
    font-weight: normal;
    color: @brand-primary;
    margin-bottom: 10px;
  }

  input,
  .form-control,
  textarea {
    margin-bottom: 30px;
    background: #fff;
    border: none !important;
    height: 54px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 10px 15px;
    color: lighten(@brand-grey, 14%);

    &:focus {
      border: 1px solid @brand-primary!important;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 none;
    }
  }

  input[type='submit'] {
    float: right;
  }

  .editing-form-control-nested-control {
    .checkbox {
      float: left;
    }
  }

  .explanation-text {
    display: block;
    position: relative;
    overflow: hidden;
    margin-top: 12px;
  }
}

.enquiry-form {
  .alert {
    margin-top: 0;
  }
}

textarea {
  min-height: 110px;
  height: auto !important;
  color: lighten(@brand-grey, 14%);
  padding: 15px;
}

.small-input {
  outline: none;
}

.EditingFormErrorLabel,
.ErrorLabel {
  width: 100%;
  display: block;
  line-height: 1.3em;
  margin-top: 10px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.hideErrorLabels .ErrorLabel {
  padding: 0px;
  border: 0px;
  float: none;
}

.enquiry-form {
  text-align: left;
}

input,
select,
textarea {
  color: @base-font-color;
  .placeholder-color();
}

.btn,
button,
input[type='button'],
input[type='submit'] {
  .button();
}

input[type='checkbox'],
input[type='radio'] {
  /*display: none;*/
}

.checkbox input[type='checkbox'] {
  margin-top: 2px;
  height: auto;
  position: relative;
  display: block;
  float: left;
  margin-left: 0px;
  margin-bottom: 0px;
}

.label-check,
.label-radio {
  display: inline-block;
  position: relative;
  height: 22px;
  margin: 0 26px 0 0;
  padding: 0 0 0 26px;
  vertical-align: middle;
  .line-height(22);

  &::before,
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    height: 14px;
    width: 14px;
    margin-top: -7px;
  }

  &::before {
    background: @brand-white;
  }
}

.label-check {
  &::before {
  }

  &::after {
    content: '\f00c';
    font-family: FontAwesome;
    font-size: 0;
    color: @base-input-font-color;
    .line-height(15);
  }

  &.checked {
    &::after {
      font-size: 1em;
    }
  }
}

.label-radio {
  &::before {
    border-radius: 7px;
  }

  &::after {
    left: 3px;
    height: 8px;
    width: 8px;
    margin-top: -4px;
    border-radius: 4px;
  }

  &.checked {
    &::after {
      background: @base-input-font-color;
    }
  }
}

// Error Styles
.error-control {
  border: 1px solid @base-error-color;
  background: lighten(@base-error-color, 50%);
}

.error-message {
  display: block;
  padding: 8px;
  color: @base-error-color;
}

.alert {
  width: 100%;
  float: left;
  line-height: 1.3em;
  margin-top: 10px;
}

.ErrorLabel {
  margin: 10px 0;
}

.input-box {
  clear: both;
}

.LoginPanelPasswordRetrieval {
  display: inline-block;
}

.EditingFormInfoLabel,
.InfoLabel {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
}

.no-width {
  width: auto;
}

.checkbox-list-vertical input[type='checkbox'] {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.checkbox-list-vertical input[type='checkbox'] + label {
  padding-left: 32px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  .font-size(14);
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-list-vertical input[type='checkbox']:checked + label {
  background-position: 0 -20px;
}

.checkbox-list-vertical label {
  background-image: url(~@imgOld/checkbox2.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: lighten(@brand-grey, 14%);
}

@media (max-width: 480px) {
  .form-box {
    padding: 38px 26px 22px 26px;
    margin-bottom: 10px;
  }
}

.blog-comments .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.no-js-btn {
  display: none !important;
}

.gdpr-checkbox.checkbox {
  input[type='checkbox'] {
    position: fixed;
    width: 13px;
  }
}

.form-group--conditions {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 5px;
  max-height: 40vh;
}

.terms-checkbox:disabled {
  + .terms-checkbox__label {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.terms-btn:disabled {
  cursor: not-allowed;
}

.editing-form-control-nested-control {
  a {
    margin-top: 10px;
  }
}

.download-panel {
  .btn-primary:disabled {
    background-color: @brand-grey;
    border-color: @brand-grey;
  }
}
