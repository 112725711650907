.twitter-typeahead {
  .input-group & {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
  .tt-hint {
    color: #585858;
    color: fadeout(#585858, 25%);
  }
  .tt-menu {
    display: block;
    width: 100%;
    padding: 4px 0;
    background-color: #fff;
    box-shadow: #585858 2px 2px 4px;
    box-shadow: fadeout(#585858, 75%) 2px 2px 4px;
    .tt-dataset {
      display: block;
      .tt-suggestion {
        display: block;
        padding: 5px 12px;
        cursor: pointer;
        min-height: 60px;
        clear: both;
        color: #666666;
        line-height: 1.4em;
        &:hover {
          color: #fff;
          background-color: @brand-orange;
        }
        p {
          margin: 10px 0 0;
        }
        img {
          max-width: 50px;
          float: left;
          margin-right: 15px;
        }
      }
    }
  }
}
