@color-error: #a94442;

.ko-form {
  .ko-form-outer {
    .ko-form-inner {
      .ko-form-category {
        .form-group {
          &.required {
            label {
              &::after {
                content: "\0000a0*";
                display: inline-block;
                color: @color-error;
              }
            }
          }

          .error-control {
            border: 1px solid @color-error;
            background: lighten(@color-error, 20%);
          }

          .error-message {
            display: block;
            padding: 8px;
            color: @color-error;
          }
        }

        .btn {
        }
      }
    }
  }
}
