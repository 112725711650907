@import "../partials/_mixins";
@import "../partials/_variables";
.download-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__close-button {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  &__close-icon {
    font-size: 20px;
    cursor: pointer;
  }

  .form-box {
    position: relative;
    min-width: 320px;
    max-width: 800px;
    max-height: 80vh;

    @media (max-width: 991px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      margin: 0 auto 20px;
    }

    input[type="submit"] {
      float: none;
      display: block;
      width: 100%;
    }
  }
}
