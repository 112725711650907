@import '../partials/_mixins';
@import '../partials/_variables';

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
}

.fancybox-opened {
    z-index: 8030;
}

    .fancybox-opened .fancybox-skin {
        -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    }

.fancybox-outer, .fancybox-inner {
    position: relative;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    margin: 0;
    padding: 15px;
    color: #444;
    white-space: nowrap;
    font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url('/sitefiles/images/fancybox/fancybox_sprite.png');
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 8060;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
}

    #fancybox-loading div {
        width: 44px;
        height: 44px;
        background: url('/sitefiles/images/fancybox/fancybox_loading.gif') center center no-repeat;
    }

.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    z-index: 8040;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.fancybox-nav {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    position: absolute;
    top: 0;
    z-index: 8040;
    width: 40%;
    height: 100%;
    text-decoration: none;
    cursor: pointer; /* helps IE */
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    z-index: 8040;
    visibility: hidden;
    margin-top: -18px;
    width: 36px;
    height: 34px;
    cursor: pointer;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    overflow: visible !important;
    max-width: 99999px;
    max-height: 99999px;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

    .fancybox-lock body {
        overflow: hidden !important;
    }

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8010;
    display: none;
    overflow: hidden;
    background: url('/sitefiles/images/fancybox/fancybox_overlay.png') repeat;
}

.fancybox-overlay-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
    position: relative;
    z-index: 8050;
    visibility: hidden;
    text-shadow: none;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    right: 50%;
    bottom: 0;
    z-index: 8050;
    margin-bottom: -35px;
    text-align: center;
}

    .fancybox-title-float-wrap .child {
        display: inline-block;
        margin-right: -100%;
        padding: 2px 20px;
        -moz-border-radius: 15px;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.8);
        background: transparent;
        color: #FFF;
        text-shadow: 0 1px 2px #222;
        white-space: nowrap;
        font-weight: bold;
        line-height: 24px; /* Fallback for web browsers that doesn't support RGBa */
    }

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
    color: #fff;
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background-image: url('/sitefiles/images/fancybox/fancybox_sprite@2x.png');
        -moz-background-size: 44px 152px;
        -o-background-size: 44px 152px;
        background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }

        #fancybox-loading div {
            background-image: url('/sitefiles/images/fancybox/fancybox_loading@2x.gif');
            -moz-background-size: 24px 24px;
            -o-background-size: 24px 24px;
            background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
        }
}

#fancybox-buttons {
    position: fixed;
    left: 0;
    z-index: 8050;
    width: 100%;
}

    #fancybox-buttons.top {
        top: 10px;
    }

    #fancybox-buttons.bottom {
        bottom: 10px;
    }

    #fancybox-buttons ul {
        display: block;
        margin: 0 auto;
        padding: 0;
        width: 166px;
        height: 30px;
        border: 1px solid #111;
        border-radius: 3px;
        background: -moz-linear-gradient(top, rgb(68,68,68) 0%, rgb(52,52,52) 50%, rgb(41,41,41) 50%, rgb(51,51,51) 100%);
        background: -ms-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
        background: -o-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(68,68,68)), color-stop(50%,rgb(52,52,52)), color-stop(50%,rgb(41,41,41)), color-stop(100%,rgb(51,51,51)));
        background: -webkit-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
        background: linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
        background: rgb(50,50,50);
        -moz-box-shadow: inset 0 0 0 1px rgba(255,255,255,.05);
        -webkit-box-shadow: inset 0 0 0 1px rgba(255,255,255,.05);
        box-shadow: inset 0 0 0 1px rgba(255,255,255,.05);
        list-style: none;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#222222',GradientType=0 );
    }

        #fancybox-buttons ul li {
            float: left;
            margin: 0;
            padding: 0;
        }

    #fancybox-buttons a {
        display: block;
        width: 30px;
        height: 30px;
        outline: none;
        background-color: transparent;
        background-image: url('/sitefiles/images/fancybox/fancybox_buttons.png');
        background-repeat: no-repeat;
        text-indent: -9999px;
        opacity: 0.8;
    }

        #fancybox-buttons a:hover {
            opacity: 1;
        }

        #fancybox-buttons a.btnPrev {
            background-position: 5px 0;
        }

        #fancybox-buttons a.btnNext {
            border-right: 1px solid #3e3e3e;
            background-position: -33px 0;
        }

        #fancybox-buttons a.btnPlay {
            background-position: 0 -30px;
        }

        #fancybox-buttons a.btnPlayOn {
            background-position: -30px -30px;
        }

        #fancybox-buttons a.btnToggle {
            width: 35px;
            border-right: 1px solid #3e3e3e;
            border-left: 1px solid #111;
            background-position: 3px -60px;
        }

        #fancybox-buttons a.btnToggleOn {
            background-position: -27px -60px;
        }

        #fancybox-buttons a.btnClose {
            width: 35px;
            border-left: 1px solid #111;
            background-position: -56px 0px;
        }

        #fancybox-buttons a.btnDisabled {
            opacity: 0.4;
            cursor: default;
        }

