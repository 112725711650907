.media-asset--video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;

  iframe {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.article-title {
  font-size: 25px;
}