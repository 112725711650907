// Load Partials
@import '../partials/_mixins';
@import '../partials/_variables';

.styleguide {
    position:relative;



    .styleguide-nav {
        display: block;
        position: fixed;
        width: 160px;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        top: 192px;
        right:0;
        z-index:99;

        ul {
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: block;

                &:before {
                    display: none;
                }

                a {
                    display: block;
                    line-height: 1.5em;
                    color:@brand-orange;

                    &:hover {
                        color:@brand-orange;
                    }
                }
            }
        }
    }

    .style-section {
        display: block;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

        .style-section-title {
            display: block;
            margin: 0;
            border-bottom: 1px solid #ddd;
            border-radius: 4px 4px 0 0;
            padding: 10px 15px;
            background-color: #f5f5f5;
        }

        .style-section-content {
            display: block;
            margin: 0;
            border-radius: 0 0 4px 4px;
            padding: 15px;
        }
    }

    .swatch {
        display: block;
        margin-bottom: 15px;
        width: 23%;
        float: left;
        height: 142px;
        margin: 0 1% 16px;
        border: 1px solid #a1a1a1;
        border-radius: 6px;


        .swatch-colour {
            display: block;
            height: 100px;
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 4px 4px 0 0;
            background-color: #ffffff;
        }

        .swatch-detail {
            display: block;
            border-radius: 0 0 4px 4px;
            border: 1px solid #a1a1a1;
            background-color: #a1a1a1;
            text-align: center;
            color: #fff;


            .swatch-hex, .swatch-name {
                display: block;
            }

            .swatch-hex {
                text-transform: lowercase;
            }
        }
    }

    .style-source-hover {
        position: relative;

        .style-source-btn {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            height: 32px;
            width: 32px;
            line-height: 32px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;

            &::before {
                content: '\f121';
                font-family: 'FontAwesome';
            }
        }

        &:hover .style-source-btn {
            display: block;
        }

        &.style-section .style-source-btn {
            height: 46px;
            line-height: 46px;
        }
    }
}



@media (max-width:767px) {
    .styleguide .styleguide-nav {
        display:none;
    }
}