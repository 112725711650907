@import '../partials/_mixins';
@import '../partials/_variables';

.tech-library {
  margin-bottom: 50px;
  min-height: 500px;

  .tech-header {
    h1 {
      float: left;
      width: 50%;
      margin: 7px 0;
    }

    .tech-library-buttons {
      float: left;
      width: 50%;
      margin-bottom: 20px;
      text-align: right;
    }
  }

  .tech-search {
    background: url(~@imgOld/faint-line-repeat.png) repeat;
    padding: 40px;
    position: relative;
    margin-bottom: 50px;

    .inner-search {
      background: #fff;
      position: relative;
      overflow: hidden;

      &.typed {
        .search-text {
          left: -200px;
        }

        input[type='submit'] {
          right: 22px;
        }
      }
    }

    input[type='text'],
    input[type='search'] {
      width: 100%;
      border: none;
      background: transparent;
      height: auto;
      line-height: normal;
      padding: 15px 85px 15px 40px;
      .font-size(40);
      position: relative;
      z-index: 2;
      outline: none;
    }

    input[type='text']:focus + input[type='submit'] {
      right: 22px;
    }

    input[type='text']:focus ~ .search-text {
      left: -200px;
    }

    .search-text {
      position: absolute;
      top: 36px;
      left: 40px;
      .font-size(40);
      color: @brand-primary;
      -moz-transition: left 0.4s ease;
      -o-transition: left 0.4s ease;
      -webkit-transition: left 0.4s ease;
      transition: left 0.4s ease;
      z-index: 0;

      i {
        margin-right: 15px;
      }
    }

    input[type='submit'] {
      position: absolute;
      top: 18px;
      right: -100px;
      background: url(@sprite-path) no-repeat 0 -294px;
      text-indent: -2000em;
      width: 54px;
      height: 54px;
      -moz-transition: right 0.4s ease;
      -o-transition: right 0.4s ease;
      -webkit-transition: right 0.4s ease;
      transition: right 0.4s ease;
      z-index: 3;
    }
  }

  h3 {
    color: @base-font-color;
  }

  .files {
    margin-bottom: 40px;

    button {
      background: #e4e9d4;
      color: @brand-primary;
      padding: 5px 15px;
      margin-bottom: 40px;

      &:hover {
        background: @brand-primary;
        color: #e4e9d4;
      }
    }
  }

  .login-register {
    padding: 40px;
    background: url(~@imgOld/faint-line-repeat.png) repeat 0px 0px;
    margin: 30px 0 40px;

    .reg-box .EditingFormInfoLabel {
      float: left;
    }

    .widget {
      margin: 0;
    }

    .login-box {
      color: @brand-white;
      -moz-transition: background 0.5s ease-in-out;
      -o-transition: background 0.5s ease-in-out;
      -webkit-transition: background 0.5s ease-in-out;
      transition: background 0.5s ease-in-out;
      position: relative;

      .alert {
        margin-top: 0;
      }

      .form-control-error .alert {
        margin-top: 20px !important;
      }

      .LoginPanelPasswordRetrieval {
        display: block;
      }

      &.orange-login {
        background: @brand-orange;

        .login-box-back {
          background: @brand-orange;
        }

        .login-btn,
        input[type='submit'] {
          background-image: url(~@imgOld/submit-arrow-orange.png);
          color: @brand-orange;
          background-position: right;
        }
      }

      &.green-login {
        background: @brand-primary;

        .login-box-back {
          background: @brand-primary;
        }

        .login-btn,
        input[type='submit'] {
          color: @brand-primary;
        }
      }

      &.grey-login {
        background: @brand-grey;

        .login-box-back {
          background: @brand-grey;
        }
      }

      &:hover {
        &.orange-login {
          background: lighten(@brand-orange, 10%);
        }

        &.green-login {
          background: lighten(@brand-primary, 10%);
        }

        &.grey-login {
          background: lighten(@brand-grey, 10%);
        }
      }

      .login-box-top {
        display: block;
        padding: 18px 12px;
        -moz-transition: opacity 0.5s ease;
        -o-transition: opacity 0.5s ease;
        -webkit-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
        cursor: pointer;

        .signpost {
          display: table;
          width: 100%;
          height: 54px;

          .signpost-icon {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            width: 100px;
            text-align: center;

            img {
              position: absolute;
              top: 0px;
              left: 21px;
              width: 46px;
              -moz-transition: all 0.4s ease-in-out;
              -o-transition: all 0.4s ease-in-out;
              -webkit-transition: all 0.4s ease-in-out;
              transition: all 0.4s ease-in-out;
            }
          }

          .signpost-text {
            display: table-cell;
            vertical-align: middle;
            .font-size(26);
            line-height: 29px;
            color: #fff;
          }
        }
      }

      .login-box-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        display: block;
        -moz-transition: max-height 0.8s ease-in-out;
        -o-transition: max-height 0.8s ease-in-out;
        -webkit-transition: max-height 0.8s ease-in-out;
        transition: max-height 0.8s ease-in-out;
        -webkit-transition-delay: 0; /* Safari */
        -moz-transition-delay: 0;
        -o-transition-delay: 0;
        transition-delay: 0;
        z-index: 2;

        .login-inner {
          padding: 40px 30px 30px;
          position: relative;

          .checkbox {
            display: flex;

            input {
              flex: 1 1 15%;
            }

            label {
              flex: 1 1 85%;
            }
          }

          .close-box {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #fff;
            font-weight: bold;
            .font-size(23);
            cursor: pointer;
          }

          .login-btn,
          input,
          textarea {
            display: block;
            width: 100%;
            border: none;
            margin-bottom: 20px;
            padding: 15px 40px 15px 15px;
            border-radius: 0px;
            height: auto;
          }

          input.email-icon,
          input[type='email'] {
            background: #fff url(/sitefiles/images/email-icon.jpg) no-repeat right top;
          }

          input.name-icon {
            background: #fff url(/sitefiles/images/name-icon.jpg) no-repeat right top;
          }

          input.company-icon {
            background: #fff url(/sitefiles/images/company-icon.jpg) no-repeat right top;
          }

          input.password-icon,
          input[type='password'] {
            background: #fff url(/sitefiles/images/password-icon.jpg) no-repeat right top;
          }

          input.address-icon {
            background: #fff url(/sitefiles/images/address-icon.jpg) no-repeat right top;
          }

          a {
            color: #fff;
            text-decoration: none;
            display: block;
            margin-bottom: 30px;

            &:hover {
              color: @brand-orange;
            }
          }

          input[type='submit'],
          .login-btn {
            background-color: #fff;
            width: auto;
            display: inline-block;
            margin: 0;
            font-size: 1.28571429em;
            vertical-align: middle;
          }

          .login-btn {
            color: @brand-primary;
            background: #fff url(~@imgOld/submit-arrow-blue.png) no-repeat right center;
            float: right;
          }
        }
      }

      &.open-box {
        &.reg-box {
          .login-box-back {
            //height: auto; //469px;
            max-height: 1000px;
          }
        }

        .login-box-top {
          opacity: 0;
        }

        .login-box-back {
          //height: auto; //312px;
          max-height: 1000px;
          -webkit-transition-delay: 0.5s; /* Safari */
          -moz-transition-delay: 0.5s;
          -o-transition-delay: 0.5s;
          transition-delay: 0.5s;
          z-index: 10;
        }
      }
    }
  }
}

.folder-item {
  margin-bottom: 30px;
  width: 100%;
  display: table;

  .folder {
    display: table-cell;
    width: 54px;
    vertical-align: top;
  }

  .folder-desc {
    display: table-cell;
    padding-left: 10px;
    vertical-align: top;

    a {
      text-decoration: none;
    }

    h4 {
      color: @brand-primary;
      .font-size(17);
      margin-top: 3px;
      margin-bottom: 0;
      -moz-transition: color 0.4s ease-in-out;
      -o-transition: color 0.4s ease-in-out;
      -webkit-transition: color 0.4s ease-in-out;
      transition: color 0.4s ease-in-out;

      &:hover {
        color: lighten(@brand-primary, 8%);
      }
    }

    p {
      margin: 0;
      .font-size(13);
      color: #999999;
    }
  }
}

.intro-tech {
  margin-bottom: 40px;

  .grey-bar {
    margin-top: 30px;
  }
}

.youtube-btn,
.download-btn {
  padding: 10px 25px 8px 88px !important;
  position: relative;
  display: inline-block !important;
  font-size: 1.9em !important;
  margin-top: 15px;

  &:before {
    display: block;
    font-family: 'FontAwesome';
    .font-size(20);
    position: absolute;
    top: 3px;
    color: @brand-white;
    left: 29px;
    right: auto;
  }
}

.youtube-btn {
  &:before {
    content: '\f144';
    color: @brand-secondary;
    line-height: 52px;
  }
}

.download-btn {
  &:before {
    content: '\f019';
  }
}

@media (max-width: 1199px) {
  .tech-library .login-register .login-box .login-box-top .signpost .signpost-icon {
    width: 77px;

    img {
      left: 7px;
    }
  }
}

@media (max-width: 991px) {
  .tech-library .tech-search .search-text {
    top: 50%;
    margin-top: -7px;
  }

  .tech-library .tech-search input[type='submit'] {
    top: 50%;
    margin-top: -28px;
  }
}

@media (max-width: 767px) {
  .tech-library .tech-header h1,
  .tech-library .tech-header .tech-library-buttons {
    float: none;
    width: 100%;
    text-align: left;
  }

  .intro-tech,
  .tech-library .files {
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .tech-library .login-register {
    padding: 15px;
  }

  .tech-library .login-register .login-box .login-box-top .signpost .signpost-text {
    font-size: 1.6em;
  }

  .tech-library .tech-header .tech-library-buttons .btn {
    margin-bottom: 15px;
  }

  .tech-library .tech-search {
    padding: 40px 20px;

    .search-text {
      .font-size(26);
      top: 50%;
      margin: -10px 0 0 0;
    }
  }

  .tech-library .tech-search input[type='text'],
  .tech-library .tech-search input[type='search'] {
    padding: 15px 72px 15px 14px;
  }

  .tech-library .tech-search input[type='submit'] {
    background: url('/sitefiles/images/sprite-tpa.png') no-repeat -55px -294px;
    top: 50%;
    margin-top: -26px;
  }

  .youtube-btn {
    font-size: 1.4em !important;
  }
}

.replaced .tech-item {
  opacity: 0;
}

#ItemDetails {
  .tech-icon {
    float: left;
    margin-right: 1%;
    width: 19%;

    img {
      max-width: 100%;
    }
  }

  .tech-desc {
    float: left;
    width: 80%;

    .downloads {
    }

    .tags {
      .font-size(13);

      ul {
        padding: 0;

        li {
          list-style-type: none;
          display: inline-block;
          padding-left: 3px;

          &:before {
            display: none;
          }
        }

        a {
          position: relative;
          display: inline-block;
          padding-right: 9px;
          color: @brand-primary;

          &:hover {
            color: lighten(@brand-primary, 5%);
          }

          &:before {
            display: block;
            content: '|';
            width: 13px;
            text-align: center;
            position: absolute;
            top: 0;
            right: -4px;
            background: none;
          }
        }
      }
    }
  }

  margin-bottom: 30px;
  clear: both;
}
