@import '../partials/_mixins';
@import '../partials/_variables';

.HomePage header {
    -moz-transition-delay: 0s;
    -o-transition-delay: .2s;
    -webkit-transition-delay: .2s;
    transition-delay: .2s;

    .grey-bar {
        -moz-transition-delay: 0.9s;
        -o-transition-delay: 0.7s;
        -webkit-transition-delay: 0.7s;
        transition-delay: 0.7s;
    }
}

.animate-banner {
    opacity: 0!important;
    position: relative;
    top: 0;
    left: 100%;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

.banner-area {
    overflow: hidden;
    opacity: 0;

    .share-carousel-style-btn;

    .item {
        position: relative;

        .banner-overlay {
            position: absolute;
            z-index: 900;
            bottom: 200px;
            left: 0px;
            right: 0px;

            .container {
              @media (min-width:1200px) {
                padding-left: 0;
              }
            }

            .banner-title {
                display: inline-block;
                padding: 20px;
                .font-size(36);
                color: @brand-white;
                background: @brand-primary;
                position: relative;
                z-index: 1;

                a {
                    display: block;
                    color: @brand-white;
                    text-decoration: none;
                    -moz-transition: color 0.3s ease-in-out;
                    -o-transition: color 0.3s ease-in-out;
                    -webkit-transition: color 0.3s ease-in-out;
                    transition: color 0.3s ease-in-out;

                    @media (max-width: 767px) {
                        color: @brand-secondary;
                    }

                    &:hover {
                        color: lighten(@brand-white, 8%);

                        @media (max-width: 767px) {
                            color: lighten(@brand-secondary, 8%);
                        }
                    }
                }
            }

            .banner-text {
                margin: -27px 0px 0px 34px;
                background-color: @brand-white;
                width: 396px;
                display: block;
                padding: 40px 20px 30px 20px;
                .font-size(18);

                a {
                    color: @base-font-color;
                    text-decoration: none;
                    -moz-transition: color 0.3s ease-in-out;
                    -o-transition: color 0.3s ease-in-out;
                    -webkit-transition: color 0.3s ease-in-out;
                    transition: color 0.3s ease-in-out;
                }
            }
        }
    }
}

.animate-banner.slide-in {
    opacity: 1!important;
    left: 0;

    .banner-area{
        opacity: 1!important;
    }
}



.homepage-signposts {
    position: relative;
    top: -36px;
    margin-bottom: 10px;

    .signpost-item {
        opacity: 0;
        width: 25%;
        float: left;
        padding: 0 15px;

        .widget.sign-post-widget {
            min-height: 95px;

            a {
                padding: 18px 20px;
            }

            /*a:hover {
                .signpost .signpost-icon img {
                    left: 5px;
                    width: 54px;
                }
            }*/

            .signpost {
                // height: 54px;
                display: flex;
                align-items: center;

                .signpost-icon {
                    display: block;
                    // width: 46px;
                    text-align: center;
                    padding: 0;

                    img {
                        // left: 7px;
                    }
                }

                .signpost-text {
                    .font-size(23);
                    line-height: 26px;
                    display: block;
                    padding: 0 15px;

                    @media (max-width: 991px) {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.load-home-content {
    opacity: 0;
    -moz-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    -webkit-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;

    &.fade-in {
        opacity: 1!important;
    }
}

.homepage-strip {
    text-align: center;
    padding: 0 0 50px;

    p {
        .font-size(22);
        line-height: 36px;

        @media (max-width: 767px) {
            .font-size(14);
            line-height: 18px;
        }
    }

    h1 {
        color: @brand-primary;
        @media (max-width: 767px) {
            .font-size(25);
        }
    }
}

.home-widget-wrap {
    margin: 60px 0;
}

@media (max-width: 1590px) {
    .banner-area .item .banner-overlay .banner-text {
        width: 550px;
    }

    .banner-area .item .banner-overlay {
        bottom: 120px;
    }
}

@media (max-width: 1390px) {
    .banner-area .item .banner-overlay {
        bottom: 75px;
        padding-left: 80px;
    }

    .banner-area .owl-buttons .owl-prev {
        left: 2px;
    }

    .banner-area .owl-buttons .owl-next {
        right: 2px;
    }
}

@media (max-width: 1270px) {
    .banner-area .item .banner-overlay {
        bottom: 65px;
        padding-left: 80px;

        .banner-text {
            width: 780px;
        }
    }
}

@media (max-width: 1199px) {
    .banner-area .item .banner-overlay {
        bottom: 100px;
        padding-left: 80px;

        .banner-text {
            width: 840px;
            padding: 40px 20px 12px 20px;
        }
    }

    .homepage-signposts {
        position: relative;
        top: -25px;
    }

        .homepage-signposts .signpost-item .widget.sign-post-widget {
            /*a:hover {
                .signpost .signpost-icon img {
                    left: 3px;
                    width: 43px;
                }
            }*/

            .signpost .signpost-icon {
                width: 56px;

                img {
                    left: 4px;
                    width: 39px;
                }
            }
        }

            .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
                .font-size(19);
                line-height: 25px;
            }

        .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
            .font-size(19);
            line-height: 25px;
        }
}

@media (max-width: 1040px) {
    .banner-area .item .banner-overlay {
        bottom: 26px;
        padding-left: 44px;
    }
}


@media (max-width: 991px) {
    .banner-area .item .banner-overlay {
        bottom: auto;
        top: 48px;
        padding-left: 0;
        padding-right: 10%;

        .banner-text {
            width: 100%;
        }
    }

    .homepage-signposts {
        top: 8px;

        .container {
        }

        .signpost-item {
            width: 25%;
            float: left;
            padding: 0 3px;

            &:last-child {
                display: none;
            }
        }
    }
}


@media (max-width: 767px) {
    .homepage-signposts .signpost-item {
        width: 100%;
    }

    .banner-area .item .banner-overlay {
        .banner-title {
            display: inline-block;
            padding: 14px 0;
            .font-size(26);
        }
    }

    .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
        .font-size(24);
        line-height: 31px;
    }

    .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
        .font-size(24);
        line-height: 25px;
    }

    .banner-area .item .banner-overlay .banner-text {
        padding: 0;
        margin: 0;
    }

    .banner-area .item .banner-overlay {
        top: 5px;
        left: 0;
        position: relative;
    }


    .banner-area .owl-buttons .owl-prev, .banner-area .owl-buttons .owl-next {
        top: 40%;
    }
}

@media (max-width: 550px) {
    .home-widget-wrap {
        margin: 25px 0 5px;
    }

    .banner-area .owl-buttons .owl-prev, .banner-area .owl-buttons .owl-next {
        top: 20%;
    }

    .banner-area .item .banner-overlay {
        top: 5px;
        left: 0;
        position: relative;
        padding-right: 0;

        .container {
            padding: 0;
        }

        .banner-title {
            display: inline-block;
            padding: 9px;
            padding-bottom: 0;
            font-size: 20px;
            color: @brand-secondary;
            background: #fff;
        }

        .banner-text {
            width: 100%;
            margin: 0;
            padding: 10px 10px;
            .font-size(14);
        }
    }
}

@media (max-width: 480px) {
    .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
        .font-size(17);
        line-height: 22px;
    }

    .widget.sign-post-widget .signpost .postcode-select .chosen-container .chosen-single {
        padding: 1px 2px!important;
    }

    .homepage-signposts .widget.sign-post-widget .signpost .depot-title {
        .font-size(17);
    }

    .widget.sign-post-widget .signpost .postcode-select {
        padding: 0 7px!important;
    }

    .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon {
        width: 48px;
    }

        .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-icon img {
            left: -2px;
        }

    /*.homepage-signposts .signpost-item .widget.sign-post-widget a:hover .signpost .signpost-icon img {
        left: -3px;
    }*/

    .homepage-signposts {
        top: -2px;

        .widget {
            margin-bottom: 7px;
        }

        .chosen-container .chosen-results li {
            height: 42px;
            line-height: 16px;
            padding-left: 12px;
            padding-top: 13px;
            padding-right: 9px;
        }
    }
}

@media (max-width: 335px) {
    .homepage-signposts .signpost-item .widget.sign-post-widget .signpost .signpost-text {
        .font-size(17);
        line-height: 22px;
    }
}
